import axiosModules from "@src/utils/config/axiosSetting";

export default {
  //회원 > 관리 > 계정 조회
  getAccountInfo: (): Promise<any> => {
    return axiosModules.get("/account/my-info");
  },

  //회원 > 관리 > 계정 수정
  updateAccount: (data): Promise<any> => {
    return axiosModules.patch("/account", data);
  },

  // 회원 > 관리 > 계정 체크
  getAccountTemporaryCheck: (): Promise<any> => {
    return axiosModules.get("/account/check");
  },

  //회원 > 로그인 > 로그인
  createSignIn: (data): Promise<any> => {
    return axiosModules.post("/sign-in", data);
  },

  //회원 > 로그인 > 로그아웃
  createSignOut: (): Promise<any> => {
    return axiosModules.post("/sign-out");
  },

  //회원 > 로그인 > 로그인 계정 라이선스 확인
  getAccountLicense: (): Promise<any> => {
    return axiosModules.get("/license");
  },

  //회원 > 계정 > 비밀번호/pin 정규식
  getAccountRegex: (): Promise<any> => {
    return axiosModules.get("/account/regex");
  },

  //회원 > 계정 > validation 확인
  createAccountValidationCheck: (data): Promise<any> => {
    return axiosModules.post("/account/validation-check", data);
  },

  //회원 > 계정 > 신규 가입
  createAccountSignUp: (data): Promise<any> => {
    return axiosModules.post("/account/sign-up", data);
  },

  //회원 > 계정 > 인증메일 재발송
  createAccountValidationResend: (data): Promise<any> => {
    return axiosModules.post("/account/verification/resend", data);
  },

  //회원 > 계정 > 계정 인증
  createAccountValidationCode: (code): Promise<any> => {
    return axiosModules.post(`/account/verification/${code}`);
  },

  //회원 > 비밀번호/pin 찾기 > PW/PIN 찾기 메일발송
  createAccountFindPwPinSendMail: (data): Promise<any> => {
    return axiosModules.post("/account/find", data);
  },

  //회원 > 비밀번호/pin 찾기 > PW/PIN 찾기 코드확인
  getAccountFindPwPinCheck: (code): Promise<any> => {
    return axiosModules.get(`/account/find/check/${code}`);
  },

  //회원 > 비밀번호/pin 찾기 > PW/PIN 찾기 변경
  updateAccountFindPwPinChange: (data): Promise<any> => {
    return axiosModules.patch("/account/find/change", data);
  },
};
