import { observable, computed, action } from "mobx";

import { dateUtil, formatConstants } from "@qubit-utill/src";

import { dateFormatUtils } from "@src/utils";
import { SetterModel } from "@src/utils/model";

type ConditionType = {
  statusFailCount: number | null;
  statusTryCount: number | null;
  speedFailCount: number | null;
  speedTryCount: number | null;
  speed: number | null;
  status: number | null;
  conditionUpdateTime: string | null;
  isNormalSpeed: boolean | null;
  isNormalStatus: boolean | null;
};

type StatusType = {
  statusUpdateTime: string | null;
  isSpoofing: boolean | null;
  isForgeryTitle: boolean | null;
  isForgeryMeta: boolean | null;
  isForgerySize: boolean | null;
  title: string | null;
  meta: string | null;
  size: number | null;
  ipList: Array<string> | null;
  ipTrText: string;
};

type DnsType = {
  dnsDaysRemain: number | null;
  isDnsExpired: boolean | null;
  expiredDate: string;
  checkDate: string;
};

type SslType = {
  sslDaysRemain: number | null;
  isSslExpired: boolean | null;
  expiredDate: string;
  checkDate: string;
};

export default class DashboardModel extends SetterModel {
  constructor(param?) {
    super(param);
    if (param) {
      this.status.statusUpdateTime = dateFormatUtils.defaultDateTimeToLocalTime(param.status.statusUpdateTime);
      this.condition.conditionUpdateTime = dateFormatUtils.defaultDateTimeToLocalTime(param.condition.conditionUpdateTime);
      this.dns.checkDate = dateFormatUtils.defaultDateTimeToLocalTime(param.dns.checkDate);
      this.dns.expiredDate = dateFormatUtils.defaultDateTimeToLocalTime(param.dns.expiredDate, true);
      this.ssl.checkDate = dateFormatUtils.defaultDateTimeToLocalTime(param.ssl.checkDate);
      this.ssl.expiredDate = dateFormatUtils.defaultDateTimeToLocalTime(param.ssl.expiredDate, true);

      if (param.status.ipList && param.status.ipList.length > 0) {
        this.status.ipTrText = param.status.ipList[0] + (param.status.ipList.length > 1 ? "..." : "");
      }
    }
  }

  ok: string = "OK";
  warning: string = "WARNING";

  @observable
  subject: string = "";

  @observable
  domain: string = "";

  @observable
  domainId: number = 0;

  @observable
  createDate: string = "";

  @observable
  condition: ConditionType = {
    statusFailCount: 0,
    statusTryCount: 0,
    speedFailCount: 0,
    speedTryCount: 0,
    speed: 0, // 속도
    status: 200,
    conditionUpdateTime: "",
    isNormalSpeed: false, // 속도 상태
    isNormalStatus: false, // 상태
  };

  @observable
  status: StatusType = {
    statusUpdateTime: "", // 스푸핑, 위변조 마지막 점검 시간
    isSpoofing: false, // 스푸핑 상태
    isForgeryTitle: false, // 웹사이트 타이틀 위변조 상태
    isForgeryMeta: false, // 웹사이트 메타 위변조 상태
    isForgerySize: false, // 웹사이트 사이즈 위변조 상태
    title: "",
    meta: "",
    size: 0,
    ipList: [],
    ipTrText: "",
  };

  @observable
  dns: DnsType = {
    dnsDaysRemain: 0, // 도메인 만료 남은일
    isDnsExpired: false, // 도메인 만료 여부
    expiredDate: "", // 도메인 만료일
    checkDate: "", // 도메인 만료 갱신일
  };

  @observable
  ssl: SslType = {
    sslDaysRemain: 0, // 인증서 만료 남은일
    isSslExpired: false, // 인증서 만료 여부
    expiredDate: "", // 인증서 만료일
    checkDate: "", // 인증서 만료 갱신일
  };

  @observable
  isWarning: boolean = false;

  @computed
  get domainPopover() {
    return [{ type: "create", data: this.createDate }];
  }

  @computed
  get statusPopover() {
    return [
      {
        type: "try",
        data: {
          failCount: this.condition.statusFailCount,
          tryCount: this.condition.statusTryCount,
        },
      },
      {
        type: "update",
        data: this.condition.conditionUpdateTime,
      },
    ];
  }

  @computed
  get speedPopover() {
    return [
      {
        type: "try",
        data: {
          failCount: this.condition.speedFailCount,
          tryCount: this.condition.speedTryCount,
        },
      },
      {
        type: "update",
        data: this.condition.conditionUpdateTime,
      },
    ];
  }

  @computed
  get forgeryTitlePopover() {
    return [
      {
        type: "update",
        data: this.status.statusUpdateTime,
      },
      {
        type: "title",
        data: this.status.title,
      },
    ];
  }

  @computed
  get forgeryMetaPopover() {
    return [
      {
        type: "update",
        data: this.status.statusUpdateTime,
      },
      {
        type: "meta",
        data: this.status.meta,
      },
    ];
  }

  @computed
  get forgerySizePopover() {
    return [
      {
        type: "update",
        data: this.status.statusUpdateTime,
      },
    ];
  }

  @computed
  get dnsPopover() {
    return [
      {
        type: "update",
        data: this.status.statusUpdateTime,
      },
      {
        type: "ip",
        data: this.status.isSpoofing ? this.status.ipList : "",
      },
    ];
  }

  @computed
  get domainExpiryPopover() {
    return [
      {
        type: "update",
        data: this.dns.checkDate,
      },
      {
        type: "expiry",
        data: this.dns.expiredDate,
      },
    ];
  }

  @computed
  get sslExpiryPopover() {
    return [
      {
        type: "update",
        data: this.ssl.checkDate,
      },
      {
        type: "expiry",
        data: this.ssl.expiredDate,
      },
    ];
  }

  @action
  getRisk = (value, isReverse = false) => {
    if (this.isNull(value)) return "-";
    if (isReverse) {
      return value ? this.ok : this.warning;
    } else {
      return value ? this.warning : this.ok;
    }
  };

  @action
  getRiskClassName = (value, isReverse = false) => {
    if (this.isNull(value)) return "-";
    if (isReverse) {
      return value ? "" : "c-red";
    } else {
      return value ? "c-red" : "";
    }
  };

  @action
  isNull = (value) => {
    return Boolean(value === null);
  };

  @computed
  get getLocalTime() {
    const date = this.status.statusUpdateTime && new Date(this.status.statusUpdateTime);
    let formatDate = "";
    if (date) {
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();
      const hour = date.getHours();
      const min = date.getMinutes();
      const sec = date.getSeconds();

      const _date = new Date(Date.UTC(year, month, day, hour, min, sec));
      formatDate = dateUtil.format(_date, formatConstants.DATEFORMATWITHTIME);
    }
    return formatDate;
  }
}
