import { observable, action } from "mobx";

import { withLoading } from "@qubit-utill/src";

import { domainApis } from "@src/apis";
import { ListCondition } from "@src/utils/store";
import DomainModel from "@src/pages/Domain/model/DomainModel";

class DomainStore extends ListCondition {
  @observable
  domainInfo: DomainModel = new DomainModel();

  @observable
  errMsg: string = "";

  @withLoading("isLoading")
  @action
  getRenewalDomainInfo = async (isEdit) => {
    try {
      const { domain, port, path, protocol } = this.domainInfo;
      const param = isEdit ? { domain, port, path, protocol } : { domain };
      const { info } = await domainApis.getRenewalDomainInfo(param);
      this.errMsg = "";

      if (info.isHttp) this.domainInfo.isSslExpireActive = false;

      if (info.isIpAddr) {
        this.domainInfo.isSpoofingActive = false;
        this.domainInfo.isDnsExpireActive = false;
        this.domainInfo.isSslExpireActive = false;
      }

      this.domainInfo = new DomainModel({ ...this.domainInfo, ...info, spoofingToString: null });
    } catch ({ data }) {
      this.errMsg = data.message;
      return data.message;
    }
  };

  @withLoading("isLoading")
  @action
  getDomainInfo = async (domainId) => {
    try {
      const { info } = await domainApis.getDomainInfo({ domainId });
      this.domainInfo = new DomainModel(info);
    } catch (err) {
      this.domainInfo = new DomainModel();
      return err;
    }
  };

  @action
  updateDomain = async (isEdit) => {
    try {
      const {
        subject,
        domainId,
        domain,
        statusTryCount,
        speedTryCount,
        protocol,
        port,
        path,
        size,
        sizeMin,
        sizeMax,
        meta,
        title,
        speed,
        speedMax,
        speedMin,
        dnsExpireAlarm,
        sslExpireAlarm,
        isTryCountActive,
        isSpeedActive,
        isForgeryActive,
        isForgeryTitleActive,
        isForgeryMetaActive,
        isForgerySizeActive,
        isSpoofingActive,
        isDnsExpireActive,
        isSslExpireActive,
        spoofingToString,
        status,
      } = this.domainInfo;

      const _spoofing = spoofingToString ? spoofingToString.split("\n") : [];

      const param = {
        subject,
        domainId,
        domain,
        statusTryCount,
        speedTryCount,
        protocol,
        port,
        path,
        size,
        sizeMin,
        sizeMax,
        meta,
        title,
        speed,
        speedMax,
        speedMin,
        spoofing: _spoofing,
        dnsExpireAlarm,
        sslExpireAlarm,
        isTryCountActive,
        isSpeedActive,
        isForgeryActive,
        isForgeryTitleActive,
        isForgeryMetaActive,
        isForgerySizeActive,
        isSpoofingActive,
        isDnsExpireActive,
        isSslExpireActive,
        status: Number(status),
      };

      if (!isEdit) {
        delete param.domainId;
        delete param.protocol;
        delete param.port;
        delete param.path;
      }
      await domainApis.updateDomain(param);
    } catch ({ data }) {
      return data.message;
    }
  };

  @action
  deleteDomain = async (domainId) => {
    try {
      await domainApis.deleteDomain({ domainId });
    } catch ({ data }) {
      return data.message;
    }
  };

  @action
  clear = () => {
    this.domainInfo = new DomainModel();
    this.isLoading = false;
  };
}

export default DomainStore;
