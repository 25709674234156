import { observable, computed, action } from "mobx";

import { languageSet } from "@src/utils";
import { SetterModel } from "@src/utils/model";
import { COUNT_OPTION_MAX_10, COUNT_OPTION_MAX_60 } from "@src/constants/code";

type DomainData = {
  title: string;
  activeName?: string;
  data: Array<{
    code: string;
    tdTitle?: string;
    type: string;
    useCheck?: boolean;
    isChecked?: string;
    min?: string;
    max?: string;
    unit?: string;
    useConvert?: boolean;
    convert?: string;
    getAction?: string;
    row?: number;
    disabled?: boolean;
    option?: Array<{ name: string; value: any }>;
    minSize?: number;
    maxSize?: number;
    subClassName?: string;
  }>;
};

export default class DomainModel extends SetterModel {
  constructor(param?) {
    super(param);
    if (param) {
      this.speedMin = this.getSpeedMin;
      this.speedMax = this.getSpeedMax;
      this.sizeMin = this.getSizeMin;
      this.sizeMax = this.getSizeMax;
    }
  }

  @observable
  subject: string = "";

  @observable
  domainId?: number = 10;

  @observable
  domain: string = "";

  @observable
  protocol?: string = "";

  @observable
  port?: string = "";

  @observable
  path?: string = "";

  @observable
  statusTryCount: number = 5;

  @observable
  speedTryCount: number = 5;

  @observable
  title: string = "";

  @observable
  meta: string = "";

  @observable
  size: string | number = "";

  @observable
  sizeMin: string | number = "";

  @observable
  sizeMax: string | number = "";

  @observable
  speed: string | number = "";

  @observable
  speedMin: string | number = "";

  @observable
  speedMax: string | number = "";

  @observable
  spoofing: Array<string> = [];

  @observable
  dnsExpireAlarm: number = 30;

  @observable
  sslExpireAlarm: number = 30;

  @observable
  createDate: string = "";

  @observable
  isTryCountActive: boolean = true;

  @observable
  isSpeedActive: boolean = true;

  @observable
  isForgeryActive: boolean = true;

  @observable
  isForgeryTitleActive: boolean = true;

  @observable
  isForgeryMetaActive: boolean = true;

  @observable
  isForgerySizeActive: boolean = true;

  @observable
  isSpoofingActive: boolean = true;

  @observable
  isDnsExpireActive: boolean = true;

  @observable
  isSslExpireActive: boolean = true;

  @observable
  spoofingToString: null | string = null;

  @observable
  status: number = 200;

  domainInfoKeyList: Array<DomainData> = [
    {
      title: languageSet("제목"),
      data: [
        {
          type: "inputText",
          code: "subject",
          minSize: 0,
          maxSize: 100,
          subClassName: "dis-inline-b width-100p",
        },
      ],
    },
    {
      title: languageSet("상태"),
      activeName: "isTryCountActive",
      data: [
        {
          type: "select",
          code: "statusTryCount",
          tdTitle: languageSet("시도"),
          unit: languageSet("번"),
          option: COUNT_OPTION_MAX_10,
        },
        {
          type: "inputText",
          code: "status",
          tdTitle: languageSet("상태값"),
          subClassName: "dis-inline-b w_100px p-r-10",
        },
      ],
    },
    {
      title: languageSet("속도"),
      activeName: "isSpeedActive",
      data: [
        {
          type: "inputTextMulti",
          code: "speed",
          tdTitle: languageSet("응답시간"),
          useCheck: false,
          unit: "ms",
          min: "speedMin",
          max: "speedMax",
        },
        {
          type: "select",
          code: "speedTryCount",
          tdTitle: languageSet("시도"),
          unit: languageSet("번"),
          option: COUNT_OPTION_MAX_10,
        },
      ],
    },
    {
      title: languageSet("웹사이트_위변조"),
      activeName: "isForgeryActive",
      data: [
        {
          type: "inputTextArea",
          code: "title",
          isChecked: "isForgeryTitleActive",
          useConvert: false,
          tdTitle: languageSet("타이틀"),
          row: 1,
          disabled: true,
        },
        {
          type: "inputTextArea",
          code: "meta",
          isChecked: "isForgeryMetaActive",
          useConvert: false,
          tdTitle: languageSet("메타"),
          row: 6,
          disabled: true,
        },
        {
          type: "inputTextMulti",
          code: "size",
          tdTitle: languageSet("응답크기"),
          useCheck: true,
          isChecked: "isForgerySizeActive",
          unit: "bytes",
          min: "sizeMin",
          max: "sizeMax",
        },
      ],
    },
    {
      title: languageSet("DNS_스푸핑"),
      activeName: "isSpoofingActive",
      data: [
        {
          type: "inputTextArea",
          code: "spoofing",
          useConvert: true,
          convert: "spoofingToString",
          getAction: "getSpoofing",
          row: 6,
        },
      ],
    },
    {
      title: languageSet("도메인_만료"),
      activeName: "isDnsExpireActive",
      data: [
        {
          type: "select",
          code: "dnsExpireAlarm",
          unit: languageSet("일_전"),
          option: COUNT_OPTION_MAX_60,
        },
      ],
    },
    {
      title: languageSet("인증서_만료"),
      activeName: "isSslExpireActive",
      data: [
        {
          type: "select",
          code: "sslExpireAlarm",
          unit: languageSet("일_전"),
          option: COUNT_OPTION_MAX_60,
        },
      ],
    },
  ];

  @computed
  get getSpeedMin() {
    if (this.speed !== "") {
      if (this.speedMin === "") {
        const min = Number(this.speed) - 300;
        this.speedMin = min > 0 ? min : 0;
      }
    } else {
      this.speedMin = "";
    }
    return this.speedMin;
  }

  @computed
  get getSpeedMax() {
    if (this.speed !== "") {
      if (this.speedMax === "") {
        const max = Number(this.speed) + 300;
        this.speedMax = max;
      }
    } else {
      this.speedMax = "";
    }
    return this.speedMax;
  }

  @computed
  get getSizeMin() {
    if (this.size !== "") {
      if (this.sizeMin === "") {
        const min = Number(this.size) - 10000;
        this.sizeMin = min > 0 ? min : 0;
      }
    } else {
      this.sizeMin = "";
    }
    return this.sizeMin;
  }

  @computed
  get getSizeMax() {
    if (this.size !== "") {
      if (this.sizeMax === "") {
        const max = Number(this.size) + 10000;
        this.sizeMax = max;
      }
    } else {
      this.sizeMax = "";
    }

    return this.sizeMax;
  }

  @action
  getSpoofing = () => {
    this.spoofingToString = this.spoofing.join("\n");
    return this.spoofingToString;
  };
}
