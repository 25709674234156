const languageJa = {
  _1단계: "1段階",
  _1차_구분자_필드: "1次セパレータ（フィールド）",
  _2단계: "2段階",
  _2차_구분자_토큰: "2次セパレータ（トークン）",
  _2차_인증: "2次認証",
  _2차_인증_OTP메일: "2次認証：OTPメール",
  _2차_인증_PIN번호: "2次認証：PIN番号",
  _2차_인증OTP메일: "2次認証<br/>(OTPメール)",
  _2차_인증PIN번호: "2次認証<br/>（PIN番号）",
  _2차_플랫폼: "2次プラットフォーム",
  _2차_플랫폼_노출명: "2次プラットフォーム露出名",
  _2차_플랫폼_코드: "2次プラットフォームコード",
  _3번_패키지_설치_포함됨: "3番パッケージのインストールが含む",
  _24시간: "24時間",
  _78_mysql_conf_수정: "78-mysql.conf修正",
  가능: "可能",
  가변: "可変",
  가입_변경일자: "登録/変更の日付",
  가입대기: "登録待機",
  가입멤버: "登録メンバー",
  가입여부: "登録有無",
  가입완료: "登録完了",
  가입인증이_완료되었습니다: "登録認証が完了しました。",
  가입인증처리: "登録認証処理",
  가입인증처리_하시겠습니까: "登録認証処理しますか？",
  가입일: "登録日",
  가입일자: "登録日付",
  간단_설명: "簡単説明",
  간략설명: "簡略説明",
  개발: "開発",
  개별: "個別",
  개인_대시보드: "個人ダッシュボード",
  개인_대시보드_수정: "個人_ダッシュボード_修正",
  개인알림: "個人通知",
  개인정보_관련없는_번호로_변경:
    "数字、英大文字、小文字、特殊文字の組み合わせを9桁以上で登録してください。",
  개인정보_마스킹: "個人情報のマスキング",
  개인정보_마스킹_설정: "個人情報のマスキング設定",
  개인정보_수정: "個人情報の修正",
  개인정보_수집_이용: "個人情報収集/利用",
  개인정보_숨김_설정: "個人情報非表示設定",
  개인정보_처리방침: "プライバシー 処理方針",
  개인정보숨김: "個人情報非表示",
  개인정보숨김_설정: "個人情報非表示設定",
  개인정보숨김설정_추가: "個人情報非表示設定を追加",
  개인정보취급방침: "個人情報取扱方針",
  개행_안함: "改行なし",
  개행_타입: "改行タイプ",
  갯수설정: "本数の設定",
  건당_평균_크기: "件あたりの平均サイズ",
  검사자등록: "検査メンバー登録",
  검색: "検索",
  검색_개인화: "検索パーソナライズ",
  검색_쿼리_방식: "検索クエリ方式",
  검색_키워드: "検索キーワード",
  검색_키워드_상세: "検索キーワードの詳細",
  검색결과: "検索結果",
  검색대상: "検索対象",
  검색제공메뉴: "検索のメニュー",
  검색제공메뉴가_없습니다: "検索のメニューがありません。",
  검색추가: "検索追加",
  검색항목_관리: "検索項目の管理",
  검색항목관리: "検索項目の管理",
  격리: "隔離",
  격리_해제: "隔離解除",
  결과문서_구조: "結果文書の構造",
  결과문서_선택: "結果文書を選択",
  경고: "警告",
  경로: "パス",
  경로_등록: "パス登録",
  경로_예시: "パスの例",
  계정_탈취_공격: "アカウント 乗っ取り 攻撃",
  계정_탈취_공격_방어: "アカウント 乗っ取り 防御",
  계정_탈취_공격_보안탐지: "アカウント奪取攻撃セキュリティ検出",
  계정생성: "アカウント作成",
  계정탈취: "アカウント乗っ取り",
  계정탈취_보고서: "アカウント奪取レポート",
  계정탈취_보안탐지: "アカウント奪取セキュリティ検出",
  계정탈취_보안필터_등록: "アカウント奪取セキュリティフィルタの登録",
  계정탈취_보안필터_수정: "アカウント奪取セキュリティフィルタの変更",
  계정탈취_수정: "アカウント奪取修正",
  계정탈취_예외: "アカウント乗っ取りを例外",
  계정탈취공격: "アカウント乗っ取り攻撃",
  계정탈취공격_보안필터_수정: "アカウント奪取攻撃攻撃セキュリティフィルタの変更",
  고_정: "固 定",
  고객지원: "サポート",
  고객지원_결과: "顧客サポートの結果",
  고객지원_담당자: "サポート担当者",
  고객지원_요청_사유: "サポートリクエストの理由",
  고객지원_요청하기: "サポートリクエストする",
  고객지원_이용약관: "サポート利用規約",
  고객지원_진행: "顧客サポート進行",
  고객지원_회사: "顧客サポート会社",
  고객지원요청: "カスタマーサポートリクエスト",
  고객지원요청_관리: "サポートリクエストの管理",
  고객지원요청_상세: "顧客サポートリクエストの詳細",
  고객지원요청관리: "サポートリクエストの管理",
  고유아이디: "固有のID",
  고유NO: "固有NO",
  고정: "固定",
  공격: "攻撃",
  공격목적: "攻撃目的",
  공격목적코드: "攻撃目的コード",
  공격분포: "攻撃分布",
  공격수: "攻撃数",
  공격유형: "攻撃種類",
  공격유형_분포: "攻撃種類の分布",
  공격유형_분포_요일별: "攻撃タイプの分布(曜日別)",
  공격유형_분포_주별: "攻撃タイプの分布(週別)",
  공격유형_선택: "攻撃タイプを選択",
  공격유형매핑: "攻撃タイプのマッピング",
  공격자: "攻撃者",
  공격코드: "攻撃コード",
  공격패턴: "攻撃パターン",
  공격확률: "攻撃確率",
  공격확률별_로그발생_현황: "攻撃確率別のログ発生状況",
  공격확률별_설정: "攻撃確率別の設定",
  공격확률별_탐지_현황: "攻撃確率の別検出現況",
  공격확률별_현황: "攻撃確率の現況",
  공격횟수: "攻撃回数",
  공격IP주소: "攻撃のIPアドレス",
  공지: "お知らせ",
  공지보기: "お知らせを見る",
  공지사항: "お知らせ",
  관련_높은: "関連の高い",
  관련그룹: "関連グループ",
  관련소프트웨어: "関連ソフトウェアー",
  관리: "管理",
  관리_무료: "管理（無料推奨）",
  관리자_설정: "管理者設定",
  구분: "区分",
  국가: "国",
  국가_설정: "国の設定",
  국가_언어_설정: "国/言語の設定",
  국가_지역: "国（地域）",
  국가별_공격_IP주소_현황: "国別の攻撃IPアドレス現況",
  국가별_접속_상위_IP주소_TOP20: "国別アクセス上位のIPアドレスTOP 20 ",
  권한: "権限",
  권한_선택: "権限の選択",
  권한변경: "権限変更",
  권한변경하기: "権限を変更する",
  그_외: "その他",
  그래프: "グラフ",
  그래프를_제공하지_않습니다: "グラフを提供しません。",
  그룹: "グループ",
  그룹_등록: "グループ登録",
  그룹_선택: "グループ選択",
  그룹_설정: "グループ設定",
  그룹_수정: "グループ修正",
  그룹_추가: "グループの追加",
  그룹고유키: "グループ固有のキー",
  그룹관리: "グループの管理",
  그룹내_기본값_사용: "グループ内のデフォルト値を使用",
  그룹명: "グループ名",
  그룹별: "グループ",
  그룹별_웹훅: "グループ別のウェプフク",
  그룹별_Syslog: "グループ別Syslog",
  그룹선택: "グループ選択",
  그룹설정: "グループ設定",
  그룹알림: "グループ通知",
  금: "金",
  금요일: "金曜日",
  금월: "今月",
  금일: "今日",
  금일_비밀번호_변경: "本日のパスワードが変更されました。",
  금주: "今週",
  기간설정: "期間設定",
  기본: "基本",
  기본_실행: "基本実行",
  기본정보: "基本情報",
  기술: "技術",
  기타: "その他",
  기타_정보: "その他の情報",
  나의_정보: "マイ情報",
  나중_변경: "後で変更",
  날짜: "日付",
  날짜형: "日付型",
  남은_기간: "残り期間",
  남은기간: "残った期間",
  남음: "残り",
  낮음: "低",
  내부_IP: "内部IP",
  내용: "内容",
  네트워크: "ネットワーク",
  네트워크_격리: "ネットワーク隔離",
  네트워크_격리_해제: "ネットワーク隔離解除",
  네트워크_단일필터: "ネットワークシングルフィルタ",
  네트워크_단일필터_등록: "ネットワークシングルフィルタ登録",
  네트워크_단일필터_수정: "ネットワークシングルフィルタの修正",
  네트워크_등록: "ネットワーク登録",
  네트워크_로그: "ネットワークログ",
  네트워크_수정: "ネットワーク修正",
  네트워크_업로드: "ネットワークアップロード",
  네트워크_전체로그: "ネットワーク全体のログ",
  네트워크_필터탐지: "ネットワークフィルタ検出",
  네트워크_호스트명: "ネットワークホスト名",
  네트워크로그: "ネットワークログ",
  네트워크로그_수집: "ネットワークログ収集",
  네트워크로그_수집_경로: "ネットワークログ収集パス",
  네트워크로그_업로드: "ネットワークログのアップロード",
  네트워크로그_업로드_수: "ネットワークログアップロード数",
  네트워크IP: "ネットワークIP",
  년: "年",
  년도별: "年度別",
  노드: "ノード",
  노드구분: "ノード区分",
  노드상태: "ノード状態",
  노출: "露出",
  노출_문구: "露出フレーズ",
  노출_컬럼을_관리합니다: "プライマリ列以外のデータ露出列を管理します。",
  노출명: "露出名",
  노출설정: "露出設定",
  노출순서: "露出順序",
  노출여부: "露出かどうか",
  높음: "高",
  닉네임: "ニックネーム",
  님: "様",
  님_회원정보: "様会員情報",
  다운로드: "ダウンロード",
  다운로드_한_파일의_경로에서_cmd_명령창_실행:
    "ダウンロードしたファイルのパスでcmdコマンドウィンドウを実行",
  다음: "次",
  다크_모드: "ダークモード",
  단_epel_release_사전_설치_필요: "ただし、epel-releaseを事前にインストールする必要がある",
  단위: "単位",
  단위변환: "単位変換",
  단일: "単一",
  단일필터: "単一フィルタ",
  단일필터_네트워크_분류: "単一フィルタ（ネットワーク）の分類",
  단일필터_등록: "単一フィルタの登録",
  단일필터_수정: "単一フィルタの修正",
  단일필터_시스템_분류: "単一フィルタ（ホスト）分類",
  닫기: "閉じる",
  담당자: "担当者",
  담당자_선택: "担当者の選択",
  답변: "回答",
  답변_메일알림_받기: "回答メールの通知受信",
  답변대기: "回答待ち",
  답변완료: "回答完了",
  당일: "当日",
  대기: "待機",
  대량등록: "大量登録",
  대상: "対象",
  대상_서버에_FilePlura_설치: "ターゲットサーバーにFilePluraをインストール",
  대상_서버에서_설정: "ターゲットサーバーで設定",
  대상_시스템: "対象ホスト",
  대상_장비에서_설정: "ターゲット機器で設定",
  대상_주소: "宛先アドレス",
  대상_포트_이름: "宛先ポート名",
  대상_호스트_이름: "宛先ホスト名",
  대상건: "対象件",
  대상for서버: "対象",
  대상IP주소: "宛先IPアドレス",
  대상IP주소_등록: "対象IPアドレス登録",
  대시보드: "ダッシュボード",
  대응: "対応",
  대표: "メイン",
  대표_웹훅: "代表ウェプフク",
  대표_Syslog: "代表Syslog",
  대표번호: "代表番号",
  대표자: "代表者",
  더_자세한_정보: "詳細",
  더보기: "もっと見る",
  덤프: "ダンプ",
  덤프_수집: "ダンプ収集",
  데이터_값: "データ値",
  데이터_소스: "データ_ソース",
  데이터_입력: "データ入力",
  데이터_저장기간: "データの保存期間",
  데이터_타입: "データタイプ",
  데이터루트: "データルート",
  데이터베이스_비교: "データベース比較",
  데이터소스: "データソース",
  데이터유출: "データ流出",
  데이터유출_방어: "データ流出防御",
  데이터유출_보안탐지: "データ流出セキュリティ検出",
  데이터유출_보안필터: "データ流出セキュリティフィルタ",
  데이터유출_보안필터_등록: "データ流出のセキュリティフィルタ登録",
  데이터유출_보안필터_수정: "データ流出セキュリティフィルタ修正",
  데이터유출_수정: "データ流出修正",
  데이터유출필터_분류: "データ流出フィルタ分類",
  데이터유형: "データの種類",
  데이터유형_선택: "データ種類を選択",
  도구: "道具",
  도메인: "ドメイン",
  도메인_등록: "ドメイン登録",
  도메인_수정: "ドメイン変更",
  도메인_코드: "ドメインコード",
  도메인관리: "ドメイン管理",
  도메인등록: "ドメイン登録",
  도메인명: "ドメイン名",
  도움말: "ヘルプ",
  도움말_바로가기: "ヘルプショートカット",
  돌아가기: "戻る",
  동기화: "同期",
  동기화_현황: "同期ステータス",
  동기화관리: "同期管理",
  동기화단위: "同期ユニット",
  동기화테이블: "同期テーブル",
  동기화현황: "同期ステータス",
  동기화DATA: "同期DATA",
  동시접속: "同時接続",
  동영상_가이드: "ビデオガイド",
  동의: "同意",
  동의안함: "同意しない",
  동일: "同じ",
  동일_숫자_3자리_이상: "同じ数字3桁以上",
  동일로그: "同じログ",
  동일요소수: "同じ要素数",
  동작: "動作",
  동작시간: "動作時間",
  동작시간_GMT09: "動作時間(GMT+09)",
  동적필터: "動的フィルタ",
  동적필터_코드_선택: "動的フィルタのコードを選択",
  동적필터등록: "エージェントの停止",
  동적필터수정: "動的フィルタの修正",
  두개_이상_필터_등록_가능: "2つ以上のフィルタ登録可能(or)",
  등록: "登録",
  등록_가능한_정보입력: "登録可能な情報入力",
  등록_가능한_호스트_개수: "登録可能なホストの合計%個",
  등록_가능한_n: "登録可能な%",
  등록_불가능한_n: "登録不可能な%",
  등록_서버: "登録サーバー",
  등록_시스템: "登録ホスト",
  등록_패턴: "登録パターン",
  등록_확인_후_가능: "登録確認後可能",
  등록게시물수: "登録投稿数",
  등록되었습니다: "登録が完了しました。",
  등록된_목록: "登録したリスト",
  등록된_서버: "登録されたサーバー",
  등록된_시스템: "登録されたホスト",
  등록된_시스템_확인: "登録されたホストを確認",
  등록된_항목수: "登録された項目の数",
  등록된_data_name: "登録されたdata name",
  등록된_data_name_리스트: "登録されたdata nameリスト",
  등록된_IP주소: "登録されたIPアドレス",
  등록된_IP주소_수: "登録されたIPアドレス数",
  등록된IP주소그룹: "登録されたIPアドレスグループ",
  등록된IP주소수: "登録されたIPアドレス数",
  등록방법: "登録方法",
  등록서버많은순: "登録サーバー多い順",
  등록서비스: "登録サービス",
  등록순: "登録順",
  등록업체: "登録企業",
  등록업체_바로가기: "登録企業のショートカット",
  등록일: "登録日",
  등록자: "登録者",
  등록자_등록일: "登録者/登録日",
  등록필터: "登録フィルタ",
  등록필터수: "登録フィルタ数",
  등록하기: "登録",
  등록한_퍼센트이상부터_서비스_노출: "登録した%以上からサービス露出",
  디스크로드률: "ディスクの読み込み率",
  디스크사용률: "ディスクの使用率",
  디스크용량: "ディスク容量",
  디코딩: "デコード",
  디폴트: "デフォルト",
  디폴트_메뉴_설정: "基本メニュー設定",
  디폴트_탭설정: "デフォルトタブの設定",
  라우트: "ルート",
  라이선스: "ライセンス",
  라이선스_기간: "ライセンス期間",
  라이선스_등록: "ライセンス登録",
  라이선스_등록_및_실행: "ライセンス登録と実行",
  라이선스_변경_신청: "ライセンス変更申請",
  라이선스_키_입력: "ライセンスキー入力",
  라이선스키: "ライセンスキー",
  라이트_모드: "ライトモード",
  라인_그래프: "ライングラフ",
  라인_추가: "ラインを追加",
  라인을_주석처리: "ラインをコメントアウト",
  랜덤_발생: "ランダム発生",
  레드마인_고유NO: "レッドマイン固有NO",
  레벨: "レベル",
  레퍼러: "リファラ",
  로고: "ロゴ",
  로그: "ログ",
  로그_기능: "ログ機能",
  로그_바로가기: "ログショートカット",
  로그_심각도: "ログ深刻度",
  로그_업로드_설정: "ログアップロード設定",
  로그_업로드_합계: "ログアップロード合計",
  로그_취합_서버_Datos_설치_포트미러링_페이지_Linux_Datos_탭:
    "ログ取込みサーバーにDatosをインストールした後、ポートミラーリングを設定 → ページ上部のメニューから Linux > Datos タブ",
  로그_취합_서버에_포트미러링_구성: "ログコレクションサーバーへのポートミラーリングの設定",
  로그_취합서버에_로그콜렉터_설치: "ログコレクターサーバーにログコレクターをインストール",
  로그_취합서버에_에이전트_설치: "ログ収集サーバにエージェントをインストール",
  로그_취합서버에서_인바운드_TCP_5514_포트_오픈:
    "ログマージサーバーでインバウンドTCP 5514ポートを開く",
  로그구분: "ログ区分",
  로그발생_현황: "ログ発生状況",
  로그별_업로드_분포: "ログ別アップロード分布",
  로그보기: "ログ表示",
  로그상세: "ログ詳細",
  로그설정: "ログ設定",
  로그수집경로입력: "ログ収集パスを入力",
  로그아웃: "ログアウト",
  로그업로드: "ログアップロード",
  로그업로드_분포: "ログアップロード分布",
  로그업로드량: "ログアップロード量",
  로그유형: "ログ種類",
  로그유형그룹: "ログ種類グループ",
  로그인: "ログイン",
  로그인_메일: "ログインメール",
  로그인_비밀번호_입력: "ログインパスワードを入力",
  로그인_속성: "ログインプロパティ",
  로그인_제한: "ログイン制限",
  로그인_히스토리: "ログイン履歴",
  로그인메일: "ログインメール",
  로그인허용_IP주소_추가: "ログイン許可IPアドレスの追加",
  로그인허용IP주소: "ログインを許可IPアドレス",
  로그인ID: "ログインID",
  로그저장크기: "ログの保存サイズ",
  로그조회_리스트_컬럼에_정보_노출함: "ログ照会リストカラムに情報公開する",
  로그취합상태: "ログ収集の状態",
  로그취합서버IP: "ログ収集サーバのIP",
  로그콜렉터_삭제: "ログコレクターの削除",
  로그콜렉터_재실행: "ログコレクタの再実行",
  로그포렌식_설치환경_안내_윈도우10_넷프레임워크45:
    "Windows10または.NET Framework4.5以降の環境でインストール可能",
  로그포렌식_지원시스템_종류: "ログアップロード支援システム : Windows, Linux, Android",
  루틴_프로시저_함수: "ルーチン（プロシージャ、関数）",
  룰_입력: "ルールの入力",
  를_선택합니다: "を選択します",
  리눅스_명령어_command_설치_rsyslog_사용:
    "Linuxコマンド（command）のインストール（rsyslogを使用）",
  리더: "リーダー",
  리셀러: "販売代理店",
  리셀러_등록: "販売代理店の登録",
  리소스: "リソース",
  리소스_등록: "リソース登録",
  리소스_수정: "リソースの修正",
  리소스_수집: "リソース収集",
  리소스_현황: "リソース状況",
  리소스관리: "リソース管理",
  리소스를_등록하세요: "リソースを登録してください.。",
  리소스명: "リソース名",
  리소스모니터링: "リソースモニタリング",
  리소스모니터링_메타정의: "% > % > メタ定義",
  리소스코드: "リソースコード",
  리스트: "リスト",
  리스트_컬럼_노출_설정: "リストの列の露出設定",
  링크: "リンク",
  마이크로초: "msec",
  마이터: "マイター",
  마이터_어택: "マイター攻撃",
  마이터_어택_탐지: "マイター攻撃ー検出",
  마이터_어택_탐지_분포: "マイター攻撃ー検出分布",
  마이터_통계_동기화: "マイター統計同期化",
  마이터관리: "マイター管理",
  마지막: "最後",
  마케팅_메일: "マーケティングメール",
  만료상태: "満了状態",
  만료예정: "満了予定",
  만료일: "満了日",
  매뉴얼: "マニュアル",
  매칭_키워드: "マッチングキーワード",
  매트릭스: "マトリックス",
  매트릭스_이동: "マトリックス移動",
  매트릭스보기: "マトリックス表示",
  매핑: "マッピング",
  매핑_컴플라이언스: "マッピングコンプライアンス",
  매핑소프트웨어: "マッピングソフトウェアー",
  매핑수: "マッピング数",
  매핑안함: "マッピングなし",
  매핑전술: "マッピング戦術",
  매핑정보_관리: "マッピング情報管理",
  매핑정보_등록: "マッピング情報の登録",
  매핑주소: "マッピングアドレス",
  매핑주소관리: "マッピングアドレス管理",
  매핑코드: "マッピングコード",
  매핑코드_컬럼: "(searchColumn)",
  매핑플랫폼: "マッピングプラットフォーム",
  매핑필터: "マッピングフィルタ",
  매핑필터_리스트: "マッピングフィルタリスト",
  매핑해킹그룹: "マッピングハッキンググループ",
  머신러닝의_패턴분석결과: "マシンラーニングのパターン分析結果",
  멀티등록_반점구분: "マルチ登録、区分",
  메뉴_노출_설정: "メニュー露出設定",
  메모리사용률: "メモリ使用率",
  메모리용량: "メモリ容量",
  메세지: "メッセージ",
  메세지_2차구분자입력하세요: "2次セパレータを入力してください。",
  메세지_가입완료후비밀번호변경요청해라: "登録完了後、パスワードの変更を要請してください。",
  메세지_개발팀문의: "開発チームにお問い合わせください。",
  메세지_개인대시보드노출필터설정: "個人のダッシュボードに公開するフィルタを設定してください。",
  메세지_개인정보처리방침에동의한다: "上記の個人情報の処理方針に同意します。",
  메세지_검색결과n건있다: `検索結果 <span class="c-black bold">%</span>件あります。`,
  메세지_검색키워드등록시안내: "検索キーワード登録時には修正できません。",
  메세지_검색키워드리스트없귀: "登録された検索キーワードがありません。",
  메세지_검색타입선택: "検索タイプを選択してください。",
  메세지_경로입력: "パスを入力してください。",
  메세지_계정탈취필터등록후사용가능: "アカウント情報奪取フィルタ登録後に使用可能です。",
  메세지_계정탈취필터등록후확인가능: "アカウント奪取フィルター登録後確認できます。",
  메세지_고객지원로그인시히스토리관리에정보가노출된다:
    "PLURAサポートログイン時の履歴管理に情報が露出されます。",
  메세지_고객지원요청완료: "顧客サポートが要求されました。",
  메세지_고객지원용아이디를통한접속정보는DB에저장한다:
    "お客様をサポートするためのIDを使用した接続情報は、DBに格納されます。",
  메세지_공격유형을_선택하세요: "攻撃種類を選択します。",
  메세지_공격IP주소가block되방어실행됨방어할거냐:
    "攻撃のIPアドレスがIP Blockに追加され、防御が実行されます。\n防御しますか?",
  메세지_관리자가_확인_후_답변_등록_예정입니다:
    "のちほど管理者が内容を確認して回答を登録する予定です。",
  메세지_관리자만수정가능하다: "管理者のみ変更が可能です。",
  메세지_관리자에게문의후로그인시도: "管理者にお問い合わせ後、ログインしようとしてください",
  메세지_그룹삭제입력정보모두삭제:
    "グループを削除しますか？\n 入力された情報がすべて削除されます。",
  메세지_그룹을생성한멤버는해당그룹의소속으로자동설정됩니다:
    "ループを作成したメンバーは、そのグループの所属に自動的に設定されます。",
  메세지_그룹을조회할멤버를선택하세요: "グループを照会するメンバーを選択してください.",
  메세지_그룹을추가해야함: "グループを追加してください。",
  메세지_그룹이등록되었습니다: "グループが登録されました。",
  메세지_내부DNS아닌경우host에서지정한다: `web serverに設定されたアドレスが内部DNSアドレスがない場合は、hostsファイルの<span className="c-red">internal-web-address</span>指定`,
  메세지_내용을_입력해주세요: "内容を入力してください",
  메세지_네트워크_격리: "ネットワーク隔離を行います。",
  메세지_네트워크_격리해제: "ネットワーク隔離を解除します。",
  메세지_다양한테마적용나만의프루라만드세요: "様様なテーマを適用して自分のPLURAを作りましょう。",
  메세지_다운로드_로그수_최대n개_입력: "ダウンロードするログ数を入力してください。(最大%個)",
  메세지_다운로드_수_입력: "2千個以下のダウンロードする%の数を入力してください。",
  메세지_다운로드실패: "ダウンロードに失敗しました。",
  메세지_다운로드하시겠습니까: "ダウンロードしますか？",
  메세지_담당자를_선택해_주세요: "担当者を選択してください。",
  메세지_답변글을_등록해_주세요: "回答文を登録してください。",
  메세지_대상을선택해주세요: "対象を選択してください。",
  메세지_대시보드로이동: "変更された情報が保存されず、ダッシュボードに移動します。",
  메세지_데이터값입력: "データ値を入力してください。",
  메세지_데이터루트를선택하세요: "データのルートを選択してください",
  메세지_동기화실패: "同期に失敗しました。",
  메세지_동기화완료: "同期完了しました。",
  메세지_동기화요청: "同期が要求されました。",
  메세지_동기화요청실패: "同期要求に失敗しました。",
  메세지_동기화진행취소불가: "同期の進行中にキャンセルできません。",
  메세지_동기화진행하쉴: "同期を続行しますか？",
  메세지_동기화필수: "順番に同期必須!",
  메세지_동기화하시겠습니까: "同期を続行しますか？",
  메세지_동일한검색어가있습니다: "同じ検索語があります。",
  메세지_등록가능한필터수초과: "登録可能なフィルタの数がすべて登録されました。",
  메세지_등록되었습니다_클릭시적용:
    "登録済みです。\nパスポップアップの下部の確認ボタンをクリックすると適用されます。",
  메세지_등록된_채널이_없습니다: "登録されたチャンネルがありません",
  메세지_등록된항목삭제불가항목삭제후다시시도:
    "登録された項目があって削除できません。項目を削除した後もう一度試してください。",
  메세지_등록된항목이없습니다: "登録された項目がありません。",
  메세지_등록된IP만로그인가능하다: "登録されたIPのみログインが可能です。",
  메세지_등록실패: "登録に失敗しました。",
  메세지_등록완료: "登録されました",
  메세지_등록하쉴: "登録しますか？",
  메세지_등록할목록그룹명을입력하라: "登録するリストグループ名を入力してください。",
  메세지_등록할목록명: "登録するリスト名を入力してください。",
  메세지_등록할태그명을입력하세요: "登録するタグ名を入力してください。",
  메세지_등록할호스트명을입력하세요: "登録するホスト名を入力してください。",
  메세지_디폴트설정하쉴이미설정된디폴트취소:
    "デフォルトに設定しますか？\n既に設定されたデフォルトはキャンセルされます。",
  메세지_디폴트OFF: "デフォルトOFF",
  메세지_디폴트ON: "デフォルトON",
  메세지_라이센스확인: "ライセンスを確認してください。",
  메세지_로그인10회실패: "ログインの10回失敗でログインが制限されます。",
  메세지_로그인성공실패선택: "ログイン成功か失敗かを選択してください。",
  메세지_로그인속성입력: "ログイン属性を入力してください。",
  메세지_로그인실패제한:
    "%%回失敗でサービス利用が%制限されます。 nしばらくして再度お試しください。",
  메세지_로그인실패제한_영구:
    "%%回失敗でサービス利用が制限されます。 n管理者にお問い合わせください。",
  메세지_로그인정보불일치: "ユーザーが存在しないか、情報が一致しません。",
  메세지_로그인해주세요: "ログインしてください。",
  메세지_로그취합안하쉴: "ログ収集を停止しますか？",
  메세지_로그취합하쉴: "ログを収集しますか？",
  메세지_리소스_등록_후_사용가능합니다: "リソース登録後に使用可能です。",
  메세지_리스트등록최종등록:
    "登録が完了しました。右上の確認ボタンをクリックして最終登録を行ってください。",
  메세지_리스트삭제최종삭제:
    "リストから削除されます。右上[OK]ボタンをクリックすると完全に削除処理されます。",
  메세지_리스트없음: "検索結果がありません。",
  메세지_리스트에서_삭제: "リストから削除されました。",
  메세지_매일설정한시간에만필터동작: "毎日設定した時間だけフィルタが動作します。",
  메세지_매핑된항목없음: "マップされた項目がありません。",
  메세지_매핑코드입력: "マッピングコードを入力してください",
  메세지_매핑필터중복안됨: "マッピングフィルタは重複することができません。",
  메세지_메뉴선택: "メニューを選択してください。",
  메세지_메일을발송했다: "メールを送信しました。",
  메세지_멤버로그인시비밀번호변경필요: "メンバーログイン時にパスワードの変更が必要です。",
  메세지_멤버에포함되지않는고객지원용아이디로로그인한다:
    "メンバーに含まれていないお客様をサポートするためのIDでログインします。",
  메세지_모드프루라업데이트: "メッセージモードプルーラをアップデートします。",
  메세지_모드프루라업데이트하쉴: "モードプルーラをアップデートしますか？",
  메세지_모든IP로그인가능하다: "全IPのログインが可能です。",
  메세지_문의하실_내용을_입력해_주세요: "お問い合わせする内容を入力してください！",
  메세지_미적용하시겠습니까: "未適用しますか？",
  메세지_발급된임시비밀번호는24시간이내만료된다:
    "発行された仮パスワードは、24時間以内に期間満了されます。",
  메세지_방어대상필터없음: `防御対象のフィルタがありません。\n 防御に必要なフィルタの登録時に露出され、設定が可能です。`,
  메세지_방어실행된다: "防御が実行されます。",
  메세지_방화벽스위치IDSIPS등네트워크장비의SYSLOG를취합:
    "ファイアウォール、スイッチ、IDS、IPSなどのネットワーク機器のsyslogを収集するための機能を提供",
  메세지_변경되었습니다_클릭시적용:
    "変更されました。\nパスポップアップ下部の確認ボタンをクリックすると適用されます。",
  메세지_변경된내용이없습니다: "変更された内容はありません。",
  메세지_변경된내용이있습니다_적용: "変更内容があります。 n適用しますか？",
  메세지_변경된내용이있습니다_취소: "変更内容があります。 nキャンセルしますか？",
  메세지_변경사항적용하쉴: "変更を適用しますか？",
  메세지_변경한비밀번호로다시로그인해라: "変更したパスワードで再度ログインしてください。",
  메세지_변경한정보저징확인클릭:
    "（変更した情報を保存するには、ページで「OK」ボタンをクリックしてください。）",
  메세지_변경할_을_선택하세요: "変更する%を選択してください。",
  메세지_변경할IP주소그룹명을입력하라: "変更IPアドレスのグループ名を入力してください。",
  메세지_보안_n개_수정완료: "%個のホストが%になりました。",
  메세지_복사되었다: "コピーされました。",
  메세지_복사하려는os와동일하다: "コピーしようとするOSと同じOSです。",
  메세지_복사하시겠습니까: "コピーしますか？",
  메세지_복사할os선택하세요: "コピーするOSを選択してください。",
  메세지_복원사용기간입력: "復元設定使用期間を入力してください。（最大20日）",
  메세지_복원승인대기중: "復元設定要請の承認待機中です。",
  메세지_복원시스템_삭제_1: "選択した復元ホストを削除しますか?",
  메세지_복원시스템_삭제_2: "削除すると復元されたログも削除されます。",
  메세지_복원시스템_삭제_3: "削除するとログ量によって数分の時間がかかることがあります。",
  메세지_복원요청: "復元設定要請が必要です。",
  메세지_복원요청승인_승인일_시작일로_노출:
    "復元設定のリクエストを承認しますか？承認日が開始日として表示されます。",
  메세지_복원진행: "復元を行います。",
  메세지_복원진행시간소요: "復元時のログ量に応じて数分の時間がかかることがあります。",
  메세지_복원진행해제_해당회사_복원설정_불가능:
    "復元の進行を解除しますか？\n当該会社の復設定が不可能となります。",
  메세지_복원취소시_복원된_로그포함삭제:
    "進行中の復元を取り消しますか？\nャンセルすると復元されたログも削除されます。",
  메세지_분류를_선택하세요: "分類を選択してください",
  메세지_분류index입력해주세요: "分類indexを入力してください。",
  메세지_사용자정의설정확인: "登録されたサービス情報がないか、カスタム設定がOFFになっています。",
  메세지_사용할_PIN번호를_입력해주세요: "使用するPIN番号を入力してください。",
  메세지_사용할컴플라이언스선택매핑정보확인:
    "使用コンプライアンス選択するとマップされた情報を確認することができます。",
  메세지_사용ID수값확인: "使用ID数は1以上99,999以下で入力可能です。",
  메세지_사용ID수를입력: "使用ID数を入力してください。",
  메세지_삭제되었습니다_클릭시적용:
    "削除されました。\nパスポップアップ下部の確認ボタンをクリックすると適用されます。",
  메세지_삭제된시스템은Default그룹으로이동된다: "削除されたホストはDefaultグループに移動されます。",
  메세지_삭제불가: "登録された記事があり、削除できません。",
  메세지_삭제시_하위대상도삭제: "削除しますか？\n(下位対象がある場合、下位対象も削除されます。)",
  메세지_삭제실패: "削除に失敗しました。",
  메세지_삭제완료: "削除されました。",
  메세지_삭제하쉴: "削除しますか？",
  메세지_삭제확인:
    "削除コマンドが実行されます。\n 削除コマンドの実行後には、ONに変更できません。\n OFF処理しますか？",
  메세지_삭제확인_등록게시물: "削除しますか？\n登録された記事がなければ、削除できます。",
  메세지_상관필터그룹공격확률입력: "関係フィルタの最初Groupの攻撃確率だけ0にする必要があります。",
  메세지_상태값입력: "ステータス値を入力してください。",
  메세지_상태값입력값확인: "ステータス値は、100以上599以下で入力可能です。",
  메세지_새롭게생성된그룹탐지방법:
    "新しく生成されたグループはフィルタを追加する場合のみ検出が可能です。",
  메세지_서비스명을_선택하세요: "サービス名を選択してください",
  메세지_서비스타입미선택: "サービスタイプ情報がありません。",
  메세지_선택한테마적용하시겠습니까: "選択したテーマを適用しますか？",
  메세지_설정체크: "設定をチェックしてください。",
  메세지_설정필터중시간별설정: "設定したフィルタの中で時間ごとの検出状況を設定することができます。",
  메세지_설정필터최신순확인:
    "メッセージ_設定したフィルタの発生時に日付順に確認することができます。",
  메세지_설정필터탐지카운트확인: "設定したフィルタの検出カウントを確認することができます。",
  메세지_설정하기필터추가필터정보가능:
    "設定することによってフィルタを追加すると追加したフィルタの情報確認が可能です。",
  메세지_세션종료시간을_변경하지_않았습니다: "セッションの終了時間を変更しませんでした。",
  메세지_속성값선택: "属性値を選択してください。",
  메세지_속성선택: "属性を選択してください。",
  메세지_수정실패: "修正に失敗しました。",
  메세지_수정완료: "修正されました。",
  메세지_수정이전데이터복사합니다:
    "修正以前のデータをコピーします。修正データのコピーが必要な場合は修正の完了後にコピーしてください。",
  메세지_수정하쉴: "修正しますか？",
  메세지_수집가능한대상이없습니다: "収集可能な対象がありません。\n確認してください。",
  메세지_수집대상을선택하세요: "収集対象を選択してください。",
  메세지_수집대상정보를입력해주세요: "収集対象の情報を入力してください。",
  메세지_수집완료는새로고침으로확인해주세요: "収集完了は更新して確認してください",
  메세지_수집하시겠습니까: "収集しますか？",
  메세지_승인_후_복원설정_가능: "承認が完了すると復元設定が可能です。",
  메세지_시간선택: "時間を選択してください。",
  메세지_시간설정다시: "開始時刻と終了時刻が同じになります。再設定してください。",
  메세지_시간을선택하세요: "時間を選択してください。",
  메세지_시간을설정: "時間を設定してください。",
  메세지_시도횟수값확인: "試行回数は、1以上9,999以下で入力可能です。",
  메세지_시도횟수를입력: "試行回数を入力してください。",
  메세지_시스템그룹명_입력하세요: "ホストグループ名を入力してください。",
  메세지_시스템그룹선택: "ホストグループを選択します。",
  메세지_시스템그룹을_선택: "ホストグループを選択してください。",
  메세지_시스템로그_n개_수정완료: "%個のホストが修正されました。",
  메세지_시스템목록갱신중: "ホストのリストを取得しています。\nしばらくお待ちください。",
  메세지_시작합니다: "% 開始します。",
  메세지_실행완료: "メッセージ実行完了",
  메세지_아이디로사용하는이메일은수정불가능하다: "ユーザ名として使用するメールは修正ができません。",
  메세지_업데이트실패실패시재시도:
    "* サーバーとネットワークの状態に応じて、アップデートが失敗することがあります。失敗時の再試行してください。",
  메세지_업데이트필요모드프루라전체업데이트하쉴:
    "* アップデートが必要なモードプルーラリストです。全体のアップデートには、次のチェックボックスを選択してください。",
  메세지_업데이트확인: "アップデートステータスページで進行状況を確認することができます。",
  메세지_완료되었습니다: "完了しました。",
  메세지_요소가등록되어있어야합니다: "要素が登録されている必要があります。",
  메세지_요소값입력해주세요: "要素値を入力してください。",
  메세지_요소선택: "要素を選択してください。",
  메세지_요소수선택: "要素数を選択してください。",
  메세지_요일선택해주세요: "曜日を選択してください。",
  메세지_요청후7일이내고객지원이루어지고7일이후엔종료된다:
    "リクエスト後7日以内の顧客サポートが行われ、7日以降に終了します。",
  메세지_운영체제ProgramName1차구분자입력하세요:
    "オペレーティングホスト、ProgramName、1次セパレータを入力してください。",
  메세지_원본로그포함여부경고:
    "ソースログを含む時に連動サイトポリシーにより通常にチケットが発行されない場合があります。",
  메세지_원하는곳에마우스우측버튼으로붙여넣기해라:
    "好きなところにマウスの右ボタンで貼り付けてください。",
  메세지_웹로그수집시작한다: "ウェブログの収集を開始します。",
  메세지_웹로그수집중지한다: "ウェブログの収集を停止します。",
  메세지_웹모듈을_삭제합니다: "Webモジュールを削除します。",
  메세지_웹방화벽로그전송설정: "ウェブファイアウォールログ転送設定(PLURAオペレータ用)",
  메세지_웹방화벽설정할수있음: "Webファイアウォールの例外設定を行うことができます。",
  메세지_웹탐지예외설정예시: '例) 000 <span class="c-red">XXXXXXXXXXX</span>時に<span>000</span>',
  메세지_위항목은등록후수정이불가합니다: "上記の項目は、登録後の変更はできません。",
  메세지_위험도별설정체크: "リスク別の設定をチェックしてください。",
  메세지_윈도우는지원하지않음: "Windowsはサポートしていません。",
  메세지_유료서비스를이용해더강력한해킹서비스와혜택받아보세요: ` <span class="c-blue bold bold p-r-5">Standard, Gold, Platinum, Premium</span>サービスを利用して、より強力なハッキング検出サービスと特典を受けましょう。`,
  메세지_유형중복: "登録済みのタイプです。",
  메세지_응답크기입력: "応答サイズを入力してください。",
  메세지_응답크기입력값확인: "応答サイズの最小値が最大値よりも大きいです。",
  메세지_응답크기최댓값확인: "応答サイズの最大値は、9,999,999以下です。",
  메세지_이동됐다: "移動されました",
  메세지_이미_등록된_컬렉션명입니다: "既に登録されているコレクション名です。",
  메세지_이미_적용된_공격유형입니다: "すでに適用された攻撃種類です。",
  메세지_이미등록된목록명: "既に登録されているリスト名です。",
  메세지_이미등록된이벤트타입입니다: "既に登録されたイベントタイプです。",
  메세지_이미등록된코드: "既に登録されたコードです。",
  메세지_이미업데이트: "すでにアップデートが進行中です。",
  메세지_이벤트타입미선택: "イベントタイプの情報がありません。",
  메세지_이용약관동의하면고객지원요청된다:
    "利用規約に同意をしていただければ、お客様のサポートがリクエストされます。",
  메세지_이용약관에동의한다: "上記の利用規約に同意します。",
  메세지_이후고객지원필요시재요청필요하다: "以降サポートが必要時には再要求する必要があります。",
  메세지_인증URL클릭하면회원가입완료된다: "認証URLをクリックすると会員登録が完了します。",
  메세지_입력된정보를확인하세요: "入力された情報を確認してください。",
  메세지_입력하신이메일로인증메일발송되며인증되야회원가입완료이다:
    "入力したメールに認証メールが送信され、認証完了時に会員登録されます。",
  메세지_자동입력방지안내: `パスワードを<span class="c-white">%回以上間違って入力した場合,</span></span><br><span class="dis-inline-b f-12 c-gray-light">情報保護のための自動入力を防止してログインする必要があります。</span>`,
  메세지_자동입력방지확인: "パスワードと自動入力防止を確認してください。",
  메세지_잠금처리되었습니다수정해재등록해주시기바랍니다:
    "オペレータによってロックされました。修正後の再登録してください。",
  메세지_잠김해제하쉴: "%ロックを解除しますか？",
  메세지_재시도버튼클릭: "再試行ボタンをクリックしてください。",
  메세지_재정렬되었습니다: "並べ替えました。",
  메세지_재정렬후_저장_가능: "並べ替え後の保存可能",
  메세지_저장되었습니다: "保存されました。",
  메세지_저장하쉴: "保存しますか？",
  메세지_적용하시겠습니까: "適用しますか？",
  메세지_전화번호를_입력해주세요: "-を除いて番号を入力してください。",
  메세지_접속하기:
    "接続時のログインされた管理とPLURAからログアウトされます。\n該当会社のPLURAに接続しますか？",
  메세지_정렬대상서비스를선택하세요: "ソート対象サービスを選択してください",
  메세지_정보삭제후진행가능합니다: "情報の削除後、進行が可能です。",
  메세지_제목을_입력해주세요: "タイトルを入力してください",
  메세지_중복된목록명: "既に使用されているリスト名です。",
  메세지_지원시작: "接続アカウントが作成されます。\n開始しますか？",
  메세지_지원종료: "接続アカウントが無効になります。\n終了しますか？",
  메세지_진행상황_복원히스토리페이지: "進行事項は[復元履歴]ページでご確認ください。",
  메세지_차단IP삭제: "削除時にブロックが解除されます。 n削除しますか？",
  메세지_채널단일설정: "(フィルタの登録時にイベントタイプの選択隠し処理)",
  메세지_채널을_선택하세요: "チャンネルをお選びください",
  메세지_초기화가능한대상이없습니다: "初期化可能な対象がありません。\n確認してください。",
  메세지_초기화대상을선택해주세요: "初期化する対象を選んでください。",
  메세지_초기화하시겠습니까: "初期化しますか？",
  메세지_초대하였습니다: "招待しました。",
  메세지_초대할사람이메일입력하라: "招待するメールアドレスを入力してください。",
  메세지_최대30개까지등록된다: "最大30個まで登録ができます。",
  메세지_최대초입력가능: "最大 %入力が可能",
  메세지_최대n개IP주소등록: `<span class="c-red">最大%個のIPアドレスを登録</span>することができます。`,
  메세지_최신버전: "最新バージョンです。",
  메세지_추가됐다: "追加されました",
  메세지_추가할_필터분류를_선택하세요: "追加するフィルタの分類を選択してください。",
  메세지_추가할멤버의정보입력해라: "追加するメンバーの情報を入力してください。",
  메세지_취소되었습니다: "キャンセルされました。",
  메세지_컴플라이언스삭제하쉴: "選択したコンプライアンスを削除しますか？",
  메세지_코드입력: "コードを入力してください。",
  메세지_키워드별상세설정안내: "キーワードごとの詳細設定ができます。",
  메세지_탐지_우선순위_적용을_해지하쉴: "検出優先順位の適用を終了しますか？",
  메세지_탐지노출입력: "検出露出0を入力することができません。",
  메세지_탐지시간입력: "出時間は、1秒以上%秒以下の値を入力してください。",
  메세지_탐지우선순위설정취소:
    "修正値が保存されず、優先順位のリスト画面に変更されます。 \n キャンセルしますか？",
  메세지_테마가적용되었습니다: "テーマが適用されました。",
  메세지_테마선택하세요: "テーマを選択してください。",
  메세지_테마저장하지않고이동됩니다: "選択したテーマが保存されずに移動されます。",
  메세지_특정필터탐지카운트정보비교:
    "特定のフィルタの検出カウントを1日前のデータと比較することができます。",
  메세지_특정Key를입력하세요: "特定のkey設定> Keyを入力してください。",
  메세지_특정key분류index를선택하세요: "特定のkey設定>カテゴリindexを選択してください。",
  메세지_특정key분류index와key확인하세요:
    "特定のkey設定>分類index、Keyを確認してください。\n（[分類index＆Key]がペアに同じ[index＆Key]は存在することはできません。",
  메세지_특정KeyValueIndex를입력하세요: "特定のkey設定> Value Indexを入力してください。",
  메세지_티켓발행시원본로그포함: "チケットの発行時、元本ログを含む",
  메세지_티켓사이트삭제:
    "削除時、すでに使用しているお客様の場合はチケット発行情報が消えて再発行が可能な状態に変更されます。\nまた、連動されたバージョンとパラメータ情報も削除されます。\n削除しますか？",
  메세지_티켓이발행됐다: "チケットが発行されました。",
  메세지_티켓OFF하쉴:
    "OFF設定時、すでに使用しているお客様の場合、チケット発行情報が消えて再発行が可能な状態に変更されます。\nOFFしますか？",
  메세지_파일업로드실패: "ファイルのアップロードが失敗しました。",
  메세지_파일업로드완료: "ファイルのアップロード完了されました。",
  메세지_포렌식_초기화_경고: "(すべての情報が削除され、現在の情報が以前の情報に更新されます。)",
  메세지_핀번호를_입력하세요: "PIN番号を入力してください。",
  메세지_필터레벨선택: "フィルタレベルを選択してください。",
  메세지_필터명선택: "フィルタ名を選択してください。",
  메세지_필터명입력: "フィルタ名を入力してください。",
  메세지_필터명입력필요: "フィルタ名を入力してください。",
  메세지_필터상태OFF하쉴: "選択したフィルタの状態をOFFしますか?",
  메세지_필터상태ON하쉴:
    "選択したフィルタの状態をONしますか? \n ON 設定時のフィルタが動作し、セキュリティフィルタに検出されます。",
  메세지_필터상태ON하시겠습니까: "選択したフィルタの状態をONしますか?",
  메세지_필터중복: "登録済みフィルタです。",
  메세지_하단특정key설정추가하세요: "下の特定keyの設定を追加してください。",
  메세지_항목선택: "項目を選択してください。",
  메세지_항목체크: "項目をチェックしてください",
  메세지_해지되었습니다: "終了しました",
  메세지_허용IP주소를입력해주세요: "許可IPアドレスを入力してください",
  메세지_호스트_종료: "ホストを終了します。",
  메세지_호스트입력: "ホストを入力してください。",
  메세지_호스트정보도도시적용됩니다: "ホスト情報も同時適用されます。",
  메세지_호스트종료후_재시작수동: "ホスト終了後の再起動は手動で行う必要があります。",
  메세지_확률n퍼센트입니다: "確率%です。",
  메세지_확인후다시시도해주세요: "確認後、再試行してください。",
  메세지_회원가입시등록한이메일정보를입력하시면해당이메일로임시비밀번호발송한다:
    "会員登録時に登録したメールアドレスを入力しましたら、そのメールに仮パスワードが送信されます。",
  메세지_회원가입완료되었다: "会員登録が完了しました。",
  메세지_회원가입하신이메일로인증URL발송했다: "会員登録されたメールで認証URLが送信されました。",
  메세지_Audit활성화를위한안내: "Audit活性化のため下の1,2回のうち、選択して使用",
  메세지_burst를입력해주세요: "を入力してください。",
  메세지_CapsLock활성: "CapsLockがオンになっています。",
  메세지_CNAME을입력해주세요: "CNAMEを入力してください。",
  메세지_DataName치환: "置換対象と値を入力してください。",
  메세지_Host를입력: "Hostを入力してください。",
  메세지_HostPath선택: "Host、Pathを選択した場合は時間帯別の統計情報を確認できます。",
  메세지_index는0부터시작합니다: "indexは0から始まります。",
  메세지_IP주소가_아래와_같이_등록됩니다: "IPアドレスが以下のように登録されます。",
  메세지_IP주소등록완료시차단: "IPアドレス登録を完了するとブロックが実行されます。",
  메세지_IP주소를입력해주세요: "IPアドレスを入力してください。",
  메세지_IP중복: "既に登録されたIPです。",
  메세지_listenPort입력해주세요: "Listen portを入力してください。",
  메세지_ML탐지예외설정할수있다: "ML検出の例外設定ができます。",
  메세지_n개선택했습니다: "%個が選択されました。",
  메세지_n개의대상만수집가능합니다: "%個の対象のみ収集可能です。\n確認してください。",
  메세지_n님안녕하세요: "%様こんにちは!",
  메세지_n으로권한을변경합니다: "%に権限を変更します。",
  메세지_n표항목은필수입니다: "%表示項目は必須項目です。",
  메세지_NOOS: "オペレーティングホストを選択してください。",
  메세지_OFF설정시매핑된정보OFF처리하쉴:
    "OFF設定時、既にサービスマッピングされた情報も消えます。OFFしますか？",
  메세지_ON설정사용자가이드: "(ON設定時にユーザーガイドの入力、フィルタ状態OFF)",
  메세지_ON설정서비스노출: "(ON設定時、サービス露出)",
  메세지_ON설정시서비스노출ON처리하쉴: "ON設定時、サービスに公開されます。ONしますか？",
  메세지_ON설정시잠금이되며서비스에필터비활성화처리:
    "(ON設定時のロックがされ、サービスのフィルタを無効に処理)",
  메세지_ON설정탐지노출: "ON設定時検出露出",
  메세지_ON설정필터적용: "(ON設定時フィルタの適用)",
  메세지_ON설정필터ON상태: "(ON設定時、フィルタON状態)",
  메세지_ON하쉴: "ONしますか？",
  메세지_OS를_선택하세요: "OSを選択してください。",
  메세지_OTP메일번호가발송되었습니다: "OTPメール番号が送信されました。",
  메세지_Passphrase값확인: "Passphrase値は1〜32桁です。",
  메세지_PLURA_관리자가_내용확인_후_답변_드릴_예정입니다:
    "のちほどPLURA管理者が内容を確認して回答する予定です。",
  메세지_PLURA제공_보안필터전용: "PLURA提供セキュリティフィルタ専用",
  메세지_Port입력해주세요: "Portを入力してください。",
  메세지_rate를입력해주세요: "rateを入力してください。",
  메세지_SSL_CRT값확인: "SSL CRT値を1000桁以上入力してください。",
  메세지_SSL_Key값확인: "SSL Key値を1000桁以上入力してください。",
  메세지_Timestamp유형은한개만등록가능합니다: "Timestamp タイプは 1 つのみ登録可能です。",
  메세지_Windows2008자동설치미지원:
    "Windows Server2008用のIIS（Internet Information Server）の自動インストール非対応",
  메소드: "メソッド",
  메시지_경로를등록해야업로드가능합니다: "パスを登録しなければアップロードできません。",
  메시지_변경내용적용: "変更内容があります。 n適用しますか？",
  메시지_변경내용취소: "変更内容があります。 nキャンセルしますか？",
  메시지_삭제완료10초이상소요: "削除完了まで10秒以上の時間がかかる場合があります。",
  메시지_서버등록후경로입력가능: "サーバー登録後のパス入力可能",
  메시지_자식서버가등록되었습니다: "子サーバーが登録されました。",
  메시지_자식시스템_n개_동시삭제: `<span class="c-red">選択された親ホスト(たち)に登録された子ホスト%個が同時削除</span>されます。`,
  메시지_IP차단을위해이유를적으세요:
    "IPアドレスをブロックしますか？ nブロックの理由を書いてください。",
  메인: "メイン",
  메일발송내용: "メール送信内容",
  메일보내기: "メールの送信",
  메타_정의: "メタ定意",
  메타_정의_후_사용가능합니다: "メタ定義後に使用可能です。",
  메타서비스: "メタサービス",
  메타서비스관리: "メタサービス管理",
  멤버: "メンバー",
  멤버_수: "メンバー数",
  멤버_이름_입력: "メンバー名を入力",
  멤버관리: "メンバー管理",
  멤버수: "メンバー数",
  멤버초대: "メンバー招待",
  멤버초대_초대현황: "メンバー招待/招待ステータス",
  멤버추가: "メンバー追加",
  명령어: "コマンド",
  명령어_선택: "コマンドの選択",
  명령어_입력: "コマンド入力",
  명령어_입력_실행: "コマンド入力の実行",
  명령어_히스토리: "コマンド履歴",
  모드프루라_등록: "モードプルーラ登録",
  모드프루라_버전관리: "モードプルーラバージョン管理",
  모드프루라_수정: "モードプルーラ修正",
  모드프루라_업데이트: "モードプルーラアップデート",
  모드프루라_전체_업데이트: "モードプルーラリスト全体のアップデート",
  모드프루라_종류: "モードプルーラ種類",
  모드프루라_종류_버전: "モードプルーラ(種類/バージョン)",
  모든그룹: "全グループ",
  모든서버그룹: "全てのサーバーグループ",
  모든서버그룹선택: "すべてのサーバーグループの選択",
  목: "木",
  목록: "リスト",
  목록_등록: "リスト登録",
  목록그룹_등록: "リストグループ登録",
  목록그룹명_변경: "リストグループ名変更",
  목록등록: "リスト登録",
  목록명: "リスト名",
  목록명_변경: "リスト名変更",
  목요일: "木曜日",
  무료: "無料",
  무료_체험기간: "無料（体験期間）",
  무료체험기간: "無料体験期間",
  무료평가판시작: "無料評価版開始",
  묶음: "バンドル",
  문의하기: "お問い合わせ",
  문자형: "文字型",
  미동작: "未動作",
  미등록: "未登録",
  미등록된_주소: "未登録のアドレス",
  미리보기: "プレビュー",
  미사용: "未使用",
  미수집: "未収集",
  미업로드: "未アップロード",
  미인증: "未認証",
  미저장: "未保存",
  미적용: "未適用",
  미제공: "未提供",
  미제한: "制限なし",
  미취합: "未収集",
  미확인_IP: "未確認IP",
  바로가기: "ショートカット",
  바이패스: "バイパス",
  박스플롯: "ボックスプロット",
  발급일: "発行日",
  발급자: "発行者",
  발급필요: "発行必要",
  발급하기: "発行する",
  발급하기_버튼을_클릭하시면_생성: "「発行」ボタンをクリックすると、仮パスワードが生成されます。",
  발생: "発生",
  발생_횟수: "発生回数",
  발생일: "発生日",
  발행: "発行",
  발행일: "発行日",
  발행자: "発行者",
  발행티켓: "発行チケット",
  방금: "先ほど",
  방어: "防御",
  방어_명령어_입력: "防御コマンド入力",
  방어관리: "防御管理",
  방어구분: "防御区分",
  방어대상: "防御対象",
  방어로그: "防御ログ",
  방어상태: "防御状態",
  방어실행: "防御実行",
  방어원인: "防御原因",
  방어IP주소: "防御IPアドレス",
  방화벽: "ファイアウォール",
  방화벽_유형: "タイプ",
  방화벽설정: "ファイアウォール設定",
  백업: "バックアップ",
  백업루트: "バックアップルート",
  버전: "バージョン",
  버전_선택: "バージョン選択",
  버전_출력_예시: "バージョンの出力例",
  버전_확인_API: "バージョン確認API",
  버전_n_다운로드_링크: "バージョン%ダウンロードリンク",
  버전에_따른_재시작_예시: "上記の例で確認されたバージョンに基づく再起動例示",
  버전에버전확인: "バージョンに応じて、サービス名が異なりますのでバージョンの確認",
  변경: "変更",
  변경_시점: "変更時点",
  변경할_권한: "変更する権限",
  변수_설명: "変数の説明",
  변환: "変換",
  별칭: "ニックネーム",
  보고서: "レポート",
  보고서_네트워크: "レポート_ネットワーク",
  보고서_메일: "レポートメール",
  보고서_박스플롯: "レポート_ボックスプロット",
  보고서_분류: "分類",
  보고서_분류별_탐지_현황: "レポート分類別検出状況",
  보고서_상관분석: "レポート_相関",
  보고서_시스템: "レポート_ホスト",
  보고서_알림: "レポート通知",
  보고서_웹: "レポート_ウェブ",
  보고서_히트맵: "レポート_ヒートマップ",
  보고서_IP주소: "レポート_IPアドレス",
  보고서메일: "レポートメール",
  보기: "表示",
  보안: "セキュリティ",
  보안_유료: "セキュリティ（有料）",
  보안탐지: "セキュリティ検出",
  보안필터: "セキュリティフィルタ",
  보안필터_등록: "セキュリティフィルタ登録",
  보안필터_수정: "セキュリティフィルタ変更",
  보안필터수정: "セキュリティフィルタ変更",
  복구: "回復",
  복사: "コピー",
  복사_위치: "コピー位置",
  복수: "複数",
  복원: "復元",
  복원기간: "復元期間",
  복원대상: "復元対象",
  복원목적: "復元目的",
  복원서비스: "復元サービス",
  복원서비스_사용기간: "復元サービス使用期間",
  복원설정: "復元設定",
  복원설정요청: "復元設定の要請",
  복원설정요청관리: "復元設定の要請管理",
  복원시간: "復元時間",
  복원시스템: "復元ホスト",
  복원시스템관리: "復元ホスト管理",
  복원시작: "復元開始",
  복원이용약관_1: "復元設定要請期間中のみ復元設定が可能です。",
  복원이용약관_2: "1日に10件の復元が可能です。(完了した復元基準)",
  복원이용약관_3: "復元されたログは復元された時点から30日間照会できます。",
  복원이용약관_4: "復元されたログは照会可能な状態で再復元されません。",
  복원히스토리: "復元履歴",
  본문: "本文",
  볼륨_메트릭: "ボリュームメトリック ",
  부모_시스템: "親(P)ホスト",
  부분삭제: "部分削除",
  부서: "部門",
  부서_입력: "部門入力",
  분: "分",
  분류: "分類",
  분류_관리: "分類管理",
  분류_선택: "分類選択",
  분류_index: "分類 index",
  분류_index_관리: "分類index管理",
  분류관리: "分類管理",
  분류별_탐지_분포: "分類別検出分布",
  분류별_탐지_현황: "分類別検出状況",
  분류수정: "分類修正",
  분석: "分析",
  분석_변경_히스토리: "分析変更ヒストリー",
  분석정보: "分析情報",
  분석정보_영역에_정보를_노출함: "分析情報領域に情報を公開する",
  분포: "分布",
  불가능: "不可能",
  불러오기: "インポート",
  불일치: "不一致",
  뷰: "ビュー",
  비교: "比較",
  비교_테이블_생성: "比較表作成",
  비교_테이블_생성_실패: "比較表作成失敗",
  비교_테이블_생성_완료: "比較表作成完了",
  비교_테이블_생성합니다: "比較表を作成します。",
  비교_필터_리스트: "比較フィルターリスト",
  비밀번호: "パスワード",
  비밀번호_변경: "パスワード変更",
  비밀번호_변경_시점_n일_초과:
    'パスワードを変更してから<span class="c-red">%日</span>が経過しました。',
  비밀번호_변경_안내: "パスワード変更ガイド",
  비밀번호_변경_요청: "定期的にパスワードを変更してください。",
  비밀번호_변경_후_로그아웃: "パスワード変更後、既存のアカウントはログアウトされます。",
  비밀번호_변경_후_서비스_이용:
    "携帯電話番号/住民登録番号/誕生日などの個人情報と関係のない番号に変更してください。",
  비밀번호_변경을_권고: "パスワードの変更を勧告してください！",
  비밀번호_변경하기: "パスワードを変更する",
  비밀번호_생성_규칙_관리: "パスワード作成のルール管理",
  비밀번호_설정_완료: "パスワードが設定されました",
  비밀번호_입력: "パスワード入力",
  비밀번호_재입력: "パスワード再入力",
  비밀번호_찾기: "パスワードアシスタンス",
  비밀번호_확인: "パスワード確認",
  비밀번호를_변경해주세요: "パスワードを変更してください",
  빌드: "ビルド",
  사용: "使用",
  사용_컴플라이언스: "使用コンプライアンス",
  사용된_변수: "使用された変数",
  사용로그: "使用ログ",
  사용불가: "使用不可",
  사용설정: "使用設定",
  사용여부: "使用するかどうか",
  사용자: "ユーザー",
  사용자_가이드: "ユーザーガイド",
  사용자_값_선택입력: "ユーザー値を選択入力",
  사용자_설정: "ユーザー設定",
  사용자_에이전트: "ユーザーエージェント",
  사용자_정보: "ユーザー情報",
  사용자_정의: "カスタム",
  사용자_환경에_따라_아래_패키지가_필요: "ユーザーの環境に応じて、以下のパッケージが必要",
  사용자가이드: "ユーザーガイド",
  사용자설정: "ユーザー設定",
  사용자에이전트: "ユーザーエージェント",
  사용자정의: "カスタマイズ",
  사용자필터: "ユーザーフィルター",
  사용자필터수정: "ユーザーフィルタの変更",
  사용중: "使用中",
  사용하시는_장비에서_설정: "ご使用の機器で設定",
  사용ID수: "使用ID数",
  사이: "間",
  사전_n_설치_필요: "事前%のインストールが必要",
  삭제: "削除",
  삭제가_불가능합니다_등록업체문제: "削除できません。 n登録企業を削除後に再試行ください。",
  삭제가_불가능합니다_리소스문제: "削除できません。 n削除してから試してください。",
  삭제가_불가능합니다_메타문제: "削除できません。\nメタを削除してから試してください。",
  삭제가_불가능합니다_적용업체문제:
    "削除できません。\n適用業者を削除した後してからお試しください。",
  삭제된_정보입니다: "削除された情報です。",
  삭제명령어: "削除コマンド",
  삭제시스템: "削除ホスト",
  삭제취소: "削除の取り消し",
  삭제하기: "削除",
  상관_분석_방어: "相関分析防御",
  상관보안필터시작: "関係セキュリティフィルタを開始",
  상관분석: "相関分析",
  상관분석_방어필터: "相関分析防御フィルタ",
  상관분석_보고서: "相関分析レポート",
  상관분석_보안탐지: "相関分析セキュリティ検出",
  상관분석_보안필터: "相関分析、セキュリティフィルタ",
  상관분석_보안필터_등록: "相関分析_セキュリティフィルターの登録",
  상관분석_보안필터_수정: "相関分析_セキュリティフィルターの修正",
  상관분석_수정: "相関分析修正",
  상관필터_분류: "関係フィルタの分類",
  상관필터_시작: "関係フィルタ開始",
  상관필터_Group_추가: "関係フィルタグループの追加",
  상단_영역_노출: "上段エリア露出",
  상세: "詳細",
  상세_설정: "詳細設定",
  상태: "状態",
  상태_코드: "ステータスコード",
  상태값: "ステータス値",
  새_비밀번호: "新しいパスワード",
  새_비밀번호_확인: "新しいパスワード確認",
  새_PIN번호_숫자_6자리_입력: "新しいPIN番号の数字6桁を入力",
  새_PIN번호_숫자_6자리_재입력: "新しいPIN番号の数字6桁の再入力",
  새로운_키워드명: "新しいキーワード名",
  새창: "新しいウィンドウ",
  새창보기: "新しいウィンドウを表示",
  생성: "Matching",
  생성_제한_공통_규칙: "作成制限共通のルール",
  생성업체: "生成業者",
  생성일: "生成日",
  생성하기: "作成する",
  샤드관리: "シャドの管理",
  서버: "サーバー",
  서버_그룹: "サーバーグループ",
  서버_그룹_등록: "サーバーグループ登録",
  서버_등록_필요: "サーバー登録が必要",
  서버_총_n개: "サーバーの合計%の",
  서버관리: "サーバー管理",
  서버그룹: "サーバーグループ",
  서버그룹_등록: "サーバーグループ登録",
  서버그룹_선택: "サーバーグループ選択",
  서버그룹_설정: "サーバーグループの設定",
  서버그룹_수정: "サーバーグループ変更",
  서버그룹_전체: "サーバーグループ全体",
  서버그룹관리: "サーバーグループ管理",
  서버그룹명: "サーバーグループ名",
  서버그룹선택: "サーバーグループ選択",
  서버등록: "サーバー登録",
  서버리스트: "サーバーリスト",
  서버목록_선택: "サーバーリスト選択",
  서버수: "サーバー数",
  서버수정: "サーバー変更",
  서버정보: "サーバー情報",
  서버추가: "サーバー追加",
  서버IP주소: "サーバーIPアドレス",
  서버IP주소_입력: "サーバーIPアドレスを入力",
  서비스: "サービス",
  서비스_등록: "サービス登録",
  서비스_수정: "サービスの修正",
  서비스_실행: "サービス実行",
  서비스_이용_재로그인_필요: "サービスを利用するには再ログインが必要です。",
  서비스_전환_신청하기: "サービス切り替えの申し込み",
  서비스관리: "サービス管理",
  서비스그룹별: "グループ",
  서비스그룹별_웹훅: "サービスグループ別のウェプフク",
  서비스그룹별_Syslog: "サービスグループ別Syslog",
  서비스대상_필터키: "サービス対象/フィルタキー",
  서비스대상_필터키_입력: "サービス対象/フィルタキー入力",
  서비스등록: "サービス登録",
  서비스리젼: "サービスリージョン",
  서비스명: "サービス名",
  서비스명_확인_경로_예시: "サービス名を確認パス例示",
  서비스별: "サービス別",
  서비스별_공격_IP주소_현황: "サービス別の攻撃IPアドレス現況",
  서비스별_로그발생: "サービス別ログ発生",
  서비스별_상세_확인하기: "サービス別の詳細を確認する",
  서비스별_탐지_분포: "サービス別検出分布",
  서비스별_탐지_양: "サービスごとの検出量",
  서비스별_탐지발생_현황: "サービス別検出発生状況",
  서비스종류: "サービス種類",
  서비스코드: "サービスコード",
  선별: "選別",
  선별_예외_설정: "選別、例外設定",
  선택: "選択",
  선택_값_선택: "選択値の選択",
  선택_취소: "選択解除",
  선택국가: "選択国",
  선택삭제: "選択削除",
  선택입력: "選択入力",
  선택입력_자동정렬: "選択入力(自動整列)",
  선택필터: "選択フィルタ",
  선택한_n개: "選択された%件",
  설명: "説明",
  설명_호스트명: "説明（ホスト名）",
  설명입력: "説明入力",
  설정: "設定",
  설정_값: "設定値",
  설정_필요한_필터: "設定に必要なフィルタ",
  설정검사: "設定検査",
  설정된_필터리스트: "設定されたフィルタリスト",
  설정하기: "設定する",
  설치: "インストール",
  설치_매뉴얼: "インストールマニュアル",
  설치_안내: "インストール手順",
  설치순서를_따라_진행합니다: "インストール手順に従って進みます。",
  설치시_궁금한_내용은_고객센터로_연락: `インストール時に気になる事案は、カスタマサポートセンター(<span class="c-red">plura@namutech.co.jp, 03-6240-0073</span> )までご連絡ください。`,
  설치시스템: "インストールホスト",
  설치할_에이전트의_운영체제를_선택합니다:
    "インストールするエージェントのオペレーティングホスト(OS)を選択します。",
  성공: "成功",
  성공건: "成功件",
  세션: "セッション",
  세션관리: "セッション管理",
  세션종료_시간: "セッション終了時間",
  세션코드: "セッションコード",
  소속그룹: "所属グループ",
  소속멤버: "所属メンバー",
  소스_디비_최신: "ソースディビ（最新）",
  소스디비_값: "ソースディビ値",
  소스디비_최신: "ソースディビ（最新）",
  소스보기: "ソースを表示",
  소스SQL: "ソースSQL",
  소프트웨어: "ソフトウェアー",
  소프트웨어_등록: "ソフトウェアー登録",
  소프트웨어_매핑: "ソフトウェアーマッピング",
  소프트웨어_수정: "ソフトウェアー修正",
  소프트웨어관리: " ソフトウェアー管理",
  소프트웨어등록: "ソフトウェアー登録",
  소프트웨어명: "名",
  소프트웨어ID: "ID",
  속성: "属性",
  속성_값_선택: "属性の値を選択",
  속성_선택: "属性の選択",
  속성값_복수_입력시_사용: "属性値を複数の入力時に使用",
  솔라시스템관리: "ソーラーシステムの管理",
  수: "水",
  수_정: "修正",
  수동: "手動",
  수동차단: "手動遮断",
  수요일: "水曜日",
  수정: "修正",
  수정_예시: "修正の例",
  수정일: "修正日",
  수정자: "修正者",
  수정자_수정일: "修正者/修正日",
  수정하기: "修正する",
  수정할_번호_입력: "変更する番号を入力",
  수정할_이름_입력: "変更する名前を入力",
  수정할_회사명_입력: "変更する会社名を入力",
  수집: "収集",
  수집_서버: "収集サーバー",
  수치: "数値",
  순서: "順序",
  순차_발생: "順次発生",
  숫자_6자리: "数字6桁",
  숫자_연속_6자리_이상: "数字連続6桁以上",
  숫자_영문_특문_조합_9자리:
    "数字（1〜0）、英文大文字と小文字（a〜z、A〜Z）、特殊文字（！、@、＃、$、%など）の組み合わせ9桁以上",
  숫자_영어대소문자_특수문자_9자리_이상:
    "パスワードを変更すると、既存のアカウントはログアウトされます。",
  숫자형: "数字型",
  스코어: "スコア",
  스크립트: "スクリプト",
  스키마_비교: "スキーマ比較",
  스토리지: "ストレージ",
  스토리지_등록: "ストレージの登録",
  스토리지_수정: "ストレージの修正",
  스토리지관리: " ストレージ管理",
  스토리지매핑_등록: "ストレージマッピングの登録",
  스토리지매핑_수정: "ストレージマッピングの修正",
  스토리지명: "ストレージ名",
  스토리지사용정보: "ストレージの使用情報",
  스토리지타입: "ストレージタイプ",
  스토리지항목: "ストレージアイテム",
  스토리지항목_등록: "ストレージアイテムの登録",
  스토리지항목관리: "ストレージアイテムの管理",
  스토리지항목주소: "ストレージアイテムアドレス",
  승인: "承認",
  승인자: "承認者",
  시: "時",
  시간: "時間",
  시간_설정: "時間設定",
  시간대: "時間帯",
  시간대별_Login_분석: "時間帯別Login分析",
  시간별_로그발생: "時間別ログ発生",
  시간별_로그발생_현황: "時間別ログ発生状況",
  시간별_로그업로드_분포: "時間別ログアップロード分布",
  시간별_로그업로드_현황: "時間別ログアップロード現況",
  시간별_총_탐지발생_현황: "時間別合計検出発生状況",
  시간별_탐지_현황_그래프: "時間別検出状況グラフ",
  시간별_탐지로그발생: "時間別_探知ログ発生",
  시간별_탐지로그발생_현황: "時間別探知ログの発生状況",
  시간선택: "時間選択",
  시간설정: "時間設定",
  시도: "試し",
  시도응답인증: "試み応答認証",
  시도횟수: "試行回数",
  시스로그: "シスログ",
  시스로그_업로드: "シスログアップロード",
  시스로그_업로드_합계: "シスログアップロード合計",
  시스로그미업로드시스템: "ホストログ未アップロードホスト",
  시스템: "ホスト",
  시스템_단일필터: "ホストシングルフィルタ",
  시스템_단일필터_등록: "ホストシングルフィルタ登録",
  시스템_단일필터_수정: "ホストシングルフィルタの修正",
  시스템_단일필터추가: "ホスト単一フィルタを追加",
  시스템_등록: "ホスト登録",
  시스템_등록_필요: "ホストが登録必要",
  시스템_로그_취합은_자동_지원: "ホストログ収集は自動サポート",
  시스템_방어필터: "ホスト防御フィルタ",
  시스템_수정: "ホスト修正",
  시스템_시스템관리_호스트선택_호스트등록: "システム > システム管理 > 収集ホスト選択 > ホスト登録",
  시스템_업로드: "ホストアップロード",
  시스템_웹: "ホスト/ウェブ",
  시스템_입력: "ホスト入力",
  시스템_전체: "ホスト全体",
  시스템_전체로그: "ホスト全体のログ",
  시스템_총_n개: "ホスト総%個",
  시스템_필터탐지: "ホストフィルタ検出",
  시스템관리: "システム管理",
  시스템등록: "システム登録",
  시스템로그: "ホストログ",
  시스템로그_모듈_설치: "ホストログモジュールをインストール",
  시스템로그_방어: "ホストログ防衛",
  시스템로그_분석: "システムログ分析",
  시스템로그_수집: "ホストログ収集",
  시스템로그_수집_경로: "ホストログ収集のパス",
  시스템로그_업로드: "ホストログのアップロード",
  시스템로그_업로드_수: "ホストログアップロード数",
  시스템정보: "ホスト情報",
  시스템추가: "ホスト追加",
  시스템필터_추가: "<strong>ホストフィルタ</strong>追加",
  시작: "スタート",
  시작일: "開始日",
  식별: "識別",
  식별키_트리값: "식識別キーのツリー値",
  신규: "新規",
  신규필터: "新規フィルター",
  실시간방어: "リアルタイム防御",
  실시간탐지: "リアルタイム検出",
  실패: "失敗",
  실패건: "失敗件",
  실행: "実行",
  실행명령어: "実行コマンド",
  심각도: "重要度",
  심각도_설정: "深刻さ設定",
  심각도별: "深刻度別",
  아이디: "ユーザ名",
  아이디_이메일: "ユーザ名（メールアドレス）",
  아이디_이메일_입력: "ユーザ名（メールアドレス）を入力",
  아이디_IP주소_날짜_검색: "ユーザ名、IPアドレス、日付、検索",
  아이디이메일: "ユーザ名（メール）",
  아티팩트: "アーティファクト",
  아티팩트_수집: "アーティファクト収集",
  안내보기: "案内表示",
  알림: "通知",
  알림_간격설정: "通知間隔設定",
  알림_그룹: "通知グループ",
  알림_메일_설정: "通知（メール）の設定",
  알림_시간설정: "通知時間の設定",
  알림_차단기간은_최소_1일부터_최대_3650일_이내로_설정가능합니다:
    "ブロック期間は最低1日から最大3650日以内に設定可能です。",
  알림_트래픽제한_설정_값을_입력해주세요: "トラフィック制限の設定値を入力してください",
  알림받을_주소입력: "通知受信アドレスを入力",
  알림설정: "通知設定",
  알림필터: "通知フィルター",
  알림필터_등록: "通知フィルター登録",
  알림필터_수정: "通知フィルター修正",
  약관동의: "利用規約に同意",
  어제: "昨日",
  어제날짜: "昨日の日付",
  언어: "言語",
  언어_설정: "言語設定",
  업데이트: "アップデート",
  업데이트_버전: "アップデートバージョン",
  업데이트_현황: "アップデート状況",
  업데이트_n_버전: "アップデート(%)バージョン",
  업데이트버전: "アップデートバージョン",
  업데이트일: "アップデート日",
  업데이트중: "アップデート中",
  업로드: "アップロード",
  업로드_중지: "アップロードを停止",
  업로드수: "アップロード数",
  업로드용량: "アップロード容量",
  업체: "企業",
  업체_등록: "企業登録",
  업체별_탐지_분포: "企業別の検出分布",
  업체별_탐지_수: "企業別の検出数",
  업체설정: "企業設置",
  없음: "なし",
  에러_개인정보처리방침에동의해라: "個人情報の処理方針に同意してください。",
  에러_검색값을입력해라: "検索値を入力してください。",
  에러_검색대상을선택해라: "検索対象を選択してください。",
  에러_고객지원이용약관동의해주세요: "サポート利用規約に同意してください。",
  에러_관리자에게문의후로그인시도해주시기바랍니다:
    "管理者に連絡した後、ログインしようとしてください。",
  에러_권한이없습니다_관리자에게문의하세요: "권한이 없습니다.\n관리자에게 문의하세요.",
  에러_기간설정: "期間設定が間違って入力されました。",
  에러_네트워크: "ネットワークエラーです。",
  에러_다시로그인해주세요:
    "長時間使用していないセッションが切れました。\n 再度ログインしてください。",
  에러_다시시도해주세요: "もう一度試してください。",
  에러_다운로드_수_확인: "ダウンロード数が間違って入力されました。\n確認してください。",
  에러_데이터가삭제되었습니다: "データが削除されました。",
  에러_데이터가없습니다: "データがありません。",
  에러_동일한_비밀번호_새_비밀번호_입력:
    "使用中のパスワードと同じです。\n新しいパスワードを入力してください。",
  에러_등록된시스템없다: "登録されたホストがありません。",
  에러_등록된티켓사이트없음: "登録されたチケットサイトがありません。",
  에러_등록이실패했다: "登録に失敗しました。",
  에러_등록중에러발생했습니다: "登録中にエラーが発生しました。",
  에러_등록한필터가없다: "登録したフィルタがありません。",
  에러_로그인10회실패로로그인이제한됩니다: "ログイン10回の失敗でログインが制限されます。",
  에러_로그인허용IP주소_사용설정_불가능:
    "登録された情報がないため,ログイン許可IPアドレスの使用設定ができません。",
  에러_리소스명중복: "すでに登録されているリソースコード名です。",
  에러_리소스코드중복: "すでに登録されているリソースコードです。",
  에러_만료된정보다: "有効期限が切れた情報です。",
  에러_모든값을입력해라: "すべての情報を入力してください。",
  에러_발생로그없습니다: "発生したログがありません。",
  에러_방어_명령어를_입력하세요: "防御コマンドを入力してください。",
  에러_방어대상1개이상선택되야함확인후다시시도하세요:
    "防御対象が1つ以上選択する必要の設定が可能です。\n確認後、再度お試しください。",
  에러_백업루트를선택해주세요: "バックアップルートを選択してください。",
  에러_복원개수초과: "最大10個のホスト復元が可能です。\n情報を確認してください。",
  에러_복원중복: "設定情報に復元済みログが含まれています。\n情報を確認してください。",
  에러_복원횟수초과: "一日10件の復元が可能です。\n(完了した復元基準)",
  에러_비밀번호는_6자리_이상_입력하셔야_합니다: "パスワードは6文字以上入力する必要があります。",
  에러_비밀번호를_입력해_주세요: "パスワードを入力してください。",
  에러_비밀번호를입력해주세요: "パスワードを入力してください。",
  에러_비밀번호일치하지않는다: "パスワードが一致しません。",
  에러_비밀번호일치하지않습니다: "パスワードが一致しません。",
  에러_빈값을입력하세요: "入力しながった情報があります。情報を入力してください。",
  에러_사용자가등록한상관필터가없다: "登録された相関分析フィルタがありません。",
  에러_삭제가실패했다: "削除に失敗しました。",
  에러_삭제중인_시스템: "削除中のホストログがあります。\n削除完了後に試しください。",
  에러_서비스리젼과스토리지타입선택: "サービスリージョンとストレージタイプを選択してください。",
  에러_서비스리젼과스토리지타입주키퍼명선택:
    "サービスエリア/ストレージタイプ/ジュキーパー/ Collectionを選択してください。",
  에러_서비스명중복: "既に登録されたサービス名です。",
  에러_선택한운영체제의시스템없다: "選択したオペレーティングシステムのホストがありません。",
  에러_세자리수: "3桁の数字を入力してください。",
  에러_수정권한: "変更権限がありません。",
  에러_수정이실패했다: "修正に失敗しました。",
  에러_시간설정다시: "時間を再設定してください。",
  에러_시스템등록불가_관리자에게문의: "ホスト登録ができません。 管理者にお問い合わせください。",
  에러_아이디를입력해주세요: "IDを入力してください。",
  에러_아이디및비밀번호를입력해라: "ユーザ名とパスワードを入力してください。",
  에러_아이디정보가없다이미등록된아이디다: "ユーザ名情報がないか、既に登録されているユーザ名です。",
  에러_업로드경로가하나이상필요: "アップロードパスが1つ以上ある必要があります",
  에러_올바른이메일주소입력해라: "有効なメールアドレスを入力してください。",
  에러_요청사유를입력하라: "要求理由を入力してください。",
  에러_웹로그업로드경로하나이상필요: "アップロードパスが1つ以上ある必要があります。",
  에러_이름에특수문자입력못한다: "名前に特殊文字を使用することができません。",
  에러_이름입력해라: "名前を入力してください。",
  에러_이메일입력해라: "メールアドレスを入力してください。",
  에러_이미가입된이메일이다: "すでに登録されたメールです。",
  에러_이미등록된내부IP주소다: "既に登録されている内部IPアドレスです。",
  에러_이미등록된목록그룹이다: "既に登録されているリストのグループです。",
  에러_이미등록된태그입니다: "すでに登録済みのタグです。",
  에러_이미등록된호스트명입니다: "既に登録されているホスト名です。",
  에러_이미선택된서버그룹이다: "既に選択したサーバーのグループです。",
  에러_이미선택된항목입니다: "すでに選択された項目です。",
  에러_이용약관에동의해라: "利用規約に同意してください。",
  에러_자동등록방지를확인해라: "自動登録防止を確認してください。",
  에러_정보를입력해라: "情報を入力してください。",
  에러_중복된값: "重複した値です。",
  에러_채널이없거나선택되지않았을경우:
    "チャンネルが存在しないか選択されていない場合は、 \nKey,Value値を入力する必要があります。",
  에러_최대n일입력가능: "最大%日まで入力可能です。（最大%年）",
  에러_컴플라이언스중복: "既に登録されているコンプライアンス名またはIDです。",
  에러_코드중복: "既に登録されているコードです。",
  에러_탐지필터_등록해_주세요: "検知フィルターを登録してください。",
  에러_티켓시스템설정안했다: "チケット連動設定後に使用可能です。 n（管理>連動>チケット）",
  에러_필수값을입력해라: "必要な値を入力してください。",
  에러_필수정보를입력해라: "必要な情報を入力してください。",
  에러_회사명입력해라: "会社名を入力してください。",
  에러_IP주소입력후등록가능합니다: "IPアドレス入力後登録可能です。",
  에러_n개_이상_선택: "％以上の情報を選択してください。",
  에러_n이후에이탈값확인가능: "%以降に離脱値の確認が可能です。",
  에러_OTP메일_번호를_확인해_주세요: "OTPメール番号をご確認ください。",
  에러_PIN번호가_일치하지_않습니다: "PIN番号が一致しません。",
  에러_PIN번호를_확인해_주세요: "PIN番号を確認してください。",
  에이전트: "エージェント",
  에이전트_다운로드: "エージェントのダウンロード",
  에이전트_등록: "エージェント登録",
  에이전트_삭제: "エージェントの削除",
  에이전트_설정값: "エージェントの設定値",
  에이전트_설치: "エージェントのインストール",
  에이전트_설치_감사정책_안내:
    "エージェントのインストールは、高級監査ポリシーの設定を伴ってシステムログの変更が発生します。",
  에이전트_설치_동의: "エージェントのインストールためにサービスの理解と同意が必要です。",
  에이전트_설치_동의_추가안내: "(該当同意は最初の1回のみ行われます。)",
  에이전트_설치_오딧설정_안내:
    "エージェントのインストールは、オディト設定を伴ってシステムログの変更が発生します",
  에이전트_설치시간: "エージェントのインストール時間",
  에이전트_실행: "エージェント実行",
  에이전트_재실행: "エージェント再実行",
  에이전트_중지: "エージェントを停止",
  에이전트버전: "エージェントバージョン",
  에이전트상태: "エージェント状態",
  에이전트중지: "エージェント停止",
  에이전트중지_알림: "エージェント停止の通知",
  엔터프라이즈_탐지_분포: "エンタープライズの検出分布",
  연도월주차: "%年%月%週",
  연동: "連動",
  연동설정: "連動設定",
  영구: "永久",
  영어공격IP주소: "攻撃IPアドレス",
  예: "例",
  예외: "例外",
  예외_설정: "例外設定",
  예외그룹_추가: "例外グループの追加",
  예외설정_추가: "例外設定の追加",
  예외속성: "例外プロパティ",
  예외적용: "例外の適用",
  예외조건: "例外条件",
  오늘: "今日",
  오늘날짜: "今日の日付",
  오늘접속: "今日接続",
  오래된순: "古い順",
  오류: "エラー",
  오류메시지: "エラーメッセージ",
  오류발생: "エラー発生",
  오류보기: "エラー表示",
  오전: "午前",
  오후: "午後",
  옵션: "オプション",
  완료: "完了",
  완료시_네트워크_아이콘노출: `完了時 <span class="p-lr-3"><i class="fa fa-circle c-red"></i></span> アイコンが表示されます。`,
  완료시_네트워크_아이콘미노출: `完了されたら<span class="p-lr-3"><i class="fa fa-circle c-red"></i></span> アイコンが消えます。`,
  완료시_호스트_아이콘노출: `完了時 <span class="p-lr-3"><i class="fa fa-circle c-dark"></i></span> アイコンが表示されます。`,
  요소: "要素",
  요소_값_선택: "要素の値を選択",
  요소_선택: "要素の選択",
  요소수선택: "要素数を選択",
  요일: "曜日",
  요일_설정: "曜日設定",
  요일별: "曜日別",
  요일별_로그발생: "曜日別_ログ発生",
  요일별_로그업로드_분포: "曜日別ログアップロード分布",
  요일별_탐지_현황: "曜日別の検出状況",
  요청: "要求",
  요청_완료: "要請されました。",
  요청_URL: "要求URL",
  요청기간: "要請期間",
  요청로그_사이즈: "要求ログサイズ",
  요청사이즈: "要求サイズ",
  요청시간: "リクエスト時間",
  요청일: "リクエスト日",
  요청자: "リクエスト者",
  요청자_이메일: "リクエスタ/メール",
  용량: "容量",
  용량_로그수_서버수: "容量/ログ数/サーバー数",
  우선순위: "優先順位",
  우선순위_적용_공격유형_설정: "優先順位の適用、攻撃タイプの設定",
  우선순위_적용필터: "優先順位の適用フィルタ",
  우측_상단의: "右上の",
  운영: "運用",
  운영방식: "運営方式",
  운영체제: "オペレーティングシステム",
  운영체제_그룹: "オペレーティングシステムのグループ",
  운영체제_버전: "オペレーティングシステムのバージョン",
  운영체제_선택: "オペレーティングシステムの選択",
  운영체제버전: "オペレーティングシステムのバージョン",
  운영체제별_설치방법_매뉴얼과_동영상_참조:
    "オペレーティングシステム別のインストール詳細方法は、マニュアルとビデオを参照してください。",
  워드클라우드: "ワードクラウド",
  원격_제어: "リモート制御",
  원격지_서버_웹로그_수신_모듈_설치: "リモートサーバーのWebログ受信モジュールをインストール",
  원격지_서버_응용프로그램_전송_모듈_설치:
    "リモートサーバーアプリケーション転送モジュールのインストール",
  원격지_서버_SYSLOG_전송_설정: "リモートサーバーのSYSLOG転送設定",
  원격지_장비_SYSLOG_전송_설정: "リモート機器にSYSLOG送信設定",
  원본: "ソース",
  원본_로그: "元ログ",
  원본_주소: "元アドレス",
  원본_포트_이름: "ソースポートの名前",
  원본_호스트_이름: "元ホスト名",
  원본로그보기: "元ログの表示",
  월: "月",
  월별: "月別",
  월별_로그업로드_분포: "月別ログアップロード分布",
  월별_총_탐지발생_현황: "月別総検出発生の状況",
  월별_탐지_현황: "月別検出状況",
  월요일: "月曜日",
  웹: "ウェブ",
  웹_개인정보숨김: "ウェブの個人情報非表示",
  웹_로그_취합은_자동_지원: "ウェブログ収集は自動サポート",
  웹_서버: "ウェブサーバー",
  웹_수정: "ウェブ修正",
  웹_업로드: "ウェブアップロード",
  웹_웹방화벽: "ウェブ/ウェブファイアウォール",
  웹_전체로그: "ウェブ全体ログ",
  웹_탐지_예외: "ウェブ検出例外",
  웹_필터: "Webフィルタ",
  웹_필터_수정: "ウェブ_フィルタ修正",
  웹_필터탐지: "ウェブフィルタ検出",
  웹로그: "ウェブログ",
  웹로그_모듈_설치: "Webログモジュールをインストール",
  웹로그_방어: "ウェブログ防衛",
  웹로그_설정_안내: "ウェブログの設定案内",
  웹로그_수집: "ウェブログ収集",
  웹로그_수집_경로: "ウェブログの収集パス",
  웹로그_업로드: "ウェブログアップロード",
  웹로그_업로드_수: "ウェブログアップロード数",
  웹로그_업로드_합계: "ウェブログアップロード合計",
  웹로그_예외: "ウェブログの例外",
  웹로그_취합_설정: "ウェブログ収集設定",
  웹로그_취합_설정_확인: "Webログ収集設定の確認",
  웹로그미업로드시스템: "ウェブログ未アップロードホスト",
  웹로그예외: "ウェブログの例外",
  웹모듈_삭제: "Webモジュールを削除",
  웹모듈_삭제중: "Webモジュールを削除中",
  웹방화벽: "ウェブファイアウォール",
  웹방화벽_수정: "ウェブファイアウォール修正",
  웹방화벽_예외: "ウェブファイアウォールの例外",
  웹방화벽_예외설정: "Webファイアウォールの例外設定",
  웹방화벽_전체로그: "ウェブファイアウォール全体のログ",
  웹방화벽_필터: "ウェブファイアウォールフィルタ",
  웹방화벽_필터탐지: "ウェブファイアウォールのフィルター検出",
  웹서버_주소: "ウェブサーバーのアドレス",
  웹탐지_개인정보숨김: "ウェブ検出個人情報隠し",
  웹탐지개인정보숨김: "ウェブ検出個人情報隠し",
  웹필터_관리: "Webフィルタの管理",
  웹필터_리스트: "Webフィルタリスト",
  웹필터_분류: "Webフィルタの分類",
  웹필터_추가: "<strong>ウェブフィルタ</strong>追加",
  웹필터_추가_텍스트: "ウェブフィルタを追加",
  웹필터관리: "Webフィルタ管理",
  웹필터등록: "Webフィルタ登録",
  웹필터리스트: "Webフィルタリスト",
  웹필터수정: "Webフィルタ修正",
  웹필터추가: "Webフィルタを追加",
  웹훅: "ウェプフク",
  웹훅_선택_시_주소_자동_입력: "ウェブフック選択時にアドレスが自動入力されます。",
  웹훅_알림: "ウェプフク通知",
  웹훅_Webhook: "ウェプフク(Webhook)",
  웹훅지원_항목: [
    "Googleチャット(Google Chat)",
    "ライン(Line)",
    "スルレク(Slack)",
    "Jandi",
    "テレグラム(Telegram)",
  ],
  위험_IP주소: "危険IPアドレス",
  위험도: "危険度",
  위험도별_로그발생: "危険度別ログ発生",
  위험도별_로그발생_현황: "危険度別ログ発生状況",
  위험도별_설정: "危険度別に設定",
  유레카_비교: "ユーレカ比較",
  유료_: "有料%",
  유료_사용_기간: "有料使用期間",
  유료_PREMIUM: "有料(PREMIUM)",
  유료_STANDARD: "有料(STANDARD)",
  유의사항: "注意事項",
  유입경로: "流入経路",
  유출정보: "流出情報",
  유형: "タイプ",
  유형_등록: "タイプ登録",
  유형_발급_메일발송: "タイプ(発行/メール送信)",
  유형선택: "タイプ選択",
  응답: "応答",
  응답_본문: "応答本文",
  응답로그_사이즈: "応答ログサイズ",
  응답본문_유출영역: "応答本文流出領域",
  응답본문내용: "応答本文内容",
  응답사이즈: "応答サイズ",
  응용프로그램: "アプリケーション",
  응용프로그램_단일필터: "アプリケーションシングルフィルタ",
  응용프로그램_단일필터_등록: "アプリケーションシングルフィルタ登録",
  응용프로그램_단일필터_수정: "アプリケーション単一フィルタの修正",
  응용프로그램_로그: "アプリケーションログ",
  응용프로그램_로그_업로드: "アプリケーションログアップロード",
  응용프로그램_로그_업로드_수: "アプリケーションログアップロード数",
  응용프로그램_사용자: "アプリケーション(ユーザ)",
  응용프로그램_사용자_수정: "アプリケーション(ユーザ)修正",
  응용프로그램_사용자정의: "アプリケーションのカスタマイズ",
  응용프로그램_사용자정의_로그: `アプリケーション<br>ユーザー定義ログ</br>`,
  응용프로그램_원본: "アプリケーションのソース",
  응용프로그램_원본_수정: "アプリケーション(原本)修正",
  응용프로그램_원본_업로드: "アプリケーションソースのアップロード",
  응용프로그램_원본로그: "アプリケーションのソースログ",
  응용프로그램_원본로그_수집: "アプリケーションのソースログを収集",
  응용프로그램_원본로그_수집_경로: "アプリケーションソースログ収集パス",
  응용프로그램_태그: "アプリケーションタグ",
  의문문_네트워크_격리: "ネットワークを隔離しますか?",
  의문문_네트워크_격리해제: "ネットワーク隔離を解除しますか?",
  의문문_리스트에서_삭제하시겠습니까: "リストから削除しますか？",
  의문문_무료서비스사용중이세요: "無料サービス使用中ですか?",
  의문문_방화벽을OFF하쉴: "ファイアウォールをOFFしますか?",
  의문문_방화벽을ON하쉴: "ファイアウォールをONしますか?",
  의문문_보안_수정: "%しますか?",
  의문문_삭제명령어실행된다실행후엔ON불가능하다OFF할거냐:
    "削除コマンドが実行されます。\n 削除コマンドの実行後には、ONに変更できません。\n OFF処理しますか?",
  의문문_삭제하시겠습니까_취소불가: "削除しますか？取り消しはできません。",
  의문문_선택한n개필터삭제하시겠습니까:
    "選択された%個のフィルタを削除しますか?\n削除すると、削除後に発生したログは、検出されず、通知発送も停止します。",
  의문문_설정검사_진행: "設定検査を進めますか?",
  의문문_수정하시겠습니까: "修正しますか?",
  의문문_수정한시간으로재설정된다적용하시겠습니까:
    "変更された時間にリセットされます。\n適用しますか?",
  의문문_시스템로그_수정: "ホストログの分析を%に修正しますか？",
  의문문_실행하시겠습니까: "%実行しますか?",
  의문문_차단IP주소등록하쉴: "ブロックIPアドレスを登録しますか？",
  의문문_티켓발행하시겠습니까: "チケットを発行しますか？",
  의문문_티켓테스트발행하시겠습니까: "入力された情報でチケット発行のテストをしますか？",
  의문문_필터삭제:
    "選択したフィルタを削除しますか?\n削除すると、削除後に発生したログは、検出されず、通知発送も停止します。",
  의문문_필터상태를ONOFF하시겠습니까: "フィルタの状態を % しますか?",
  의문문_호스트_종료: "ホストを終了しますか？",
  의문문_IP주소삭제: "IPアドレスを削除しますか？ n削除したらブロックが解除されます。",
  의문문_IP주소차단해제: "IPアドレスを%しますか？",
  의문문_n_개의_시스템_복원: "[%]個のホストを復元しますか?",
  이동: "移動",
  이름: "名前",
  이름_입력: "名前を入力",
  이름_or_이메일_검색: "名前orメール検索",
  이메일: "メール",
  이메일_입력: "メール入力",
  이메일ID: "メール(ID)",
  이미_등록된_업체입니다: "既に登録されている企業です。",
  이미지: "イメージ",
  이벤트: "イベント",
  이벤트타입: "イベントタイプ",
  이벤트타입_관리: "イベントタイプの管理 ",
  이벤트타입_ID: "イベントタイプ（ID）",
  이벤트타입_ID_제공_형태: "イベントタイプ（ID）提供形態",
  이벤트타입관리: "イベントタイプの管理",
  이벤트타입등록: "イベントタイプの登録",
  이벤트타입명: "イベントタイプ名",
  이벤트타입선택: "イベントタイプを選択",
  이벤트타입수정: "イベントタイプの修正",
  이벤트ID: "イベントID",
  이상: "以上",
  이용서비스: "利用サービス",
  이용약관: "利用規約",
  이용약관_체크: "利用約款をチェックしてください。",
  이전: "以前",
  이전_로그보기: "以前のログ表示",
  이전로그검색: "以前のログ検索",
  이탈값: "離脱値",
  이하: "以下",
  이해_및_동의_안내: "理解して同意します。",
  인스톨에이전트_설치_안내: "エージェントをインストールして、サービスをご利用下さい。",
  인증: "認証",
  인증메일_재발송: "認証メール再送",
  인증메일을_재발송하시겠습니까: "認証メールを再送しますか？",
  인증메일이_발송되었습니다: "認証メールが送信されました。",
  인증상태: "認証状態",
  인증실패_로그인_제한: "認証に失敗しログイン制限",
  인증실패_횟수: "認証に失敗した回数",
  인코딩: "エンコード",
  일: "日",
  일_며칠: "日",
  일개월: "一ヶ月",
  일반: "一般",
  일별: "日別",
  일별_로그발생: "日別ログ発生",
  일별발생_현황: "日別発生状況",
  일요일: "日曜日",
  일일_매트릭스: "毎日マトリックス",
  일정: "日程",
  일주: "一周",
  일치: "一致",
  임계치관리: "しきい値管理",
  임계치등록: "しきい値登録",
  임계치명: "しきい値名",
  임계치수정: "しきい値の変更",
  임계치코드: "しきい値コード",
  임시_비밀번호_이메일로_보내기: "仮パスワードメールで送信",
  임시비밀번호: "仮パスワード",
  임시비밀번호_메일발송: "仮パスワードメール送信",
  임시비밀번호_발급: "仮パスワード発行",
  임시비밀번호_발급_히스토리: "仮パスワード発行履歴",
  임시비밀번호가_발송되었습니다: "仮パスワードが送信されました。",
  임시비밀번호를_발급시_재사용불가: "仮パスワードを発行したら既存のパスワードは使用できません！",
  입력: "入力",
  입력된_정보를_확인해주세요: "入力された情報を確認してください。",
  입력정보를_확인해주세요: "入力情報を確認してください。",
  있음: "あり",
  자동: "自動",
  자동등록방지: "自動登録防止",
  자동차단: "自動遮断",
  자세한_정보_도움말_이용: "詳しい情報はヘルプをご利用ください。",
  자세히보기: "詳細",
  작동: "動作",
  작성_시_공격유형_2depth: "を作成する際、攻撃の種類2depth",
  작성일: "作成日",
  작성자: "著者",
  작성자_이메일: "作成者(メール)",
  잠금상태: "ロック状態",
  잠김: "ロック",
  장비_등록: "機器登録",
  장비등록: "機器の登録",
  재발송: "再送",
  재설정: "リセット",
  재시도: "再試行",
  재시작: "再起動",
  재입력: "再入力",
  재전송공격: "再伝送攻撃",
  재정렬: "並べ替え",
  저장: "保存",
  적_용: "適用",
  적용: "適用",
  적용된_정규식_노출: "適用された正規式の露出",
  적용목록_관리: "適用リストの管理리",
  적용목록관리: "適用リストの管理",
  적용상태: "適用状態",
  적용서버: "適用サーバー",
  적용시간: "適用時間",
  적용시스템그룹: "適用ホストグループ",
  적용업체: "適用業者",
  적용일: "適用日",
  적용하기: "適用する",
  전송: "転送",
  전송_방식: "伝送方式",
  전술: "戦術",
  전술_등록: "戦術登録",
  전술_매핑: "戦術マッピング",
  전술_수정: "戦術変更",
  전술_탐지_TOP5: "戦術検出TOP5 ",
  전술관리: "戦術管理",
  전술등록: "戦術登録",
  전술명: "戦術名",
  전술ID: "戦術ID",
  전월: "前月",
  전월발생: "前月発生",
  전일: "前日",
  전일_그래프_제공: "前日グラフを提供",
  전일발생: "昨日発生",
  전주: "前週",
  전주발생: "前週発生",
  전체: "全体",
  전체로그: "全ログ",
  전체로그_바로가기: "全ログのショートカット",
  전체로그_수집: "全体ログ収集",
  전체로그_저장: "全ログ保存",
  전체선택: "全選択",
  전체차단동작: "全ブロックの動作",
  전화번호_휴대폰: "電話番号(ケータイ)",
  전환: "転換",
  점검_중_예외: "チェック中の例外",
  접근_제한: "アクセス制限",
  접속_예외: "接続例外",
  접속_횟수: "接続回数",
  접속_IP: "接続IP",
  접속수: "接続数",
  접속일: "接続日",
  접속자: "接続者",
  접속파일_TOP5: "接続ファイルTOP5",
  접속하기: "接続する",
  접속IP: "接続IP",
  접속Path_TOP5: "接続Path TOP5",
  정규식: "正規式",
  정렬: "ソート",
  정렬코드: "整列コード",
  정렬해제: "ソート解除",
  정보: "情報",
  정보_마케팅_메일: "情報（マーケティング）メール",
  정보_수집: "情報収集",
  정보_입력: "情報の入力",
  정보검색: "情報検索",
  정보를_선택하세요: "情報を選択してください。",
  정보메일_서비스: "情報メールサービス",
  정보메일수신: "情報メール受信",
  정보수집: "情報収集",
  정보수집이실패하였습니다: "情報収集に失敗しました。",
  정보수집후사용가능: "情報収集後に使用可能です。",
  정보입력: "情報入力",
  정보입력_다수복사붙여넣기가능: "情報入力（多数のコピー貼り付けが可能）",
  정상: "通常",
  정지: "停止",
  제거문자열: "削除文字列",
  제거유형: "削除タイプ",
  제공: "提供",
  제목: "タイトル",
  제약조건_인덱스_키: "制約条件（インデックス、キー）",
  제어: "制御",
  제외: "除く",
  제외기간_설정: "除く期間の設定",
  제외설정: "除く設定",
  제한: "制限",
  조건: "条件",
  종료: "終了",
  종료일: "終了日",
  종합: "総合",
  주별: "週別",
  주별_총_탐지발생_현황: "週別総検出発生の状況",
  주소: "アドレス",
  주요개체: "主なオブジェクト",
  주요개체_선택: "主要オブジェクト選択",
  주요개체설정: "主なオブジェクトの設定",
  주요정보: "重要な情報",
  주의_개인정보_도용_노출:
    "同じIDとパスワードを複数のサイトで使用すると、個人情報の盗難にさらされる可能性があります。",
  주의사항: "注意事項",
  주체_계정_이름: "主体アカウント名",
  주키퍼관리: "ジュキーパーの管理",
  주키퍼등록: "ジュキーパー登録",
  주키퍼명: "ジュキーパー名",
  주키퍼선택: "ジュキーパー選択",
  주키퍼수정: "ジュキーパー修正",
  주키퍼주소: "ジュキーパーアドレス",
  준비중: "準備中",
  중간: "中",
  중복된_정보가_존재합니다_확인바랍니다: "重複情報があります。\n確認ください。",
  중복체크: "重複チェック",
  중지: "停止",
  중첩검색: "ネスト検索",
  즉시방어: "すぐ防御",
  즉시차단: "すぐにブロック",
  지금_변경: "今すぐ変更",
  지역: "地域",
  지원단계: "サポート段階",
  지원상태: "サポート状態",
  지원시작: "サポート開始",
  지원시작일: "サポート開始日",
  지원종료: "サポート終了",
  지원종료일: "サポート終了日",
  직접: "直接",
  직접_n: "直接[%]",
  직접등록: "直接登録",
  직접입력: "直接入力",
  직책: "職責",
  직책_입력: "職責入力",
  진행: "進行",
  진행사항: "進行内容",
  진행상태: "進行状態",
  진행상황: "進行状況",
  진행자: "進行者",
  진행중: "進行中",
  집계: "集計",
  차단: "ブロック",
  차단_안내: "遮断のご案内",
  차단_안내_문구: "遮断案内文",
  차단_해지: "ブロック解除",
  차단기간: "ブロック期間",
  차단되었습니다: "ブロックされました。",
  차단모드: "ブロックモード",
  차단실행중: "ブロック実行中",
  차단IP주소: "ブロックIPアドレス",
  차단IP주소_등록: "ブロックIPアドレスの登録",
  채널: "チャンネル",
  채널_관리: "チャネル管理",
  채널_정렬_관리: "チャンネル配置の管理",
  채널_ID: "チャンネル（ID）",
  채널관리: "チャネル管理",
  채널등록: "チャンネル登録",
  채널명: "チャンネル名",
  채널명_EN: "チャンネル名(EN)",
  채널별_로그수: "チャンネル別ログ数",
  채널수정: "チャネル修正",
  채널정렬: "チャンネルソート",
  채널ID: "チャネルID",
  채널ID_중복체크: "チャネルID（重複チェック）",
  처리_결과: "処理結果",
  처리상태: "処理状態",
  처리원인: "処理原因",
  처리일: "処理日",
  처음: "初め",
  초: "秒",
  초_내: "秒以内",
  초기화: "初期化",
  초단위_입력: "秒単位入力",
  초대메일발송일: "招待メール発送日",
  초대현황: "招待現況",
  총: "合計",
  총_로그_수: "ログの総数",
  총_로그업로드: "総ログアップロード",
  총_로그업로드_수: "合計ログアップロード数",
  총_매핑_항목: "総マッピング項目",
  총_발생로그: "総発生ログ",
  총_탐지: "総検出",
  총_탐지건수: "総検出数",
  총_필터: "総フィルター",
  총_n개: "合計%件",
  총게시물: "総投稿",
  총접속: "総アクセス",
  최근_탐지_노출_리스트: "最近検出露出リスト",
  최근동기화시도일: "最近同期しようとした日",
  최근업데이트: "最近の更新",
  최근접속일: "最近接続日",
  최대: "最大",
  최대_1_2분소요_실패시재시도:
    "最大1〜2分かかることがあります。更新で結果を確認できます。失敗した場合は再試行してください。",
  최대_16자: "*最大16文字",
  최대_요청: "最大要求",
  최대_응답: "最大応答",
  최대_n개: "最大%個",
  최대_n개_검색가능: "最大%個の検索が可能です。",
  최대값: "最大値",
  최대n년: "最大%年",
  최소: "最小",
  최소_길이_8자리: "最小長8桁、文字の組み合わせのルールなし",
  최소값: "最小値",
  최신버전: "最新バージョン",
  최신버전_다운로드_링크: "最新バージョンのダウンロードリンク",
  최신버전_패치버전: "最新バージョン (パッチバージョン)",
  최신순: "最新順",
  최신업데이트일: "最新アップデート日",
  최종검사: "最終検査",
  최종적용: "最終適用",
  최종접속일: "最終接続日",
  추가: "追加",
  추가_정보입력: "追加情報入力",
  추가저장: "追加保存",
  추가저장기간: "追加保存期間",
  추천: "おすすめ",
  추천필터: "おすすめフィルタ",
  추천필터_등록: "おすすめフィルタ登録",
  추천필터등록: "推奨フィルタ登録",
  추천필터등록필터메뉴얼보기: "推奨フィルタ/登録フィルタマニュアル表示",
  추천필터등록하러가기: "おすすめフィルタ登録しに行く",
  추천필터수정: "おすすめフィルタの変更",
  추출로그: "抽出ログ",
  추출수: "抽出数",
  추출순: "抽出順",
  추출일: "抽出日",
  추출IP주소: "抽出IPアドレス",
  출력: "出力",
  취_소: "キャンセル",
  취소: "キャンセル",
  취약경로: "脆弱パス",
  취약항목: "脆弱項目",
  취합_동일로그수: "収集同じログの数",
  취합로그: "収集ログ",
  취합로그별_서버수: "収集ログ別サーバーの数",
  취합중: "収集中",
  치환: "置換",
  치환대상: "置換対象",
  치환할_값: "置換する値",
  커널: "カーネル",
  커서: "カーソル",
  커서마크_수정하시겠습니까:
    "OFFにすると検索の「次へ」ページの読み込みが遅くなります。\n修正しますか？",
  컬럼: "カラム",
  컬럼_위치_날짜_앞: "列位置: 日付の前",
  컬럼명: "列名",
  컬렉션관리: "コレクションの管理",
  컬렉션등록: "コレクションの登録",
  컬렉션명: "コレクション名",
  컬렉션수정: "コレクションの修正",
  컴플라이언스: "コンプライアンス",
  컴플라이언스_등록: "コンプライアンス登録",
  컴플라이언스_리스트: "コンプライアンスリスト",
  컴플라이언스_매핑: "コンプライアンスマッピング",
  컴플라이언스_설정: "コンプライアンス設定",
  컴플라이언스_정보: "コンプライアンス情報",
  컴플라이언스명: "コンプライアンス名",
  컴플라이언스명_변경: "コンプライアンス名変更",
  컴플라이언스상세: "コンプライアンス詳細",
  컴플라이언스ID: "コンプライアンスID",
  코드: "コード",
  코드_및_항목_설명: "コードと項目説明",
  코드_불러오기: "コードインポート",
  코드_선택: "コードの選択",
  코드_입력: "コード入力",
  코드_코드명: "コード/コード名",
  코드_코드명_코드설명: "コード/コード名/コードの説明",
  코드그룹: "コードグループ",
  코드그룹등록: "コードグループの登録",
  코드그룹명: "コードグループ名",
  코드그룹선택: "コードグループ選択",
  코드그룹설명: "コードグループ説明",
  코드그룹수정: "コードグループの変更",
  코드명: "コード名",
  코드설명: "コード説明",
  코드수정: "コード修正",
  코드순: "コード順",
  코드정보: "コード情報",
  코드정보입력_개발자입력: "コードの情報を入力（開発者の入力）",
  코드추가: "コード追加",
  쿠키: "クッキー",
  쿼리: "クエリ",
  쿼리_요청본문: "クエリ || リクエスト本文",
  크리덴셜_스터핑: "クレデンシャルスタッフィング ",
  키: "キー",
  키워드: "キーワード",
  키워드_매핑: "キーワードのマッピング",
  키워드저장크기: "キーワード保存サイズ",
  타겟_디비_패치대상: "ターゲットディビ（パッチ対象）",
  타겟디비_값: "ターゲットディビ値",
  타겟디비_패치대상: "ターゲットディビ（パッチ対象）",
  타이틀_시스템: "システム",
  타입: "タイプ",
  타입별: "タイプ別",
  탐지: "検出",
  탐지_단계: "検出段階",
  탐지_방어: "検出/防御",
  탐지_분포: "検出分布",
  탐지_양: "検出量",
  탐지_예외: "検出例外",
  탐지_우선순위_설정: "検出の優先順位を設定",
  탐지_우선순위_적용: "検出の優先順位を適用",
  탐지_위험도별_현황: "検出/リスク別状況",
  탐지_필터: "検出フィルタ",
  탐지_TOP5: "検出TOP5",
  탐지간_인터벌_시간: "検出間、インターバル時間",
  탐지갯수: "検出件数",
  탐지노출: "検出露出",
  탐지로그: "検出ログ",
  탐지로그_바로가기: "検出ログのショートカット",
  탐지로그_발생_시스템: "検出ログ発生ホスト",
  탐지로그_시스템: "検出ログホスト",
  탐지모드: "検出モード",
  탐지서비스: "検出サービス",
  탐지수: "検出数",
  탐지수_기준: "検出数基準",
  탐지시간: "検出時間",
  탐지알림: "検出通知",
  탐지일: "検出日",
  탐지조건: "検出条件",
  탐지키워드_TOP5: "検出キーワードTOP5",
  탐지타입: "検出タイプ",
  탐지필터: "検出フィルタ",
  탐지필터_분포: "検出フィルタ分布",
  탐지필터_TOP5: "検出フィルタTOP5",
  태그: "タグ",
  태그_관리: "タグ管理",
  태그_선택: "タグの選択",
  테마설정: "テーマ設定",
  테이블: "テーブル",
  템플릿_변수_입력: "テンプレート領域内の変数に適した値を入れて置き換えてください。",
  템플릿_정보: "テンプレート情報",
  템플릿view: "テンプレートview",
  토: "土",
  토요일: "土曜日",
  통계: "統計",
  통계동기화: "統計の同期化",
  통계제외: "統計を除く",
  통계제외일_n일: "統計を除く日：%日",
  통합코드관리: "統合コード管理",
  통합코드그룹_상세: "統合コードグループの詳細",
  트래픽제한: "トラフィック制限",
  트리거: "トリガー",
  특정_key_설정: "特定keyの設定",
  티켓: "チケット",
  티켓_발행: "チケット発行",
  티켓_발행_테스트: "チケット発行テスト",
  티켓_발행완료: "チケット発行完了",
  티켓_연동: "チケット連動",
  티켓관리: "チケット管理",
  티켓버전_등록: "チケットバージョンを登録",
  티켓버전_수정: "チケットバージョンを修正",
  티켓사이트: "チケットサイト",
  티켓사이트_고유아이디: "チケットサイト一意のID",
  티켓사이트_관리: "チケットサイト管理",
  티켓사이트_선택: "チケットサイト選択",
  티켓사이트명: "チケットサイト名",
  파라미터: "パラメータ",
  파라미터_고정값: "パラメータ固定値",
  파라미터_노출명: "パラメータ露出名",
  파라미터_설명: "パラメータの説明",
  파라미터_설정: "パラメータ設定",
  파라미터_전송_템플릿: "パラメータの転送、テンプレート",
  파라미터명: "パラメータ名",
  파라미터유형: "パラメータタイプ",
  파일: "ファイル",
  파일업로드: "ファイルのアップロード",
  팔로우: "フォロー",
  패턴: "パターン",
  패턴_관리: "パターンの管理",
  패턴_추가: "パターンを追加",
  패턴명: "パターン名",
  패턴선택: "パターン選択",
  퍼센트_입력: "% 入力",
  퍼센트게이지: "％ゲージ",
  페이지_상단_메뉴에서_OS별_선택: "ページ上部のメニューからOS別の選択",
  평균: "平均",
  평판DB: "平板DB",
  포렌식: "フォレンジック",
  포렌식_등록: "フォレンジック登録",
  포렌식_수정: "フォレンジック修正",
  포렌식_하위목록_등록: "フォレンジック下位目録登録",
  포렌식_하위목록_수정: "フォレンジック下位目録修正",
  포렌식관리: "フォレンジック管理",
  포맷: "フォーマット",
  포트: "Port",
  포트_프로토콜: "ポート/プロトコル",
  포트번호_유지: "ポート番号を維持",
  포함: "含む",
  포함제외: "含むを除く",
  프로그램_이름: "プログラム名",
  프로그램명: "プログラム名",
  프로세스_ID: "プログラムID",
  프로토콜: "プロトコル",
  프록시_설정: "プロキシの設定",
  플랫폼: "プラットフォーム",
  플랫폼_노출명: "プラットフォーム露出名",
  플랫폼_등록: "プラットフォーム登録",
  플랫폼_매핑: "プラットフォームマッピング",
  플랫폼_수정: "プラットフォーム変更",
  플랫폼_코드: "プラットフォームコード",
  플랫폼관리: "プラットフォーム管理",
  플랫폼등록: "プラットフォーム登録",
  필수여부: "必須かどうか",
  필요시_경로_입력: "必要時にパス入力",
  필터: "フィルタ",
  필터_관리: "フィルタの管理",
  필터_등록: "フィルタ登録",
  필터_등록_필요: "フィルタ登録が必要",
  필터_매핑: "フィルタマッピング ",
  필터_비교: "フィルタ比較",
  필터_선택: "フィルタ選択",
  필터_소스: "フィルタソース",
  필터_이름: "フィルタ名",
  필터_추가: "フィルタ追加",
  필터관리: "フィルタ管理",
  필터등록: "フィルタ登録",
  필터를_검색하세요: "フィルタを検索してください。",
  필터를_선택하세요: "フィルタを選択してください。",
  필터리스트_관리: "フィルタリストの管理",
  필터명: "フィルタ名",
  필터명순: "フィルタ名順",
  필터별_로그발생: "フィルタ固有のログ発生",
  필터별_로그발생_현황: "フィルタ別ログ発生状況",
  필터별_탐지_수: "フィルタ別の検出数",
  필터별_탐지_카운트: "フィルタ別検出カウント",
  필터분류: "フィルタ分類",
  필터분류_선택: "フィルタ分類を選択",
  필터분류선택: "フィルタ分類を選択",
  필터상태: "フィルタの状態",
  필터설명: "フィルタ説明",
  필터수정: "フィルタ変更",
  필터위험도별: "フィルタ危険度別",
  필터위험도별_설정: "フィルタ危険別の設定",
  필터유형: "フィルタタイプ",
  필터유형별: "フィルタタイプ別",
  필터키: "フィルタキー",
  필터키입력: "フィルタキー入力",
  필터키OR매핑코드: "フィルタキーorマッピングコード",
  필터탐지: "フィルタ検出",
  하단다음4라인주석해제: "下部の次4ラインのコメントを解除",
  하단에서_수정: "下部で修正",
  하위: "下位",
  하위_필터: "下位フィルタ",
  하위대상: "下位対象",
  하위요소: "サブ要素",
  한개의_필터_등록: "1つのフィルタ登録",
  합계: "合計",
  항목_설명: "項目/説明",
  항목_추가: "項目追加",
  항목추가: "項目の追加",
  해시: "ハッシュ",
  해제: "解除",
  해지: "解約",
  해킹그룹: "グループ",
  해킹그룹_등록: "グループ登録",
  해킹그룹_매핑: "グループマッピング",
  해킹그룹_수정: "グループ変更",
  해킹그룹관리: "グループ管理",
  해킹그룹등록: "グループ登録",
  해킹그룹명: "グループ名",
  해킹그룹탐지: "グループ検出",
  해킹그룹ID: "グループID",
  행동유형: "タイプ",
  허용: "許可",
  허용IP주소: "許可IPアドレス",
  현재: "現在",
  현재_권한: "現在の権限",
  현재_비밀번호: "現在のパスワード",
  현재_PIN번호_입력: "現在のPIN番号を入力",
  호스트: "ホスト",
  호스트_사용: "ホスト使用",
  호스트_이름: "ホスト 名",
  호스트_종료: "ホスト終了",
  호스트명: "ホスト名",
  호스트명_입력: "ホスト名を入力",
  호스트보안: "EDR",
  호스트보안_단일필터: "EDR 単一フィルター",
  호스트보안_단일필터_등록: "EDR 単一フィルター登録",
  호스트보안_단일필터_수정: "EDR 単一フィルター修正",
  호스트보안_단일필터추가: "EDR 単一フィルター追加",
  호스트보안_방어필터: "EDR 防御フィルター",
  호스트보안_수정: "EDR修正",
  호스트보안_업로드: "ホストセキュリティアップロード",
  호스트보안로그_분석: "ホストセキュリティログ分析",
  호스트보안로그_수집: "ホストセキュリティログ収集",
  호스트보안로그_업로드: "EDRログアップロード",
  호스트보안로그_업로드_수: "EDRログアップロード数",
  호스트보안필터_추가: "<strong>EDRフィルタ</strong>追加",
  홈페이지_위변조: "ホームページ偽造・変造",
  홈페이지_위변조_보안탐지: "ホームページ偽造・変造セキュリティ検出",
  홈페이지_위변조_보안필터_수정: "ホームページ偽造・変造セキュリティフィルタの変更",
  홈페이지_위변조_탐지: "ホームページ偽造・変造検出",
  화: "火",
  화면_설정: "画面の設定",
  화요일: "火曜日",
  확_인: "確 認",
  확률: "確率",
  확인: "確認",
  확인하기: "確認する",
  확인했습니다: "確認しました。",
  환경_설정: "環境設定",
  회: "回",
  회사: "会社",
  회사명: "会社名",
  회사명_입력: "会社名を入力",
  회사별_관리: "会社別の管理",
  회사별관리: "会社別管理",
  회사별관리수정: "会社別の管理修正",
  회사소개: "会社紹介",
  회사이름: "会社名",
  회원_관리: "会員管理",
  회원가입: "会員登録",
  회원고유키: "会員固有のキー",
  회원관리: "会員管理",
  횟수: "回数",
  횟수입력: "回数を入力",
  휴대폰: "携帯電話",
  휴대폰번호: "携帯電話番号",
  휴대폰번호_입력: "携帯電話番号を入力",
  히스토리: "履歴",
  히트맵: "ヒートマップ",
  히트맵_정보: "ヒートマップ情報",
  힙사용률: "ヒープ使用率",
  abc순: "ABC順",
  Amazon_Linux_AMI_2017_2018_Linux_2_AMI_지원: "Amazon Linux AMI 2017/2018, Linux 2 AMI サポート",
  API_버전: "APIバージョン",
  API_인증키: "API認証キー",
  Audit_로그패키지개별설치: "Auditログパッケージ個別のインストール",
  Audit_rule_관리: "Audit rule管理",
  Audit_rule_등록: "Audit rule登録",
  Audit_rule_수정: "Audit rule修正",
  AUDIT_RULE명: "AUDIT RULE名",
  Auditrule관리: "Auditrule管理",
  Byte_부터: "Byteから",
  CentOS_7_Stream_8_지원: "CentOS 7, Stream 8 サポート",
  Command_로그_수집_중지: "Commandログ収集を停止",
  Config_위치_확인: "Config位置確認",
  DATA_위치: "DATA位置",
  Data_name_불러오기: "Data nameインポート",
  dataname: "Data name",
  Dataname_관리: "Data name管理",
  Dataname등록: "Data name登録",
  Dataname수정: "Data name修正",
  DB_매핑코드: "DBマッピングコード",
  Default적용: "Default適用",
  DoS: "DoS",
  Event_Log_IIS포함: "Event Log (IISを含む)",
  Event_TYPE: "<strong>Event</strong>TYPE",
  GUID: "GUID",
  HASH_전_로그보기: "HASH全ログの表示",
  ID관리: "ID管理",
  ID등록: "ID登録",
  ID수: "ID数",
  ID수정: "ID変更",
  IP검사: "IPチェック",
  IP주소: "IPアドレス",
  IP주소_등록: "IPアドレスの登録",
  IP주소_사용자태그: "IPアドレスユーザタグ",
  IP주소_입력: "IPアドレスを入力",
  IP주소_추출: "IPアドレス抽出",
  IP주소_추출로그: "IPアドレス抽出ログ",
  IP주소_태그: "IPアドレスタグ",
  IP주소그룹: "IPアドレスグループ",
  IP주소추가: "IPアドレス追加",
  ipv6는_지원하지않습니다: "IPv6はサポートしていません。",
  KEY: "KEY",
  Linux_Syslog탭: "Linux > Syslogタブ",
  Login_분석: "Login分析",
  Login_분포: "Login分布",
  Login_성공_실패: "Login成功/失敗",
  Login_추출로그: "Login抽出ログ",
  MAC주소: "MACアドレス",
  MAC주소_사용자태그: "MACアドレスユーザタグ",
  Method별_로그발생: "メソッド別のログ発生",
  MIME_타입: "MIMEタイプ",
  ML_예외설정_추가: "MLの例外設定を追加",
  ML분석: "ML分析",
  ML예외_설정: "ML例外設定",
  ML예외n건: "ML例外%件",
  ML탐지: "ML検出",
  msg정렬_등록: "msg整列登録",
  msg정렬_수정: "msg整列修正",
  msg정렬관리: "msg整列管理",
  MSS관리: "MSS管理",
  mysql_conf_다운로드_rsyslog_사용: "mysql confダウンロードrsyslogを使用",
  mysql_error_위치_확인: "mysql error位置確認",
  n_공격가능확률: "%攻撃可能確率",
  n_데몬_재시작: "%デーモンの再起動",
  n_사용시_설치필요: "%使用時にインストールが必要",
  n_수정: "% 修正",
  n_이상_지원: "% 異常サポート",
  n_이상일때_설치필요: "%以上の時にインストールが必要",
  n_지원: "%支援",
  n가_등록됩니다: "%が以下のように登録されます。",
  n개: "%個",
  n개_등록제한: "%個まで登録可能です。",
  n개_모두_선택: "%個すべてを選択",
  n건: "%件",
  n건_등록: "%件 登録",
  n년: "%年",
  n명: "%人",
  n분_내: "%分以内",
  n분_전: "%分前",
  n시: "%時",
  n시간_내: "%時間以内",
  n시간_전: "%時間前",
  n월: "%月",
  n이상: "% 以上",
  n일: "%日",
  n일_뒤: "%日 後",
  n일_전: "%日前",
  n일_n회_가능: "%日%回可能",
  n주: "%周",
  NO: "NO",
  Ocean_대상IP주소관리: "Ocean:対象IPアドレス管理",
  OFF: "OFF",
  ON: "ON",
  ON_설정_시_서비스_노출되며_탐지_시작: "ON設定時のサービスに露出され、検出開始",
  ON_설정_시_탐지노출: "ON設定時検出露出",
  ON_설정시_로그인이_제한됩니다: "ONに設定すると、ログインが制限されます。",
  OS: "OS",
  OS_채널: "OS/チャンネル",
  OS별_로그발생_현황: "OS別のログ発生状況",
  OS선택: "OS選択",
  OTP메일: "OTPメール",
  OTP메일_번호_입력: "OTPメール番号を入力",
  OWASP: "OWASP",
  Path: "Path",
  PATH: "PATH",
  PATH를_입력해주세요: "PATHを入力してください。",
  PATTERN: "PATTERN",
  Permission: "Permission",
  PERMISSION: "PERMISSION",
  PERMISSION을_입력해주세요: "PERMISSIONを入力してください。",
  PIN번호: "PIN番号",
  PIN번호_생성_규칙_관리: "PIN番号の作成のルール管理",
  PIN번호_설정: "PIN番号の設定",
  PIN번호_설정_완료: "PIN番号が設定されました。",
  PIN번호_입력: "PIN番号を入力",
  PIN번호_확인: "PIN番号の確認",
  PIN번호_OFF_설정: "PIN番号OFF設定",
  PLURA_해킹_대응_서비스시작: "PLURAハッキング対応サービスが開始されました。",
  PLURA_Agent_UI에서_설정된_것_확인: "PLURA Agent UIで設定されたを確認",
  Port_입력: "Port入力",
  PostgreSQL및Syslog재시작: "PostgreSQL、およびSyslog再起動",
  Powershell_관리: "Powershell 管理",
  Powershell_등록: "Powershell 登録",
  Powershell_rule: "Powershell rule",
  POWERSHELL_RULE명을_입력해주세요: "POWERSHELL RULE名を入力してください。",
  Powershellrule명: "Powershell rule 名",
  PRIORITY: "PRIORITY",
  Priority_관리: "Priority管理",
  Priority_등록: "Priority登録",
  Priority_수정: "Priority修正",
  PRIORITY명: "PRIORITY名",
  QnA: "Q&A",
  QnA_관리: "Q&A 管理",
  QnA_등록: "Q＆A登録",
  QnA_상세: "Q&A 詳細",
  QnA_수정: "Q＆A修正",
  Redhat_7_8_지원: "Redhat 7, 8 サポート",
  SQUID_결과_값: "SQUID結果値",
  status별: "status別",
  status별_로그발생: "Status別のログ発生",
  Status별_로그발생_현황: "Status別のログ発生状況",
  status별_설정: "status別の設定",
  Syslog_재시작: "Syslog再起動",
  Syslog_전송_설정: "Syslog送信設定",
  Syslog_포맷: "Syslogフォーマット",
  SyslogAudit_로그패키지통합설치: "Syslog+Auditログパッケージ統合インストール",
  Sysmon_다운로드: "Sysmonダウンロード",
  Sysmon_설정: "Sysmon設定",
  Ubuntu_16_04_18_04_20_04_지원: "Ubuntu 16.04, 18.04, 20.04 サポート",
  URL_스캔: "URLスキャン",
  URL_추출로그: "URL抽出ログ",
  URL경로: "URL(path)",
  WAF_모듈_설치: "WAFモジュールをインストール",
  Windows_Server_2012_이상: "Windows Server 2016 以上",
  XML_값: "XML値",
};
export default languageJa;
