const languageEn = {
  _1단계: "Step 1",
  _1차_구분자_필드: "1st separator (field)",
  _2단계: "Step 2",
  _2차_구분자_토큰: "2nd Separator (token)",
  _2차_인증: "2nd authentication",
  _2차_인증_OTP메일: "2nd authentication: Email",
  _2차_인증_PIN번호: "2nd authentication: PIN",
  _2차_인증OTP메일: "2nd Auth<br/>(OTP Mail)",
  _2차_인증PIN번호: "2nd Auth<br/>(PIN)",
  _2차_플랫폼: "2nd Platform",
  _2차_플랫폼_노출명: "2nd Platform display name",
  _2차_플랫폼_코드: "2nd Platform code",
  _3번_패키지_설치_포함됨: "#3 Package installation included.",
  _24시간: "24Hours",
  _78_mysql_conf_수정: "Edit 78-mysql.conf",
  가능: "Possible",
  가변: "variable",
  가입_변경일자: "Subscription / change date",
  가입대기: "Processing subscription",
  가입멤버: "Member",
  가입여부: "Status",
  가입완료: "Signed up",
  가입인증이_완료되었습니다: "Sign-up is complete.",
  가입인증처리: "Subscription authentication processing",
  가입인증처리_하시겠습니까: "Process registration verification?",
  가입일: "Subscription date",
  가입일자: "Join Date",
  간단_설명: "Explanation",
  간략설명: "Brief description",
  개발: "Development",
  개별: "Single",
  개인_대시보드: "Personal dashboard",
  개인_대시보드_수정: "Modify Personal dashboard",
  개인알림: "Notification",
  개인정보_관련없는_번호로_변경:
    "Please change it to a number that is not related to personal information such as mobile phone, social number, date of birth.",
  개인정보_마스킹: "Data Masking",
  개인정보_마스킹_설정: "Data masking settings",
  개인정보_수정: "Edit personal information",
  개인정보_수집_이용: "Privacy Policy",
  개인정보_숨김_설정: "Personal privacy settings",
  개인정보_처리방침: "Privacy statement",
  개인정보숨김: "Privacy",
  개인정보숨김_설정: "Privacy settings",
  개인정보숨김설정_추가: "Add personal privacy settings",
  개인정보취급방침: "Privacy Statement",
  개행_안함: "No newline",
  개행_타입: "Newline character",
  갯수설정: "Count settings",
  건당_평균_크기: "Average size",
  검사자등록: "Ticket",
  검색: "Search",
  검색_개인화: "Search personalization",
  검색_쿼리_방식: "Search query",
  검색_키워드: "Search keyword",
  검색_키워드_상세: "Search keyword details",
  검색결과: "Result",
  검색대상: "Search target",
  검색제공메뉴: "Search menu",
  검색제공메뉴가_없습니다: "There is no search offer menu..",
  검색추가: "Add search",
  검색항목_관리: "Search",
  검색항목관리: "Search type",
  격리: "Isolation",
  격리_해제: "Disable Isolation",
  결과문서_구조: "Result document structure",
  결과문서_선택: "Result document select",
  경고: "Warning",
  경로: "Path",
  경로_등록: "Registration path",
  경로_예시: "Example path",
  계정_탈취_공격: "Account acquisition attack",
  계정_탈취_공격_방어: "Defense account acquisition attack",
  계정_탈취_공격_보안탐지: "Account Acquisition Attack Security Detection",
  계정생성: "Account creation",
  계정탈취: "Account acquisition",
  계정탈취_보고서: "Account Acquisition report",
  계정탈취_보안탐지: "Account Acquisition Security Detection",
  계정탈취_보안필터_등록: "Account Acquisition Security filter registration",
  계정탈취_보안필터_수정: "Account Acquisition Security filter modification",
  계정탈취_수정: "Edit Account acquisition",
  계정탈취_예외: "Exception account takeover",
  계정탈취공격: "Account acquisition attack",
  계정탈취공격_보안필터_수정: "Edit account security filter",
  고_정: "Fixing",
  고객지원: "Customer Support",
  고객지원_결과: "Resut customer support",
  고객지원_담당자: "Customer support manager",
  고객지원_요청_사유: "Reason for Requesting Customer Support",
  고객지원_요청하기: "Request customer support",
  고객지원_이용약관: "Terms and Conditions",
  고객지원_진행: "Customer support progress",
  고객지원_회사: "Customer support company",
  고객지원요청: "Request customer support",
  고객지원요청_관리: "Manage customer support requests",
  고객지원요청_상세: "Customer support requests details",
  고객지원요청관리: "Manage customer support requests",
  고유아이디: "UniqueID",
  고유NO: "Unique NO",
  고정: "fixing",
  공격: "Attacks",
  공격목적: "Attack purpose",
  공격목적코드: "Attack object code",
  공격분포: "Attack distribution",
  공격수: "Attack number",
  공격유형: "Attack type",
  공격유형_분포: "Attack type distribution",
  공격유형_분포_요일별: "Attack type distribution (by day)",
  공격유형_분포_주별: "Attack type distribution (weekly)",
  공격유형_선택: "Select attack type",
  공격유형매핑: "Attack type mapping",
  공격자: "Attacker",
  공격코드: "Attack code",
  공격패턴: "Attack pattern",
  공격확률: "Attack probability",
  공격확률별_로그발생_현황: "Log generation status by attack probability",
  공격확률별_설정: "Set by attack probability",
  공격확률별_탐지_현황: "Detection status by attack probability",
  공격확률별_현황: "Status by attack probability",
  공격횟수: "Number of attacks",
  공격IP주소: "Attack IP Addr",
  공지: "Notice",
  공지보기: "View",
  공지사항: "Notice",
  관련_높은: "High related",
  관련그룹: "Related Group",
  관련소프트웨어: "Related Software",
  관리: "Management",
  관리_무료: "Management (Recomand free)",
  관리자_설정: "Set manager",
  구분: "Classification",
  국가: "Country",
  국가_설정: "Country",
  국가_언어_설정: "Country/Language settings",
  국가_지역: "Country (Region)",
  국가별_공격_IP주소_현황: "Status of attacked IP addr by Country",
  국가별_접속_상위_IP주소_TOP20: "Top 20 IP Addresses by Country",
  권한: "Authority",
  권한_선택: "Select permission",
  권한변경: "Change permissions",
  권한변경하기: "Change permissions",
  그_외: "ETC",
  그래프: "Graph",
  그래프를_제공하지_않습니다: "Does not provide graphs.",
  그룹: "Group",
  그룹_등록: "New Group",
  그룹_선택: "Select Group",
  그룹_설정: "Group",
  그룹_수정: "Edit Group",
  그룹_추가: "Add the Group",
  그룹고유키: "Group unique key",
  그룹관리: "Group management",
  그룹내_기본값_사용: "Use default value",
  그룹명: "Name",
  그룹별: "Group",
  그룹별_웹훅: "Web hook by group",
  그룹별_Syslog: "By group Syslog",
  그룹선택: "SELECT",
  그룹설정: "Group Setting",
  그룹알림: "Group notification",
  금: "Fri",
  금요일: "Friday",
  금월: "This Month",
  금일: "Today",
  금일_비밀번호_변경: "Password has been changed.",
  금주: "This Week",
  기간설정: "Set date range",
  기본: "Default",
  기본_실행: "Default Run",
  기본정보: "Default info",
  기술: "Techniques",
  기타: "etc",
  기타_정보: "Other info",
  나의_정보: "My information",
  나중_변경: "Change later",
  날짜: "Date",
  날짜형: "Date",
  남은_기간: "Remaining day",
  남은기간: "Remaining period",
  남음: "left",
  낮음: "Low",
  내부_IP: "Internal IP Addr",
  내용: "Contents",
  네트워크: "Network",
  네트워크_격리: "Network isolation",
  네트워크_격리_해제: "Disable Network Isolation",
  네트워크_단일필터: "Network a Filte",
  네트워크_단일필터_등록: "Registration",
  네트워크_단일필터_수정: "Edit",
  네트워크_등록: "Network registration",
  네트워크_로그: "Network log",
  네트워크_수정: "Edit Network",
  네트워크_업로드: "Network Upload",
  네트워크_전체로그: "Network full log",
  네트워크_필터탐지: "Network filter detection",
  네트워크_호스트명: "Network hostname",
  네트워크로그: "Network log",
  네트워크로그_수집: "Network log collection",
  네트워크로그_수집_경로: "Network log collection path",
  네트워크로그_업로드: "Network log upload",
  네트워크로그_업로드_수: "Number of uploads",
  네트워크IP: "Network IP addr",
  년: "Year",
  년도별: "by year",
  노드: "Node",
  노드구분: "Node classification",
  노드상태: "Node status",
  노출: "Exposure",
  노출_문구: "Exposure phrase",
  노출_컬럼을_관리합니다: "Manage data exposure columns other than the default column.",
  노출명: "Display name",
  노출설정: "Exposure settings",
  노출순서: "Exposure sequence",
  노출여부: "Exposure",
  높음: "High",
  닉네임: "Alias",
  님: "",
  님_회원정보: " member information",
  다운로드: "Download",
  다운로드_한_파일의_경로에서_cmd_명령창_실행:
    "Run the cmd command from the path of the downloaded file.",
  다음: "Next",
  다크_모드: "Dark mode",
  단_epel_release_사전_설치_필요: "However, epel-release requires pre-installation.",
  단위: "unit",
  단위변환: "Unit Conversion",
  단일: "Singular",
  단일필터: "Single filter",
  단일필터_네트워크_분류: "Single filter (network) classification",
  단일필터_등록: "Registration single filter",
  단일필터_수정: "Eidt single filter",
  단일필터_시스템_분류: "Single filter (host)",
  닫기: "Close",
  담당자: "Manager",
  담당자_선택: "Select manager",
  답변: "Replay",
  답변_메일알림_받기: "Send notifications using email.",
  답변대기: "Answer waiting",
  답변완료: "Answer completed",
  당일: "Day",
  대기: "Waiting",
  대량등록: "Bulk registration",
  대상: "Target",
  대상_서버에_FilePlura_설치: "Install FilePlura on the target server",
  대상_서버에서_설정: "Setting on target server",
  대상_시스템: "Target host",
  대상_장비에서_설정: "Setting on the target device",
  대상_주소: "Target addr",
  대상_포트_이름: "Target port name",
  대상_호스트_이름: "Target hostname",
  대상건: "Target",
  대상for서버: "Target",
  대상IP주소: "Target IP addr",
  대상IP주소_등록: "Target IP address registration",
  대시보드: "Dashboard",
  대응: "Response",
  대표: "Main",
  대표_웹훅: "Choose Webhook",
  대표_Syslog: "Main Syslog",
  대표번호: "Representative number",
  대표자: "Master",
  더_자세한_정보: "More information",
  더보기: "More",
  덤프: "Dump",
  덤프_수집: "Dump Collection",
  데이터_값: "Data value",
  데이터_소스: "Data source",
  데이터_입력: "Enter Data",
  데이터_저장기간: "Data storage period",
  데이터_타입: "Data type",
  데이터루트: "Data root",
  데이터베이스_비교: "Database comparison",
  데이터소스: "Data source",
  데이터유출: "Data breach",
  데이터유출_방어: "Data breach protection",
  데이터유출_보안탐지: "Data breach security detection",
  데이터유출_보안필터: "Data breach security filter",
  데이터유출_보안필터_등록: "Data breach security filter registration",
  데이터유출_보안필터_수정: "Edit Data breach security filter",
  데이터유출_수정: "Edit Data breach",
  데이터유출필터_분류: "Data breach flter classification",
  데이터유형: "Data type",
  데이터유형_선택: "Select data type",
  도구: "Tool",
  도메인: "Domain",
  도메인_등록: "Domain registration",
  도메인_수정: "Domain modification",
  도메인_코드: "Domain code",
  도메인관리: "Domain management",
  도메인등록: "Domain registration",
  도메인명: "Domain name",
  도움말: "Help",
  도움말_바로가기: "Help shortcut",
  돌아가기: "Back",
  동기화: "synchronization",
  동기화_현황: "Synchronization status",
  동기화관리: "Synchronization management",
  동기화단위: "Synchronization unit",
  동기화테이블: "Synchronization table",
  동기화현황: "Synchronization status",
  동기화DATA: "Synchronization DATA",
  동시접속: "Simultaneous access",
  동영상_가이드: "Installation video guide",
  동의: "Agree",
  동의안함: "Don't agree",
  동일: "Equal",
  동일_숫자_3자리_이상: "3 or more digits of the same number",
  동일로그: "Hash",
  동일요소수: "Same number of elements",
  동작: "Action",
  동작시간: "Operating time",
  동작시간_GMT09: "Start time (GMT+09)",
  동적필터: "Dynamic filter",
  동적필터_코드_선택: "Select dynamic filter code",
  동적필터등록: "Dynamic filter registration",
  동적필터수정: "Edit dynamic filters",
  두개_이상_필터_등록_가능: "More than 2 filters(or)",
  등록: "Registration",
  등록_가능한_정보입력: "Registerable information input",
  등록_가능한_호스트_개수: "A total of % hosts that can be registered.",
  등록_가능한_n: "% items that can be registered",
  등록_불가능한_n: "% items that cannot be registered",
  등록_서버: "Registration server",
  등록_시스템: "Total Host",
  등록_패턴: "Registration pattern",
  등록_확인_후_가능: "Available after registration",
  등록게시물수: "Registered posts",
  등록되었습니다: "Registered.",
  등록된_목록: "Registered list",
  등록된_서버: "Servers",
  등록된_시스템: "Registered host",
  등록된_시스템_확인: "Check Registered Hosts",
  등록된_항목수: "Number of items registered",
  등록된_data_name: "In use data name",
  등록된_data_name_리스트: "Registered dataname",
  등록된_IP주소: "Registered IP addr",
  등록된_IP주소_수: "Number of registered IP addr",
  등록된IP주소그룹: "Registered IP addr group",
  등록된IP주소수: "Number of registered IP addr",
  등록방법: "Registration",
  등록서버많은순: "Order by number of servers",
  등록서비스: "Registration",
  등록순: "Registration order",
  등록업체: "Registered Customer",
  등록업체_바로가기: "Shortcuts",
  등록일: "Registration date",
  등록자: "Registrant",
  등록자_등록일: "Registrant/registration date",
  등록필터: "Filter",
  등록필터수: "Number of registered filters",
  등록하기: "Registration",
  등록한_퍼센트이상부터_서비스_노출: "Exposure of service from registered %",
  디스크로드률: "Disk load rate",
  디스크사용률: "Disk utilization",
  디스크용량: "Disk capacity",
  디코딩: "Decoding",
  디폴트: "Default",
  디폴트_메뉴_설정: "Default menu settings",
  디폴트_탭설정: "Default Tap setting",
  라우트: "Route",
  라이선스: "License",
  라이선스_기간: "License term",
  라이선스_등록: "License registration",
  라이선스_등록_및_실행: "Register and run licenses",
  라이선스_변경_신청: "Application for License Change",
  라이선스_키_입력: "Enter license key",
  라이선스키: "License key",
  라이트_모드: "Light mode",
  라인_그래프: "Line graph",
  라인_추가: "Add line",
  라인을_주석처리: "Comment out the line",
  랜덤_발생: "Random occurrence",
  레드마인_고유NO: "Redmine unique NO",
  레벨: "Level",
  레퍼러: "Referer",
  로고: "Logo",
  로그: "Log",
  로그_기능: "Log function",
  로그_바로가기: "Shortcuts",
  로그_심각도: "Severity",
  로그_업로드_설정: "Set up log uploads",
  로그_업로드_합계: "Total log uploads",
  로그_취합_서버_Datos_설치_포트미러링_페이지_Linux_Datos_탭:
    "After installing Datos on the log collector, configure port mirroring → Linux > Datos tab in the menu at the top of the page",
  로그_취합_서버에_포트미러링_구성: "Configure port mirroring on the log collector server",
  로그_취합서버에_로그콜렉터_설치: "Install log collector on target server",
  로그_취합서버에_에이전트_설치: "Installing agents",
  로그_취합서버에서_인바운드_TCP_5514_포트_오픈:
    "Open inbound TCP 5514 port from log collection server",
  로그구분: "Log classification",
  로그발생_현황: "Log generation status",
  로그별_업로드_분포: "Upload distribution by log",
  로그보기: "View log",
  로그상세: "Log details",
  로그설정: "Log settings",
  로그수집경로입력: "Enter log collection path",
  로그아웃: "Logout",
  로그업로드: "Log upload",
  로그업로드_분포: "Log upload distribution",
  로그업로드량: "Log upload",
  로그유형: "Log type",
  로그유형그룹: "Log type group",
  로그인: "Login",
  로그인_메일: "Login email",
  로그인_비밀번호_입력: "Enter login password",
  로그인_속성: "Login properties",
  로그인_제한: "Login Restriction",
  로그인_히스토리: "Login history",
  로그인메일: "Login email",
  로그인허용_IP주소_추가: "Add login IP addr",
  로그인허용IP주소: "Login allowed IP addr",
  로그인ID: "Login ID",
  로그저장크기: "Log storage size",
  로그조회_리스트_컬럼에_정보_노출함: "Information is displayed in the log search list column.",
  로그취합상태: "Log collection status",
  로그취합서버IP: "Log collection server IP addr",
  로그콜렉터_삭제: "Delete log collector",
  로그콜렉터_재실행: "Restart log collector",
  로그포렌식_설치환경_안내_윈도우10_넷프레임워크45:
    "Can be installed on Windows 10 or .NET Framework 4.5 or later",
  로그포렌식_지원시스템_종류: "Log upload support system : Windows, Linux, Android",
  루틴_프로시저_함수: "Routine (procedure, function)",
  룰_입력: "Enter Rule",
  를_선택합니다: "choose, please.",
  리눅스_명령어_command_설치_rsyslog_사용: "Installing Linux commands (using rsyslog)",
  리더: "leader",
  리셀러: "Reseller",
  리셀러_등록: "Register as a reseller",
  리소스: "Resource",
  리소스_등록: "Registration resource",
  리소스_수정: "Edit resource",
  리소스_수집: "Resource collection",
  리소스_현황: "Resource status",
  리소스관리: "Resource Management",
  리소스를_등록하세요: "Register resources.",
  리소스명: "Resource name",
  리소스모니터링: "Resource monitoring",
  리소스모니터링_메타정의: "% > % > meta definition",
  리소스코드: "Resource code",
  리스트: "List",
  리스트_컬럼_노출_설정: "List column display setting",
  링크: "Link",
  마이크로초: "msec",
  마이터: "MITRE",
  마이터_어택: "MITRE ATT&CK",
  마이터_어택_탐지: "MTIRE ATT&CK detection",
  마이터_어택_탐지_분포: "MITRE ATT&CK Detection Distribution",
  마이터_통계_동기화: "MITRE statistics synchronization",
  마이터관리: "MITRE management",
  마지막: "Last",
  마케팅_메일: "Marketing email",
  만료상태: "Expired",
  만료예정: "Expiration dates",
  만료일: "Expiry date",
  매뉴얼: "Manual",
  매칭_키워드: "Matching keywords",
  매트릭스: "Matrices",
  매트릭스_이동: "Go to MATRIX",
  매트릭스보기: "Matricesview",
  매핑: "Mapping",
  매핑_컴플라이언스: "Mapping Compliance",
  매핑소프트웨어: "Mapping Software",
  매핑수: "Mapping count",
  매핑안함: "No mapping",
  매핑전술: "Mapping Tactics",
  매핑정보_관리: "mapping",
  매핑정보_등록: "Registration mapping",
  매핑주소: "Mapping address",
  매핑주소관리: "Mapping address management",
  매핑코드: "Mapping code",
  매핑코드_컬럼: "(searchColumn)",
  매핑플랫폼: "Mapping Platform",
  매핑필터: "Mapping filter",
  매핑필터_리스트: "Mapping filter list",
  매핑해킹그룹: "Mapping Group",
  머신러닝의_패턴분석결과: "Analysis results of machine learning",
  멀티등록_반점구분: "Multi registration, classification",
  메뉴_노출_설정: "Menu exposure settings",
  메모리사용률: "Memory utilization",
  메모리용량: "Memory capacity",
  메세지: "Message",
  메세지_2차구분자입력하세요: "Please enter the 2nd separator.",
  메세지_가입완료후비밀번호변경요청해라:
    "Please request password change after completing subscription.",
  메세지_개발팀문의: "Please contact the administrator.",
  메세지_개인대시보드노출필터설정: "Set filters to expose to personal dashboards.",
  메세지_개인정보처리방침에동의한다: "I agree the privacy policy.",
  메세지_검색결과n건있다: `Search result <span class="c-black bold">%</span>.`,
  메세지_검색키워드등록시안내: "Can not be modified at the time of search keyword registration.",
  메세지_검색키워드리스트없귀: "There are no registered search keywords.",
  메세지_검색타입선택: "Please select a search type.",
  메세지_경로입력: "Please enter the Path",
  메세지_계정탈취필터등록후사용가능:
    "Please use it after registering the account acquisition filter.",
  메세지_계정탈취필터등록후확인가능: "You can check after registering the filter.",
  메세지_고객지원로그인시히스토리관리에정보가노출된다:
    "Information is exposed in the history management at the time of customer support login.",
  메세지_고객지원요청완료: "Customer support was requested.",
  메세지_고객지원용아이디를통한접속정보는DB에저장한다:
    "Access information via ID for customer support is stored in Database.",
  메세지_공격유형을_선택하세요: "Please select an attack type.",
  메세지_공격IP주소가block되방어실행됨방어할거냐:
    "The attacking IP addr is added to the IP addr block and the defense is executed.\nDo you want to defend?",
  메세지_관리자가_확인_후_답변_등록_예정입니다: "PLURA administrator will answer.",
  메세지_관리자만수정가능하다: "Only the administrator can modify.",
  메세지_관리자에게문의후로그인시도: "Please contact your administrator and try logging in again.",
  메세지_그룹삭제입력정보모두삭제: "Confirm on delete?\nAll information will be deleted.",
  메세지_그룹을생성한멤버는해당그룹의소속으로자동설정됩니다:
    "Members who create a group are automatically set as belonging to the group.",
  메세지_그룹을조회할멤버를선택하세요: "Select a member.",
  메세지_그룹을추가해야함: "Need to add the Group",
  메세지_그룹이등록되었습니다: "Completed.",
  메세지_내부DNS아닌경우host에서지정한다: `If the address configured in the web server is not an internal DNS address, specify <span className="c-red">internal-web-address</span> in the hosts file.`,
  메세지_내용을_입력해주세요: "Please enter the contents.",
  메세지_네트워크_격리: "Proceed with network isolation.",
  메세지_네트워크_격리해제: "Disable network isolation.",
  메세지_다양한테마적용나만의프루라만드세요: "Create your own PLURA by applying various themes.",
  메세지_다운로드_로그수_최대n개_입력: "Please enter the number of logs to be downloaded(max %).",
  메세지_다운로드_수_입력:
    "Please enter % counts the number of logs to download below 2,000. Now, entered %.",
  메세지_다운로드실패: "Download failed.",
  메세지_다운로드하시겠습니까: "Would you like to download?",
  메세지_담당자를_선택해_주세요: "Please select a contact person.",
  메세지_답변글을_등록해_주세요: "Please register your answer.",
  메세지_대상을선택해주세요: "Please select a target.",
  메세지_대시보드로이동: "The changed information is not saved and you are taken to the dashboard.",
  메세지_데이터값입력: "Please enter data value.",
  메세지_데이터루트를선택하세요: "Please select a data route.",
  메세지_동기화실패: "Synchronization failed.",
  메세지_동기화완료: "Synchronization is complete.",
  메세지_동기화요청: "Synchronization request.",
  메세지_동기화요청실패: "Synchronization request failed.",
  메세지_동기화진행취소불가: "Cancellation is not possible while synchronization is in progress.",
  메세지_동기화진행하쉴: "Would you like to proceed with synchronization?",
  메세지_동기화필수: "Synchronization required in order!!!",
  메세지_동기화하시겠습니까: "Do you want to proceed with synchronization?",
  메세지_동일한검색어가있습니다: "The same search keyword exists.",
  메세지_등록가능한필터수초과: "All of the available filters have been registered.",
  메세지_등록되었습니다_클릭시적용:
    "Registered.\n Applied when you click the OK button at the bottom of the path pop-up.",
  메세지_등록된_채널이_없습니다: "No channels registered.",
  메세지_등록된항목삭제불가항목삭제후다시시도:
    "There is a registered item and can not be deleted. Please delete item and try again.",
  메세지_등록된항목이없습니다: "No items found.",
  메세지_등록된IP만로그인가능하다: "Only registered IP addr can login.",
  메세지_등록실패: "Registration failed.",
  메세지_등록완료: "Registered.",
  메세지_등록하쉴: "Would you like to register?",
  메세지_등록할목록그룹명을입력하라: "Enter the list group name to register.",
  메세지_등록할목록명: "Please enter a list name to register.",
  메세지_등록할태그명을입력하세요: "Enter the tag name to register.",
  메세지_등록할호스트명을입력하세요: "Enter the host to register.",
  메세지_디폴트설정하쉴이미설정된디폴트취소:
    "Would you like to set it as default?\nThe default already set is canceled.",
  메세지_디폴트OFF: "Defaut settings OFF.",
  메세지_디폴트ON: "Default ON",
  메세지_라이센스확인: "Please check the license.",
  메세지_로그인10회실패: "Login is limited to 10 failures.",
  메세지_로그인성공실패선택: "Please select the success or failure of the login status.",
  메세지_로그인속성입력: "Please enter the Login name attribute.",
  메세지_로그인실패제한:
    "% Service use is limited by % due to % failures.\nPlease try again later.",
  메세지_로그인실패제한_영구:
    "% Service use is limited due to % failures.\nPlease contact the Master.",
  메세지_로그인정보불일치: "No users or information mismatch.",
  메세지_로그인해주세요: "Please login.",
  메세지_로그취합안하쉴: "Are you sure you want to stop collecting the logs?",
  메세지_로그취합하쉴: "Would you like to collect the logs?",
  메세지_리소스_등록_후_사용가능합니다: "Use it after registering the resource.",
  메세지_리스트등록최종등록:
    "Registration completed. Click the confirm button at the top right to finalize.",
  메세지_리스트삭제최종삭제:
    "Displayed for deletion from the list. Click the OK button on the upper right, it will be deleted.",
  메세지_리스트없음: "No results were found for your search.",
  메세지_리스트에서_삭제: "Removed from list.",
  메세지_매일설정한시간에만필터동작: "The filter works only at the time set every day.",
  메세지_매핑된항목없음: "No mapped items.",
  메세지_매핑코드입력: "Please enter the mapping code.",
  메세지_매핑필터중복안됨: "Mapping filters can not be duplicated.",
  메세지_메뉴선택: "Please select menu.",
  메세지_메일을발송했다: "Sent an email.",
  메세지_멤버로그인시비밀번호변경필요: "Password change required for login.",
  메세지_멤버에포함되지않는고객지원용아이디로로그인한다:
    "Sign in with a customer support ID that does not belong to a member.",
  메세지_모드프루라업데이트: "Update message ModPlura.",
  메세지_모드프루라업데이트하쉴: "Do you want to update ModPlura?",
  메세지_모든IP로그인가능하다: "Log in from any IP addr.",
  메세지_문의하실_내용을_입력해_주세요: "Please enter your inquiry!",
  메세지_미적용하시겠습니까: "Do not want to use?",
  메세지_발급된임시비밀번호는24시간이내만료된다:
    "Issued temporary passwords will expire within 24 hours.",
  메세지_방어대상필터없음: `No defense target filter.\nIt is displayed when you register a filter that requires defense, and can be set.`,
  메세지_방어실행된다: "The defense is executed.",
  메세지_방화벽스위치IDSIPS등네트워크장비의SYSLOG를취합:
    "Provides functions for collecting syslogs of network devices such as firewalls, switches, IDS, and IPS.",
  메세지_변경되었습니다_클릭시적용:
    "Changed.\n Applied when you click the OK button at the bottom of the path pop-up.",
  메세지_변경된내용이없습니다: "Nothing has changed.",
  메세지_변경된내용이있습니다_적용: "Changes have been made.\n Are you sure you want to apply?",
  메세지_변경된내용이있습니다_취소: "Changes have been made.\n Are you sure you want to cancel?",
  메세지_변경사항적용하쉴: "Apply changes?",
  메세지_변경한비밀번호로다시로그인해라: "Please log in with the changed password.",
  메세지_변경한정보저징확인클릭: `(Click the "OK" button to save your changes.)`,
  메세지_변경할_을_선택하세요: "Please select % to change.",
  메세지_변경할IP주소그룹명을입력하라: "Enter the IP addr group name to be changed.",
  메세지_보안_n개_수정완료: "% hosts became %.",
  메세지_복사되었다: "Copied.",
  메세지_복사하려는os와동일하다: "This is the same OS that you want to copy.",
  메세지_복사하시겠습니까: "Would you like to copy it?",
  메세지_복사할os선택하세요: "Select the OS to copy.",
  메세지_복원사용기간입력: "Enter the restoration period. (up to 20 days)",
  메세지_복원승인대기중: "Pending restore request approval.",
  메세지_복원시스템_삭제_1: "Are you sure to delete the selected restore host?",
  메세지_복원시스템_삭제_2: "Deleting also deletes the restored logs.",
  메세지_복원시스템_삭제_3:
    "Depending on the amount of logs, this may take several minutes to several hours.",
  메세지_복원요청: "A restoration request is required.",
  메세지_복원요청승인_승인일_시작일로_노출:
    "Would you like to approve the restoration?\nThe approval date will be displayed as the start date.",
  메세지_복원진행: "Proceed with restoration.",
  메세지_복원진행시간소요:
    "Depending on the amount of logs, this may take several minutes to several hours.",
  메세지_복원진행해제_해당회사_복원설정_불가능:
    "Do you want to cancel restoration?\nRestoration of the company will be impossible.",
  메세지_복원취소시_복원된_로그포함삭제:
    "Are you sure to cancel the restoration in progress?\nIf you cancel, the restored log will also be deleted.",
  메세지_분류를_선택하세요: "Please select a category.",
  메세지_분류index입력해주세요: "Please enter a classification Index.",
  메세지_사용자정의설정확인:
    "There is no registered service information, or the custom setting is OFF.",
  메세지_사용할_PIN번호를_입력해주세요: "Please enter PIN.",
  메세지_사용할컴플라이언스선택매핑정보확인:
    "Select the compliance you want to use to see the mapped information.",
  메세지_사용ID수값확인: "The number of IDs used can be input from 1 to 99,999.",
  메세지_사용ID수를입력: "Please enter the number of IDs used.",
  메세지_삭제되었습니다_클릭시적용:
    "Deleted.\n Applied when you click the OK button at the bottom of the path pop-up.",
  메세지_삭제된시스템은Default그룹으로이동된다: "The deleted host is moved to the Default group.",
  메세지_삭제불가: "There is a registered post that can not be deleted.",
  메세지_삭제시_하위대상도삭제:
    "Do you want to delete?\n(If there are sub-targets, the sub-targets will also be deleted.)",
  메세지_삭제실패: "Delete failed.",
  메세지_삭제완료: "Deleted.",
  메세지_삭제하쉴: "Are you sure you want to delete?",
  메세지_삭제확인:
    "The delete command is executed.\n ON can not be changed after executing delete command.\nOFF, do you want to process?",
  메세지_삭제확인_등록게시물:
    "Are you sure you want to delete?\nYou must have no registered posts to be deleted.",
  메세지_상관필터그룹공격확률입력:
    "Correlation Filter Only the attack probability of the first group should be set to zero.",
  메세지_상태값입력: "Please enter the Login status.",
  메세지_상태값입력값확인: "Login status can be entered between 100 and 599.",
  메세지_새롭게생성된그룹탐지방법: "A newly created group can be detected only by adding a filter.",
  메세지_서비스명을_선택하세요: "Select a service name.",
  메세지_서비스타입미선택: "No service type information.",
  메세지_선택한테마적용하시겠습니까: "Would you like to apply the selected theme?",
  메세지_설정체크: "Please check the settings.",
  메세지_설정필터중시간별설정: "Set the hourly detection status among the set filters.",
  메세지_설정필터최신순확인: "When the filter set in the message occurs, check the latest order.",
  메세지_설정필터탐지카운트확인: "Check the detection count of the filter among the set.",
  메세지_설정하기필터추가필터정보가능:
    "Add filter through setting, check the information of added filter.",
  메세지_세션종료시간을_변경하지_않았습니다: "You have not changed the session end time.",
  메세지_속성값선택: "Please select attribute value.",
  메세지_속성선택: "Please select a property.",
  메세지_수정실패: "Modification failed.",
  메세지_수정완료: "Changed.",
  메세지_수정이전데이터복사합니다:
    "Copy the data before modification. If you need to copy the modified data, copy it after completing the modification.",
  메세지_수정하쉴: "Do you want to edit?",
  메세지_수집가능한대상이없습니다: "There is no target available for collection.\nPlease check.",
  메세지_수집대상을선택하세요: "Please select the target to be collected.",
  메세지_수집대상정보를입력해주세요: "Please enter the information of the target to be collected.",
  메세지_수집완료는새로고침으로확인해주세요: "Please refresh to confirm collection completion.",
  메세지_수집하시겠습니까: "Would you like to collect?",
  메세지_승인_후_복원설정_가능: "Restoration is possible after approval.",
  메세지_시간선택: "Please enter time.",
  메세지_시간설정다시: "Start time and end time are the same. Please reset.",
  메세지_시간을선택하세요: "Please select a time.",
  메세지_시간을설정: "Please set the time.",
  메세지_시도횟수값확인: "The number of attempts can be entered from 1 to 9,999.",
  메세지_시도횟수를입력: "Please enter the number of attempts.",
  메세지_시스템그룹명_입력하세요: "Please enter host group name",
  메세지_시스템그룹선택: "Choose the Group",
  메세지_시스템그룹을_선택: "Please select a group.",
  메세지_시스템로그_n개_수정완료: "% hosts have been modified.",
  메세지_시스템목록갱신중: "Retrieving host list.\nPlease wait.",
  메세지_시작합니다: "% Start.",
  메세지_실행완료: "Executed",
  메세지_아이디로사용하는이메일은수정불가능하다: "ID(email) can not be edited.",
  메세지_업데이트실패실패시재시도:
    "* Updates can fail depending on server and network status. Please try again in case of failure.",
  메세지_업데이트필요모드프루라전체업데이트하쉴:
    "* list of ModPlura that needs updating. Please check the box below for a full update.",
  메세지_업데이트확인: "You can check the progress on the update status page.",
  메세지_완료되었습니다: "Completed.",
  메세지_요소가등록되어있어야합니다: "Element must be registered.",
  메세지_요소값입력해주세요: "Please enter the element value.",
  메세지_요소선택: "Please select an element.",
  메세지_요소수선택: "Please select the number of elements.",
  메세지_요일선택해주세요: "Choose the day of the week.",
  메세지_요청후7일이내고객지원이루어지고7일이후엔종료된다:
    "Customer support will be provided within 7 days of the request and will end after 7 days.",
  메세지_운영체제ProgramName1차구분자입력하세요:
    "Enter the operating host, ProgramName and primary separator.",
  메세지_원본로그포함여부경고:
    "When the original log is included, the ticket may not be normally issued due to network environment.",
  메세지_원하는곳에마우스우측버튼으로붙여넣기해라: "Paste with the right mouse button.",
  메세지_웹로그수집시작한다: "Start collecting web logs.",
  메세지_웹로그수집중지한다: "Stop collecting web logs.",
  메세지_웹모듈을_삭제합니다: "Delete web module.",
  메세지_웹방화벽로그전송설정: "Web Firewall Log Transfer Settings (For PLURA operator)",
  메세지_웹방화벽설정할수있음: "You can set exceptions for WAF.",
  메세지_웹탐지예외설정예시: `예) 000 <span class="c-red">XXXXXXXXXXX</span>, <span>000</span>`,
  메세지_위항목은등록후수정이불가합니다: "The above items cannot be modified after registration.",
  메세지_위험도별설정체크: "Check the settings by risk level.",
  메세지_윈도우는지원하지않음: "Sorry, Windows is not supported.",
  메세지_유료서비스를이용해더강력한해킹서비스와혜택받아보세요: ` <span class="c-blue bold bold p-r-5">Standard, Gold, Platinum, Premium</span>. Get more powerful hacking detection services.`,
  메세지_유형중복: "Already registered.",
  메세지_응답크기입력: "Please enter the Response size.",
  메세지_응답크기입력값확인:
    "The minimum value of Response size is greater than the maximum value.",
  메세지_응답크기최댓값확인: "The maximum response size is less than 9,999,999.",
  메세지_이동됐다: "Moved.",
  메세지_이미_등록된_컬렉션명입니다: "The name of the collection already registered.",
  메세지_이미_적용된_공격유형입니다: "The type of attack already applied.",
  메세지_이미등록된목록명: "List names already registered.",
  메세지_이미등록된이벤트타입입니다: "Event type already registered.",
  메세지_이미등록된코드: "It is already registered code.",
  메세지_이미업데이트: "An update is already in progress.",
  메세지_이벤트타입미선택: "Event type information not found.",
  메세지_이용약관동의하면고객지원요청된다:
    "Please agree to the Terms and Conditions and customer support will begin.",
  메세지_이용약관에동의한다: "I agree the Terms and Conditions.",
  메세지_이후고객지원필요시재요청필요하다: "Customer support will be required later if necessary.",
  메세지_인증URL클릭하면회원가입완료된다:
    "Click on the URL link in the email to complete the membership.",
  메세지_입력된정보를확인하세요: "Please check the information entered.",
  메세지_입력하신이메일로인증메일발송되며인증되야회원가입완료이다:
    "A verification email will be sent to the email you entered and you will be registered as a member when the verification is completed.",
  메세지_자동입력방지안내: `If you enter the password <span class="c-white">incorrectly more than % times, </span></span><span class="dis-inline-b f-12 c-gray-light">you need to log in after checking CAPTCHA.</span>`,
  메세지_자동입력방지확인: "Please check the password and CAPTCHA.",
  메세지_잠금처리되었습니다수정해재등록해주시기바랍니다:
    "Locked by operator. Please correct it and re-register.",
  메세지_잠김해제하쉴: "Are you sure you want to unlock %?",
  메세지_재시도버튼클릭: "Please click the retry button.",
  메세지_재정렬되었습니다: "Reordered.",
  메세지_재정렬후_저장_가능: "Can be saved after reordering.",
  메세지_저장되었습니다: "Saved.",
  메세지_저장하쉴: "Do you want to save",
  메세지_적용하시겠습니까: "Do you want to apply?",
  메세지_전화번호를_입력해주세요: "Please enter the number without -.",
  메세지_접속하기:
    "Upon login, the logged in account will be logged out.\nWould you like to continue?",
  메세지_정렬대상서비스를선택하세요: "Select a service to sort by.",
  메세지_정보삭제후진행가능합니다: "Delete information before proceeding.",
  메세지_제목을_입력해주세요: "Please enter the title.",
  메세지_중복된목록명: "The name of the list you are already using.",
  메세지_지원시작: "A connection account is created.\nDo you want to start?",
  메세지_지원종료: "Connection account is disabled.\nDo you want to quit?",
  메세지_진행상황_복원히스토리페이지: "Please check the progress in [Restore list].",
  메세지_차단IP삭제: "Upon deletion, the block is unblocked.\nConfirm",
  메세지_채널단일설정: "(Event type selection area hidden processing when registering filter)",
  메세지_채널을_선택하세요: "Please select a channel.",
  메세지_초기화가능한대상이없습니다:
    "There is no target available for initialization.\nPlease check.",
  메세지_초기화대상을선택해주세요: "Please select the target to initialize.",
  메세지_초기화하시겠습니까: "Would you like to initialize?",
  메세지_초대하였습니다: "Invited.",
  메세지_초대할사람이메일입력하라: "Please enter an email to invite.",
  메세지_최대30개까지등록된다: "Up to 30 can be registered.",
  메세지_최대초입력가능: "Maximum % can be entered.",
  메세지_최대n개IP주소등록: `<span class="c-red">Can register up to % IP addresses.</span>`,
  메세지_최신버전: "The latest version.",
  메세지_추가됐다: "Added.",
  메세지_추가할_필터분류를_선택하세요: "Please select a filter class to add.",
  메세지_추가할멤버의정보입력해라: "Please enter the information for the member you want to add.",
  메세지_취소되었습니다: "Canceled.",
  메세지_컴플라이언스삭제하쉴: "Are you sure you want to delete the selected compliance?",
  메세지_코드입력: "Please enter the code.",
  메세지_키워드별상세설정안내: "You can make detailed settings for each keyword.",
  메세지_탐지_우선순위_적용을_해지하쉴: "Are you sure you want to deactivate detection priority?",
  메세지_탐지노출입력: "0 can not be entered.",
  메세지_탐지시간입력: "The detection time should be between 1 second and % seconds.",
  메세지_탐지우선순위설정취소:
    "The modification value is not saved and changes to the priority list screen. \n Are you sure you want to cancel?",
  메세지_테마가적용되었습니다: "Theme applied.",
  메세지_테마선택하세요: "Choose a theme.",
  메세지_테마저장하지않고이동됩니다: "The selected theme is moved without being saved.",
  메세지_특정필터탐지카운트정보비교:
    "Compare the number of times a specific filter is detected a day ago.",
  메세지_특정Key를입력하세요: "Set specific Key > Please enter the Key.",
  메세지_특정key분류index를선택하세요: "Set specific Key > Please select a classification Index.",
  메세지_특정key분류index와key확인하세요:
    "Set specific Key > Please check the classification Index and Key.\n([Classification Index & Key] in which [Index & Key] is the same as a pair cannot exist.",
  메세지_특정KeyValueIndex를입력하세요: "Set specific Key >Please enter the Value Index.",
  메세지_티켓발행시원본로그포함: "Include original log when issuing ticket",
  메세지_티켓사이트삭제:
    "If the customer is already using the ticket, the ticketing information will disappear and the ticket will be reissued.\nAnd The linked version and parameter information will also be deleted.Are you sure you want to delete?",
  메세지_티켓이발행됐다: "Tickets have been issued.",
  메세지_티켓OFF하쉴:
    "When set to OFF, the ticket issuing information will disappear and the ticket will be reissued.\nOFF would you like to continue?",
  메세지_파일업로드실패: "File upload failed.",
  메세지_파일업로드완료: "File upload complete.",
  메세지_포렌식_초기화_경고:
    "(All information will be deleted and the current information will be updated to the previous information.)",
  메세지_핀번호를_입력하세요: "Enter PIN",
  메세지_필터레벨선택: "Please select a filter level.",
  메세지_필터명선택: "Please select a filter.",
  메세지_필터명입력: "Please enter the Filter.",
  메세지_필터명입력필요: "Please enter filter name.",
  메세지_필터상태OFF하쉴: "Do you want to turn off the status of the selected filter?",
  메세지_필터상태ON하쉴:
    "Do you want to turn on the status of the selected filter? \n When ON, the filter is activated and the security filter detects it.",
  메세지_필터상태ON하시겠습니까: "Do you want to turn on the status of the selected filter?",
  메세지_필터중복: "Already registered.",
  메세지_하단특정key설정추가하세요: "Please add a specific Key setting at the bottom.",
  메세지_항목선택: "Please select an item.",
  메세지_항목체크: "Check the item.",
  메세지_해지되었습니다: "Canceled.",
  메세지_허용IP주소를입력해주세요: "Please enter a permitted IP address.",
  메세지_호스트_종료: "Shut down the host.",
  메세지_호스트입력: "Please enter the Host.",
  메세지_호스트정보도도시적용됩니다: "Host information is also applied at the same time.",
  메세지_호스트종료후_재시작수동: "After shutting down the host, a restart must be done manually.",
  메세지_확률n퍼센트입니다: "Probability is %.",
  메세지_확인후다시시도해주세요: "Please check and try again.",
  메세지_회원가입시등록한이메일정보를입력하시면해당이메일로임시비밀번호발송한다:
    "If you enter your registered email information when you register, you will receive a temporary password via email.",
  메세지_회원가입완료되었다: "Sign up is complete.",
  메세지_회원가입하신이메일로인증URL발송했다: "You have been sent by email.",
  메세지_Audit활성화를위한안내: "Select 1 or 2 below to activate Audit",
  메세지_burst를입력해주세요: "Please enter Burst.",
  메세지_CapsLock활성: "CapsLock is on.",
  메세지_CNAME을입력해주세요: "Please enter CNAME.",
  메세지_DataName치환: "Enter the target and value to replace.",
  메세지_Host를입력: "Please enter the Host.",
  메세지_HostPath선택:
    "When selecting Host or Path, you can check the statistics for each time period.",
  메세지_index는0부터시작합니다: "Index starts at 0.",
  메세지_IP주소가_아래와_같이_등록됩니다: "The IP address will be registered as follows.",
  메세지_IP주소등록완료시차단: "Blocking occurs when IP address registration is complete.",
  메세지_IP주소를입력해주세요: "Please enter IP address.",
  메세지_IP중복: "It is already registered IP address.",
  메세지_listenPort입력해주세요: "Please enter Listen Port.",
  메세지_ML탐지예외설정할수있다: "ML detection Exceptions can be set",
  메세지_n개선택했습니다: "% selected.",
  메세지_n개의대상만수집가능합니다: "Only % targets can be collected.\nPlease check.",
  메세지_n님안녕하세요: "% welcome!",
  메세지_n으로권한을변경합니다: "Change permissions to %.",
  메세지_n표항목은필수입니다: "% items marked are required.",
  메세지_NOOS: "Please select OS.",
  메세지_OFF설정시매핑된정보OFF처리하쉴:
    "When set to OFF, the mapping information will disappear. OFF would you like to continue?",
  메세지_ON설정사용자가이드: "(When set ON, user guide input, filter state OFF)",
  메세지_ON설정서비스노출: "(When set ON, service exposure)",
  메세지_ON설정시서비스노출ON처리하쉴:
    "When set to ON, the service is exposed. ON would you like to continue?",
  메세지_ON설정시잠금이되며서비스에필터비활성화처리:
    "(When set to ON, it will be locked and the service will be deactivated.)",
  메세지_ON설정탐지노출: "When ON, detection notification",
  메세지_ON설정필터적용: "(Apply filter when ON)",
  메세지_ON설정필터ON상태: "(When set ON, filter ON state)",
  메세지_ON하쉴: "ON would you like to continue?",
  메세지_OS를_선택하세요: "Select OS.",
  메세지_OTP메일번호가발송되었습니다: "Email has been sent.",
  메세지_Passphrase값확인: "Passphrase range from 1 to 32 digits.",
  메세지_PLURA_관리자가_내용확인_후_답변_드릴_예정입니다: "PLURA administrator will answer.",
  메세지_PLURA제공_보안필터전용: "Exclusively for security filters provided by PLURA",
  메세지_Port입력해주세요: "Please enter Port.",
  메세지_rate를입력해주세요: "Please enter Rate.",
  메세지_SSL_CRT값확인: "Please enter at least 1000 characters",
  메세지_SSL_Key값확인: "Please enter at least 1000 characters",
  메세지_Timestamp유형은한개만등록가능합니다: "Only one Timestamp type can be registered.",
  메세지_Windows2008자동설치미지원:
    "To stop Windows Server 2008 IIS (Internet Information Server) automatically installation",
  메소드: "Method",
  메시지_경로를등록해야업로드가능합니다: "You must register the path to upload.",
  메시지_변경내용적용: "Changes have been made.\n Are you sure you want to apply?",
  메시지_변경내용취소: "Changes have been made.\n Are you sure you want to cancel?",
  메시지_삭제완료10초이상소요: "It may take 10 seconds or more to complete the deletion.",
  메시지_서버등록후경로입력가능: "Path can be entered after server registration",
  메시지_자식서버가등록되었습니다: "Child server is registered.",
  메시지_자식시스템_n개_동시삭제: `<span class="c-red">Simultaneous deletion of % child hosts registered to the selected parent host(s)</span>`,
  메시지_IP차단을위해이유를적으세요:
    "Do you want to block the IP address? \nThat's the reason why?",
  메인: "Main",
  메일발송내용: "Contents of sending email",
  메일보내기: "Send email",
  메타_정의: "Meta definition",
  메타_정의_후_사용가능합니다: "First, define the meta.",
  메타서비스: "Meta Service",
  메타서비스관리: "Meta management",
  멤버: "Member",
  멤버_수: "Number of Members",
  멤버_이름_입력: "Enter member name",
  멤버관리: "Member Management",
  멤버수: "Number of members",
  멤버초대: "Invite a member",
  멤버초대_초대현황: "Member invitation & status",
  멤버추가: "Add member",
  명령어: "command",
  명령어_선택: "Select a command",
  명령어_입력: "Enter command",
  명령어_입력_실행: "Execute command",
  명령어_히스토리: "Command history",
  모드프루라_등록: "modPlura register",
  모드프루라_버전관리: "modPlura version management",
  모드프루라_수정: "Edit modPlura",
  모드프루라_업데이트: "ModPlura Update",
  모드프루라_전체_업데이트: "ModPlura full update",
  모드프루라_종류: "modPlura version",
  모드프루라_종류_버전: "ModPlura (Type / Version)",
  모든그룹: "ALL",
  모든서버그룹: "All server groups",
  모든서버그룹선택: "Select all server groups",
  목: "Thu",
  목록: "List",
  목록_등록: "Enter list",
  목록그룹_등록: "List group registration",
  목록그룹명_변경: "List group name change",
  목록등록: "List registration",
  목록명: "List",
  목록명_변경: "Change list name",
  목요일: "Thursday",
  무료: "Free",
  무료_체험기간: "Free (trial period)",
  무료체험기간: "Free trial period",
  무료평가판시작: "Start free trial",
  묶음: "Bundle",
  문의하기: "Contact us",
  문자형: "Character",
  미동작: "Not working",
  미등록: "Unregistered",
  미등록된_주소: "Unregistered address",
  미리보기: "Preview",
  미사용: "Unused",
  미수집: "Not collected",
  미업로드: "Unuploaded",
  미인증: "Unauthorized",
  미저장: "Unsaved",
  미적용: "Unapplied",
  미제공: "Not provided",
  미제한: "Unlimited",
  미취합: "Not collected",
  미확인_IP: "Unidentified IP addr",
  바로가기: "link",
  바이패스: "Bypass",
  박스플롯: "Box plot",
  발급일: "Issue Date",
  발급자: "Issuer",
  발급필요: "Issue Required",
  발급하기: "Issue",
  발급하기_버튼을_클릭하시면_생성: "Click the button to create a temporary password.",
  발생: "Occur",
  발생_횟수: "Number of occurrences",
  발생일: "Date of occurrence",
  발행: "Issuance",
  발행일: "Published date",
  발행자: "Publisher",
  발행티켓: "Issue Ticket",
  방금: "Just now",
  방어: "Defense",
  방어_명령어_입력: "Enter defense command",
  방어관리: "Defense management",
  방어구분: "Defense category",
  방어대상: "Defensive target",
  방어로그: "Defense log",
  방어상태: "Defense status",
  방어실행: "Execute defense",
  방어원인: "Cause of defense",
  방어IP주소: "Defensive IP addr",
  방화벽: "Firewall",
  방화벽_유형: "Result",
  방화벽설정: "Firewall settings",
  백업: "Backup",
  백업루트: "Backup route",
  버전: "Version",
  버전_선택: "Select version",
  버전_출력_예시: "Example version output",
  버전_확인_API: "Version check API",
  버전_n_다운로드_링크: "Download version link",
  버전에_따른_재시작_예시:
    "In the example above, an example of restart depending on the confirmed version",
  버전에버전확인: "Depending on the version, the service name varies so check the version",
  변경: "Change",
  변경_시점: "On the next change",
  변경할_권한: "Authority to change",
  변수_설명: "Variable Description",
  변환: "Run",
  별칭: "Alias",
  보고서: "Report",
  보고서_네트워크: "Report_Network",
  보고서_메일: "Report email",
  보고서_박스플롯: "Report_Boxplot",
  보고서_분류: "Category",
  보고서_분류별_탐지_현황: "Detection status by report classification",
  보고서_상관분석: "Report_Correlation_analysis",
  보고서_시스템: "Report_Host",
  보고서_알림: "Report notification",
  보고서_웹: "Report_Web",
  보고서_히트맵: "Report_Heatmap",
  보고서_IP주소: "Report_IPaddress",
  보고서메일: "Report email",
  보기: "Viewing",
  보안: "Security",
  보안_유료: "Security (charged)",
  보안탐지: "Security detection",
  보안필터: "Security filter",
  보안필터_등록: "Register security filter",
  보안필터_수정: "Edit security filter",
  보안필터수정: "Edit security filter",
  복구: "Restore",
  복사: "Copy",
  복사_위치: "Target",
  복수: "Plural",
  복원: "Restore",
  복원기간: "Period",
  복원대상: "Restoration target",
  복원목적: "Purpose",
  복원서비스: "Restore service",
  복원서비스_사용기간: "Restoration period",
  복원설정: "Restore settings",
  복원설정요청: "Restore request",
  복원설정요청관리: "Restoration setup management",
  복원시간: "Time",
  복원시스템: "Restore Host",
  복원시스템관리: "Restore host management",
  복원시작: "Start restoration",
  복원이용약관_1: "Restoration is possible only during the requested period.",
  복원이용약관_2: "Permit only 10 restorations per day(based on completed restoration).",
  복원이용약관_3: "Restored logs can be viewed for 30 days after restoration.",
  복원이용약관_4: "Restored logs is not restored again while it is available for viewing.",
  복원히스토리: "Restore list",
  본문: "Text",
  볼륨_메트릭: "Volume metrics",
  부모_시스템: "Parent(P) host",
  부분삭제: "Partial deletion",
  부서: "Department",
  부서_입력: "Enter department",
  분: "Minute",
  분류: "Classification",
  분류_관리: "Classification",
  분류_선택: "Select category",
  분류_index: "Classification Index",
  분류_index_관리: "Classification Index management",
  분류관리: "Classification",
  분류별_탐지_분포: "Distribution of detection by classification",
  분류별_탐지_현황: "Status category detection",
  분류수정: "Edit category",
  분석: "Analysis",
  분석_변경_히스토리: "Analysis change list",
  분석정보: "Analysis info",
  분석정보_영역에_정보를_노출함: "Information is exposed in the analysis information area.",
  분포: "Distribution",
  불가능: "Impossible",
  불러오기: "loading",
  불일치: "Inconsistency",
  뷰: "View",
  비교: "Comparison",
  비교_테이블_생성: "Create comparison table",
  비교_테이블_생성_실패: "Failed to create",
  비교_테이블_생성_완료: "Completion of creating",
  비교_테이블_생성합니다: "Create a comparison table.",
  비교_필터_리스트: "Comparison filter list",
  비밀번호: "Password",
  비밀번호_변경: "Edit password",
  비밀번호_변경_시점_n일_초과:
    'It has been <span class="c-red">%days</span> since you changed your password.',
  비밀번호_변경_안내: "Password change information",
  비밀번호_변경_요청: "Please change your password periodically.",
  비밀번호_변경_후_로그아웃: "After changing the password, the old account will be logged out.",
  비밀번호_변경_후_서비스_이용: "Change your password periodically to use the service.",
  비밀번호_변경을_권고: "Please change your password.",
  비밀번호_변경하기: "Changing password",
  비밀번호_생성_규칙_관리: "Password generation rule management",
  비밀번호_설정_완료: "Password has been set.",
  비밀번호_입력: "Enter password",
  비밀번호_재입력: "Retype password",
  비밀번호_찾기: "Forgot password",
  비밀번호_확인: "Confirm password",
  비밀번호를_변경해주세요: "Please change your password.",
  빌드: "build number",
  사용: "Use",
  사용_컴플라이언스: "Usage Compliance",
  사용된_변수: "Used variable",
  사용로그: "Usage log",
  사용불가: "Unavailable",
  사용설정: "Enable",
  사용여부: "Whether or not to use",
  사용자: "User",
  사용자_가이드: "User guide",
  사용자_값_선택입력: "User-selected input",
  사용자_설정: "User settings",
  사용자_에이전트: "User-Agent",
  사용자_정보: "User info",
  사용자_정의: "Custom",
  사용자_환경에_따라_아래_패키지가_필요:
    "Depending on your environment, additional packages are required.",
  사용자가이드: "User guide",
  사용자설정: "User settings",
  사용자에이전트: "UA",
  사용자정의: "Custom",
  사용자필터: "User filters",
  사용자필터수정: "Edit user filters",
  사용중: "In use",
  사용하시는_장비에서_설정: "Settings on your device",
  사용ID수: "Count ID",
  사이: "Between",
  사전_n_설치_필요: "Pre-install required",
  삭제: "Delete",
  삭제가_불가능합니다_등록업체문제:
    "Deletion is not possible.\nPlease try after deleting the registered customer.",
  삭제가_불가능합니다_리소스문제: "Unable to delete.\n Please try after deleting the resource.",
  삭제가_불가능합니다_메타문제: "Unable to delete.\n Please try after deleting the meta.",
  삭제가_불가능합니다_적용업체문제:
    "Unable to delete.\nPlease try after deleting the registration.",
  삭제된_정보입니다: "Deleted information.",
  삭제명령어: "Delete instruction",
  삭제시스템: "Delete host",
  삭제취소: "Undelete",
  삭제하기: "Deleting",
  상관_분석_방어: "Correlation analysis defense",
  상관보안필터시작: "Start correlation security filter",
  상관분석: "Correlation analysis",
  상관분석_방어필터: "Correlation analysis defense filter",
  상관분석_보고서: "Correlation analysis report",
  상관분석_보안탐지: "Correlation Analysis Security Detection",
  상관분석_보안필터: "Correlation analysis security filter",
  상관분석_보안필터_등록: "Add correlation analysis filter",
  상관분석_보안필터_수정: "Edit correlation analysis filter",
  상관분석_수정: "Edit Correlation analysis",
  상관필터_분류: "Correlation filter classification",
  상관필터_시작: "Start correlation filter",
  상관필터_Group_추가: "Add correlation filter group",
  상단_영역_노출: "Top area exposure",
  상세: "details",
  상세_설정: "Detailed settings",
  상태: "Status",
  상태_코드: "Status",
  상태값: "Status",
  새_비밀번호: "New password",
  새_비밀번호_확인: "Confirm new password",
  새_PIN번호_숫자_6자리_입력: "Enter a new PIN of 6 digits",
  새_PIN번호_숫자_6자리_재입력: "Re-enter a new PIN of 6 digits",
  새로운_키워드명: "New keyword",
  새창: "New window",
  새창보기: "New window",
  생성: "Matching",
  생성_제한_공통_규칙: "Restriction common rule",
  생성업체: "Company",
  생성일: "Creation date",
  생성하기: "Create",
  샤드관리: "Shard Management",
  서버: "Server",
  서버_그룹: "Server group",
  서버_그룹_등록: "Server group registration",
  서버_등록_필요: "Server registration required",
  서버_총_n개: "Total % of servers",
  서버관리: "Server management",
  서버그룹: "Server group",
  서버그룹_등록: "Register server group",
  서버그룹_선택: "Select server group",
  서버그룹_설정: "Server group setting",
  서버그룹_수정: "Edit  server group",
  서버그룹_전체: "All server group",
  서버그룹관리: "Manage server group",
  서버그룹명: "Server group name",
  서버그룹선택: "Select server group",
  서버등록: "Server registration",
  서버리스트: "Server list",
  서버목록_선택: "Select server list",
  서버수: "Number of servers",
  서버수정: "Edit server",
  서버정보: "Server info",
  서버추가: "Adding server",
  서버IP주소: "Server IP addr",
  서버IP주소_입력: "Enter server IP addr",
  서비스: "Service",
  서비스_등록: "Registration service",
  서비스_수정: "Edit service",
  서비스_실행: "Run service",
  서비스_이용_재로그인_필요: "Relogin is required to use the service.",
  서비스_전환_신청하기: "Applying for service conversion",
  서비스관리: "Service management",
  서비스그룹별: "Group",
  서비스그룹별_웹훅: "Web hook by service group",
  서비스그룹별_Syslog: "By service group Syslog",
  서비스대상_필터키: "Service destination / filter key",
  서비스대상_필터키_입력: "Enter service subject / filter key",
  서비스등록: "Registration service",
  서비스리젼: "Service region",
  서비스명: "Service name",
  서비스명_확인_경로_예시: "Example of service name verification path",
  서비스별: "By service",
  서비스별_공격_IP주소_현황: "Status of attack IP addr by service",
  서비스별_로그발생: "Log_Generation_By_Service",
  서비스별_상세_확인하기: "Check the details by service",
  서비스별_탐지_분포: "Detection distribution by Service",
  서비스별_탐지_양: "Detections by Service",
  서비스별_탐지발생_현황: "Detection status by service",
  서비스종류: "Service type",
  서비스코드: "Service code",
  선별: "Selection",
  선별_예외_설정: "Selection, Exception settings",
  선택: "Select",
  선택_값_선택: "Selection value selection",
  선택_취소: "Deselect",
  선택국가: "Selected country",
  선택삭제: "Delete selected",
  선택입력: "Optional input",
  선택입력_자동정렬: "Selective input (automatic sort)",
  선택필터: "Selection filter",
  선택한_n개: "% Selected",
  설명: "Explanation",
  설명_호스트명: "Explanation(hostname)",
  설명입력: "Enter description",
  설정: "Config",
  설정_값: "Value",
  설정_필요한_필터: "Setting required filter",
  설정검사: "Configuration check",
  설정된_필터리스트: "Set filterlist",
  설정하기: "Set up",
  설치: "Installation",
  설치_매뉴얼: "Installation manual",
  설치_안내: "Installation instructions",
  설치순서를_따라_진행합니다: "Follow the installation sequence.",
  설치시_궁금한_내용은_고객센터로_연락: `If you have any questions about installation, please contact Customer Support Center(<span class="c-red">support@qubitsec.com, +82-70-8802-0306</span>)`,
  설치시스템: "Install host",
  설치할_에이전트의_운영체제를_선택합니다:
    "Select the operating system (OS) of the agent to be installed.",
  성공: "Success",
  성공건: "Success",
  세션: "Session",
  세션관리: "Session management",
  세션종료_시간: "Session end time",
  세션코드: "Session code",
  소속그룹: "Affiliated Group",
  소속멤버: "member",
  소스_디비_최신: "Source DB (latest)",
  소스디비_값: "Source DB value",
  소스디비_최신: "Source DB (latest)",
  소스보기: "View source",
  소스SQL: "Source SQL",
  소프트웨어: "Software",
  소프트웨어_등록: "Software registration",
  소프트웨어_매핑: "Software mapping",
  소프트웨어_수정: "Software modification",
  소프트웨어관리: "Software management",
  소프트웨어등록: "Software registration",
  소프트웨어명: "Software",
  소프트웨어ID: "SoftwareID",
  속성: "Property",
  속성_값_선택: "Select attribute value",
  속성_선택: "Select attribute",
  속성값_복수_입력시_사용: "Used for multiple value input",
  솔라시스템관리: "Management for Solr",
  수: "Wed",
  수_정: "Modified",
  수동: "Manual",
  수동차단: "Manual blocking",
  수요일: "Wednesday",
  수정: "Edit",
  수정_예시: "Example modification",
  수정일: "Modified date",
  수정자: "Modifier",
  수정자_수정일: "Modifier/Modified Date",
  수정하기: "Modify",
  수정할_번호_입력: "Enter number to edit",
  수정할_이름_입력: "Enter name to edit",
  수정할_회사명_입력: "Enter company name to edit",
  수집: "Collection",
  수집_서버: "Collection server",
  수치: "Figure",
  순서: "Order",
  순차_발생: "Sequential occurrence",
  숫자_6자리: "6 digits",
  숫자_연속_6자리_이상: "6 consecutive digits or more",
  숫자_영문_특문_조합_9자리:
    "Numbers (1 ~ 0), upper and lower case letters (a ~ z, A ~ Z), special characters (!, @, #, $, %, etc.) or 9 more combinations",
  숫자_영어대소문자_특수문자_9자리_이상:
    "Please register at least 9 digits, a combination of numbers, uppercase and lowercase letters, and special characters.",
  숫자형: "Numeric",
  스코어: "Score",
  스크립트: "Script",
  스키마_비교: "Schema comparison",
  스토리지: "Storage",
  스토리지_등록: "Register storage",
  스토리지_수정: "Modify storage",
  스토리지관리: "Storage management",
  스토리지매핑_등록: "Register storage mapping",
  스토리지매핑_수정: "Edit storage mapping",
  스토리지명: "Storage name",
  스토리지사용정보: "Storage usage information",
  스토리지타입: "Storage type",
  스토리지항목: "Storage item",
  스토리지항목_등록: "Storage item registration",
  스토리지항목관리: "Storage item management",
  스토리지항목주소: "Storage item address",
  승인: "Approval",
  승인자: "Approver",
  시: "Hours",
  시간: "Date",
  시간_설정: "Time setting",
  시간대: "Slot",
  시간대별_Login_분석: "Login analysis by time slot",
  시간별_로그발생: "Log_Generation_By_Hour",
  시간별_로그발생_현황: "Status of Log Generation by Hour",
  시간별_로그업로드_분포: "Log-up distribution by hour",
  시간별_로그업로드_현황: "Log upload status (by hourly)",
  시간별_총_탐지발생_현황: "Detection status per hour",
  시간별_탐지_현황_그래프: "Hourly Detection Status Graph",
  시간별_탐지로그발생: "Detection logs by hour",
  시간별_탐지로그발생_현황: "Detection logs by hour",
  시간선택: "Select time",
  시간설정: "Time setting",
  시도: "Try",
  시도응답인증: "Challenge–response authentication",
  시도횟수: "Number of attempts",
  시스로그: "Syslog",
  시스로그_업로드: "Syslog upload",
  시스로그_업로드_합계: "Syslog upload total",
  시스로그미업로드시스템: "Total Unuploaded Host",
  시스템: "Host",
  시스템_단일필터: "Host a Filter",
  시스템_단일필터_등록: "Registration",
  시스템_단일필터_수정: "Edit",
  시스템_단일필터추가: "Add host single filter",
  시스템_등록: "Add",
  시스템_등록_필요: "Host registration required",
  시스템_로그_취합은_자동_지원: "Automated hostlog collection",
  시스템_방어필터: "Host defense filter",
  시스템_수정: "Edit",
  시스템_시스템관리_호스트선택_호스트등록:
    "System > Management > Choosing Target Host > Registration",
  시스템_업로드: "Host upload",
  시스템_웹: "Host/Web",
  시스템_입력: "Host input",
  시스템_전체: "Host-wide",
  시스템_전체로그: "Host full log",
  시스템_총_n개: "Total Hosts are %",
  시스템_필터탐지: "Host filter for detection",
  시스템관리: "Management",
  시스템등록: "Registration",
  시스템로그: "Host log",
  시스템로그_모듈_설치: "Host module installation",
  시스템로그_방어: "Host log for defense",
  시스템로그_분석: "Host log analysis",
  시스템로그_수집: "Host log collection",
  시스템로그_수집_경로: "Host log collection path",
  시스템로그_업로드: "Host log upload",
  시스템로그_업로드_수: "Number of uploads",
  시스템정보: "Information",
  시스템추가: "Add Host",
  시스템필터_추가: "Add <strong>Host filter</strong>",
  시작: "Start",
  시작일: "Start date",
  식별: "Identification",
  식별키_트리값: "Identification key tree value",
  신규: "NEW",
  신규필터: "New filter",
  실시간방어: "Real-time defense",
  실시간탐지: "Real-time detection",
  실패: "Failure",
  실패건: "Failure",
  실행: "Run",
  실행명령어: "Run command",
  심각도: "Severity",
  심각도_설정: "Severity",
  심각도별: "by severity",
  아이디: "ID",
  아이디_이메일: "ID(Email)",
  아이디_이메일_입력: "Enter ID(Email)",
  아이디_IP주소_날짜_검색: "ID, IP addr, Date search",
  아이디이메일: "ID (email)",
  아티팩트: "Artifact",
  아티팩트_수집: "Artifact Collection",
  안내보기: "View guide",
  알림: "Notification",
  알림_간격설정: "Set notification interval",
  알림_그룹: "Alarm Group",
  알림_메일_설정: "Settings notification(email)",
  알림_시간설정: "Set notification time",
  알림_차단기간은_최소_1일부터_최대_3650일_이내로_설정가능합니다:
    "The blocking period can be set from a minimum of 1 day to a maximum of 3,650 days.",
  알림_트래픽제한_설정_값을_입력해주세요: "Please enter the traffic limit setting value.",
  알림받을_주소입력: "Enter email address to be notified",
  알림설정: "Notification settings",
  알림필터: "Notification filter",
  알림필터_등록: "Notification filter registration",
  알림필터_수정: "Edit Notification filter",
  약관동의: "Accept terms",
  어제: "Yesterday",
  어제날짜: "Yesterday",
  언어: "Language",
  언어_설정: "Language setting",
  업데이트: "Update",
  업데이트_버전: "Update version",
  업데이트_현황: "Update status",
  업데이트_n_버전: "Update(%) version",
  업데이트버전: "Update version",
  업데이트일: "Update date",
  업데이트중: "Updating",
  업로드: "Upload",
  업로드_중지: "Stop upload",
  업로드수: "Number of uploads",
  업로드용량: "Upload capacity",
  업체: "Company",
  업체_등록: "Customer Registration",
  업체별_탐지_분포: "Detection Distribution",
  업체별_탐지_수: "Count",
  업체설정: "Setting",
  없음: "None",
  에러_개인정보처리방침에동의해라: "Please accept the Privacy statement.",
  에러_검색값을입력해라: "Please enter a search term.",
  에러_검색대상을선택해라: "Please select a search target.",
  에러_고객지원이용약관동의해주세요: "Please agree to the Customer Support Terms and Conditions.",
  에러_관리자에게문의후로그인시도해주시기바랍니다:
    "Please contact the administrator and try logging in.",
  에러_권한이없습니다_관리자에게문의하세요: "권한이 없습니다.\n관리자에게 문의하세요.",
  에러_기간설정: "The period setting was entered incorrectly.",
  에러_네트워크: "Network error.",
  에러_다시로그인해주세요:
    "Your session has expired because you have not used it for a long time.\n Please login again.",
  에러_다시시도해주세요: "Please try again.",
  에러_다운로드_수_확인: "The number of downloads was entered incorrectly.\nPlease check.",
  에러_데이터가삭제되었습니다: "Data has been deleted.",
  에러_데이터가없습니다: "No data.",
  에러_동일한_비밀번호_새_비밀번호_입력:
    "It is the same as the password you are using.\nPlease enter a new password.",
  에러_등록된시스템없다: "There are no registered hosts here.",
  에러_등록된티켓사이트없음: "There are no registered ticket host.",
  에러_등록이실패했다: "Registration failed.",
  에러_등록중에러발생했습니다: "There was an error registering.",
  에러_등록한필터가없다: "There are no registered filters.",
  에러_로그인10회실패로로그인이제한됩니다: "Sign-in is limited to 10 failed attempts.",
  에러_로그인허용IP주소_사용설정_불가능:
    "Since there is no registered information, it is not possible to set the use of login permitted IP address.",
  에러_리소스명중복: "Already registered resource name",
  에러_리소스코드중복: "Already registered resource code.",
  에러_만료된정보다: "Expired information.",
  에러_모든값을입력해라: "Please enter all information.",
  에러_발생로그없습니다: "No logs were encountered.",
  에러_방어_명령어를_입력하세요: "Enter the defense command.",
  에러_방어대상1개이상선택되야함확인후다시시도하세요:
    "If more than one defensive target is selected, it can be set.\nPlease check and try again.",
  에러_백업루트를선택해주세요: "Choose the backup route.",
  에러_복원개수초과: "Up to 10 hosts can be restored.\nPlease check the information.",
  에러_복원중복:
    "The settings include logs that have already been restored.\nPlease check the settings.",
  에러_복원횟수초과: "Permit only 10 restorations per day(based on completed restoration).",
  에러_비밀번호는_6자리_이상_입력하셔야_합니다: "Password must be at least 6 digits.",
  에러_비밀번호를_입력해_주세요: "Please enter a password.",
  에러_비밀번호를입력해주세요: "Please enter a password.",
  에러_비밀번호일치하지않는다: "Passwords do not match.",
  에러_비밀번호일치하지않습니다: "Passwords do not match.",
  에러_빈값을입력하세요: "Please enter empty inquiry.",
  에러_사용자가등록한상관필터가없다: "There are no registered correlation analysis filters.",
  에러_삭제가실패했다: "Delete failed.",
  에러_삭제중인_시스템:
    "There are hosts and logs being deleted.\nPlease try after deletion is complete.",
  에러_서비스리젼과스토리지타입선택: "Please select a service region and storage type.",
  에러_서비스리젼과스토리지타입주키퍼명선택: "Select Region/Storage type/Zookeeper/Collection.",
  에러_서비스명중복: "Already registered service name.",
  에러_선택한운영체제의시스템없다: "There is no host.",
  에러_세자리수: "Please enter a three-digit number.",
  에러_수정권한: "You do not have permission to modify.",
  에러_수정이실패했다: "Modification failed.",
  에러_시간설정다시: "Please reset the time.",
  에러_시스템등록불가_관리자에게문의:
    "Host registration is not possible.Please contact the administrator.",
  에러_아이디를입력해주세요: "Please enter an ID.",
  에러_아이디및비밀번호를입력해라: "Please enter username and password..",
  에러_아이디정보가없다이미등록된아이디다: "No ID information or ID already registered.",
  에러_업로드경로가하나이상필요:
    "There must be at least one upload path to enable weblog collection.",
  에러_올바른이메일주소입력해라: "Please provide a valid email.",
  에러_요청사유를입력하라: "Please enter reason for request.",
  에러_웹로그업로드경로하나이상필요:
    "There must be at least one upload path to enable weblog collection.",
  에러_이름에특수문자입력못한다: "You can not use special characters in names.",
  에러_이름입력해라: "Please enter a name.",
  에러_이메일입력해라: "Please enter an email.",
  에러_이미가입된이메일이다: "Already subscribed ID(Email).",
  에러_이미등록된내부IP주소다: "This is the internal IP addr already registered.",
  에러_이미등록된목록그룹이다: "List group already registered.",
  에러_이미등록된태그입니다: "This tag is already registered.",
  에러_이미등록된호스트명입니다: "The host is already registered.",
  에러_이미선택된서버그룹이다: "Already selected server group.",
  에러_이미선택된항목입니다: "Already selected.",
  에러_이용약관에동의해라: "Please agree to the Terms and Conditions.",
  에러_자동등록방지를확인해라: "Please check to prevent automatic registration.",
  에러_정보를입력해라: "Please enter information.",
  에러_중복된값: "Duplicate value.",
  에러_채널이없거나선택되지않았을경우:
    "If the channel does not exist or is not selected, \nKey,Value must be entered.",
  에러_최대n일입력가능: "Max % days (Max % years).",
  에러_컴플라이언스중복: "Compliance name or ID already registered.",
  에러_코드중복: "It is already registered code.",
  에러_탐지필터_등록해_주세요: "Please register the detection filter.",
  에러_티켓시스템설정안했다:
    "It can be used after setting the ticket linkage.\n(Management> Interlock> Ticket)",
  에러_필수값을입력해라: "Please enter the required value.",
  에러_필수정보를입력해라: "Please fill in the required information.",
  에러_회사명입력해라: "Please enter a company name.",
  에러_IP주소입력후등록가능합니다: "Please use after registering the IP address.",
  에러_n개_이상_선택: "Please enter % or more pieces of information.",
  에러_n이후에이탈값확인가능: "Check for deviation after %",
  에러_OTP메일_번호를_확인해_주세요: "Please check Email verification number.",
  에러_PIN번호가_일치하지_않습니다: "PIN do not match.",
  에러_PIN번호를_확인해_주세요: "Please check your PIN.",
  에이전트: "Agent",
  에이전트_다운로드: "Agent download",
  에이전트_등록: "Agent registration",
  에이전트_삭제: "Delete Agent",
  에이전트_설정값: "Agent settings",
  에이전트_설치: "Install Agent",
  에이전트_설치_감사정책_안내:
    "Agent installation is accompanied by advanced audit policy settings, which will result in changes to system logging.",
  에이전트_설치_동의: "Service understanding and consent is required for agent installation.",
  에이전트_설치_동의_추가안내: "(The consent is just performed once.)",
  에이전트_설치_오딧설정_안내:
    "Agent installation is accompanied by audit settings, which will result in changes to system logging.",
  에이전트_설치시간: "Agent install time",
  에이전트_실행: "Run Agent",
  에이전트_재실행: "Re-run Agent",
  에이전트_중지: "Stop Agent",
  에이전트버전: "Agent version",
  에이전트상태: "Agent status",
  에이전트중지: "Stop the agent",
  에이전트중지_알림: "Agent stop notification",
  엔터프라이즈_탐지_분포: "Enterprise Detection Distribution",
  연도월주차: "%Y %M %W",
  연동: "Interlock",
  연동설정: "Setting",
  영구: "Permanence",
  영어공격IP주소: "Attack IP address",
  예: "Example",
  예외: "Exception",
  예외_설정: "Exception settings",
  예외그룹_추가: "Add exception group",
  예외설정_추가: "Add exception settings",
  예외속성: "Exception attribute",
  예외적용: "Apply exception",
  예외조건: "Exception condition",
  오늘: "Today",
  오늘날짜: "Today",
  오늘접속: "Access today",
  오래된순: "Order by Old",
  오류: "Error",
  오류메시지: "Error message",
  오류발생: "Error",
  오류보기: "View error",
  오전: "AM",
  오후: "PM",
  옵션: "Option",
  완료: "Complete",
  완료시_네트워크_아이콘노출: `Upon completion, the <span class="p-lr-3"><i class="fa fa-circle c-red"></i></span> icon will be exposed.`,
  완료시_네트워크_아이콘미노출: `Upon completion, the <span class="p-lr-3"><i class="fa fa-circle c-red"></i></span> icon will be disappear.`,
  완료시_호스트_아이콘노출: `Upon completion, the <span class="p-lr-3"><i class="fa fa-circle c-dark"></i></span> icon will be exposed.`,
  요소: "Element",
  요소_값_선택: "Select element value",
  요소_선택: "Select element",
  요소수선택: "Select the number of elements",
  요일: "Day of the week",
  요일_설정: "Setting the day of week",
  요일별: "by day",
  요일별_로그발생: "Log_Generation_By_Day",
  요일별_로그업로드_분포: "Distribution of log upload by day",
  요일별_탐지_현황: "Detection status by day",
  요청: "Request",
  요청_완료: "Requested.",
  요청_URL: "Request URL",
  요청기간: "Request period",
  요청로그_사이즈: "Request Content Length",
  요청사이즈: "Request size",
  요청시간: "Request time",
  요청일: "Date requested",
  요청자: "Requester",
  요청자_이메일: "Requestor / email",
  용량: "Volume",
  용량_로그수_서버수: "Capacity/Logs/Servers",
  우선순위: "Priority",
  우선순위_적용_공격유형_설정: "Set priority attack type",
  우선순위_적용필터: "Apply priority filter",
  우측_상단의: "Top right",
  운영: "Operations",
  운영방식: "Mode",
  운영체제: "OS",
  운영체제_그룹: "OS group",
  운영체제_버전: "OS version",
  운영체제_선택: "Select OS",
  운영체제버전: "OS version",
  운영체제별_설치방법_매뉴얼과_동영상_참조:
    "For detailed installation methods for each operating system, refer to the manual and video.",
  워드클라우드: "Word cloud",
  원격_제어: "Remote Control",
  원격지_서버_웹로그_수신_모듈_설치: "Install modplura module on remote server",
  원격지_서버_응용프로그램_전송_모듈_설치: "Install application module on remote server",
  원격지_서버_SYSLOG_전송_설정: "Setting up remote syslog server",
  원격지_장비_SYSLOG_전송_설정: "Setting up syslog on a remote server",
  원본: "Default",
  원본_로그: "Original log",
  원본_주소: "Source addr",
  원본_포트_이름: "Source port name",
  원본_호스트_이름: "Source hostname",
  원본로그보기: "View logs",
  월: "Mon",
  월별: "Monthly",
  월별_로그업로드_분포: "Monthly log-up load distribution",
  월별_총_탐지발생_현황: "Total detections by month",
  월별_탐지_현황: "Monthly detection status",
  월요일: "Monday",
  웹: "Web",
  웹_개인정보숨김: "[WEB] Hide personal information",
  웹_로그_취합은_자동_지원: "Automated weblog collection",
  웹_서버: "Server",
  웹_수정: "Edit Web",
  웹_업로드: "Web upload",
  웹_웹방화벽: "Web/WAF",
  웹_전체로그: "Web full log",
  웹_탐지_예외: "[WEB] Detection exception",
  웹_필터: "WEB Filter",
  웹_필터_수정: "Edit WEB Filter",
  웹_필터탐지: "Web filter detection",
  웹로그: "Web log",
  웹로그_모듈_설치: "Web module installation",
  웹로그_방어: "Weblog defense",
  웹로그_설정_안내: "Weblog settings guide",
  웹로그_수집: "Weblog collection",
  웹로그_수집_경로: "Weblog collection path",
  웹로그_업로드: "Weblog upload",
  웹로그_업로드_수: "Number of uploads",
  웹로그_업로드_합계: "Weblog upload total",
  웹로그_예외: "Exception web log",
  웹로그_취합_설정: "Weblog collection settings",
  웹로그_취합_설정_확인: "Check whether web logs are collected",
  웹로그미업로드시스템: "Total Unuploaded WEB",
  웹로그예외: "Exception web log",
  웹모듈_삭제: "Delete web module",
  웹모듈_삭제중: "Deleting web module",
  웹방화벽: "WAF",
  웹방화벽_수정: "Edit WAF Filter",
  웹방화벽_예외: "Exception WAF",
  웹방화벽_예외설정: "Set exception WAF",
  웹방화벽_전체로그: "WAF Full log",
  웹방화벽_필터: "WAF Filter",
  웹방화벽_필터탐지: "Web Application Firewall (WAF) Filter Detection",
  웹서버_주소: "Web Server address",
  웹탐지_개인정보숨김: "Web detection hidden privacy",
  웹탐지개인정보숨김: "Web detection hidden privacy",
  웹필터_관리: "Web filter",
  웹필터_리스트: "Web filter list",
  웹필터_분류: "Web filter classification",
  웹필터_추가: "Add <strong>Web filter</strong>",
  웹필터_추가_텍스트: "Add web filter",
  웹필터관리: "Web filter management",
  웹필터등록: "Web filter registration",
  웹필터리스트: "Web filter list",
  웹필터수정: "Edit web filter",
  웹필터추가: "Add web filter",
  웹훅: "Webhook",
  웹훅_선택_시_주소_자동_입력: "When selecting a webhook, the address is automatically entered.",
  웹훅_알림: "Web notification",
  웹훅_Webhook: "Webhook",
  웹훅지원_항목: ["Google Chat", "Line", "Slack", "Jandi", "Telegram"],
  위험_IP주소: "Malicisous IP addr",
  위험도: "Risk",
  위험도별_로그발생: "Logs by risk level",
  위험도별_로그발생_현황: "Status of logs by risk level",
  위험도별_설정: "Settings by risk level",
  유레카_비교: "Eureka comparison",
  유료_: "Pay%",
  유료_사용_기간: "Paid usage period",
  유료_PREMIUM: "Pay(PREMIUM)",
  유료_STANDARD: "Pay(STANDARD)",
  유의사항: "Notice",
  유입경로: "Funnel",
  유출정보: "Mark",
  유형: "Type",
  유형_등록: "Type registration",
  유형_발급_메일발송: "Type(Issue/Email Sending)",
  유형선택: "Select type",
  응답: "Response",
  응답_본문: "Resp-body",
  응답로그_사이즈: "Response Content Length",
  응답본문_유출영역: "Breach area in response body",
  응답본문내용: "Resp-body Source",
  응답사이즈: "Response size",
  응용프로그램: "Application",
  응용프로그램_단일필터: "Application a Filte",
  응용프로그램_단일필터_등록: "Registration",
  응용프로그램_단일필터_수정: "Edit",
  응용프로그램_로그: "Application Log",
  응용프로그램_로그_업로드: "Upload application log",
  응용프로그램_로그_업로드_수: "Number of uploads",
  응용프로그램_사용자: "Application (User)",
  응용프로그램_사용자_수정: "Edit Application (User)",
  응용프로그램_사용자정의: "Application Custom",
  응용프로그램_사용자정의_로그: `Application <br>Custom Log</br>`,
  응용프로그램_원본: "Application Default",
  응용프로그램_원본_수정: "Edit Application (original)",
  응용프로그램_원본_업로드: "Upload application default log",
  응용프로그램_원본로그: "Application default log",
  응용프로그램_원본로그_수집: "Application default log collection",
  응용프로그램_원본로그_수집_경로: "Application default log collection path",
  응용프로그램_태그: "Application Tag",
  응용프로그램로그_원본로그_수집: "Application log<br/>Default log collection",
  의문문_네트워크_격리: "Are you sure to isolate the host network?",
  의문문_네트워크_격리해제: "Are you sure to disable network isolation?",
  의문문_리스트에서_삭제하시겠습니까: "Are you sure to delete it from the list?",
  의문문_무료서비스사용중이세요: "You're using a free service.",
  의문문_방화벽을OFF하쉴: "Do you want to turn OFF the firewall?",
  의문문_방화벽을ON하쉴: "Do you want to turn ON the firewall?",
  의문문_보안_수정: "% is right?",
  의문문_삭제명령어실행된다실행후엔ON불가능하다OFF할거냐:
    "The delete command is executed.\nAfter the delete command is executed, ON can not be changed.\nOFF Do you want to process?",
  의문문_삭제하시겠습니까_취소불가:
    "Are you sure you want to delete?\nDelete will take effect immediately and will not be canceled.",
  의문문_선택한n개필터삭제하시겠습니까:
    "Are you sure you want to delete % the filters selected?\nIf you delete it, the log will not be detected after deletion, and you will not be notified.",
  의문문_설정검사_진행: "Do you want to check the configuration?",
  의문문_수정하시겠습니까: "Are you sure you want to edit?",
  의문문_수정한시간으로재설정된다적용하시겠습니까:
    "It will be reset to the modified time. \nDo you want to apply?",
  의문문_시스템로그_수정: "Are you want to modify % analyzes of the host log?",
  의문문_실행하시겠습니까: "% will you do it?",
  의문문_차단IP주소등록하쉴: "Would you like to register a blocked IP address?",
  의문문_티켓발행하시겠습니까: "Would you like to issue a ticket?",
  의문문_티켓테스트발행하시겠습니까:
    "Would you like to test the ticket with the information you entered?",
  의문문_필터삭제:
    "Are you sure you want to delete the filters selected?\nIf you delete it, the log will not be detected after deletion, and you will not be notified.",
  의문문_필터상태를ONOFF하시겠습니까: "Do you want to turn the filter ON or OFF?",
  의문문_호스트_종료: "Are you sure to shut down the host?",
  의문문_IP주소삭제:
    "Are you sure you want to delete the IP address?\nBlocking is canceled upon deletion.",
  의문문_IP주소차단해제: "Do you want to % the IP address",
  의문문_n_개의_시스템_복원: "Do you want to restore [%] hosts?",
  이동: "Move",
  이름: "Name",
  이름_입력: "Enter name",
  이름_or_이메일_검색: "Name or email search",
  이메일: "Email",
  이메일_입력: "Enter email",
  이메일ID: "Email(ID)",
  이미_등록된_업체입니다: "already a registered customer.",
  이미지: "Image",
  이벤트: "Event",
  이벤트타입: "Event type",
  이벤트타입_관리: "Event type",
  이벤트타입_ID: "Event type(ID)",
  이벤트타입_ID_제공_형태: "Event type(ID) offer type",
  이벤트타입관리: "Event type management",
  이벤트타입등록: "Event type registration",
  이벤트타입명: "Event type name",
  이벤트타입선택: "Select event type",
  이벤트타입수정: "Edit event type",
  이벤트ID: "EventID",
  이상: "more than",
  이용서비스: "Use service",
  이용약관: "Terms of service",
  이용약관_체크: "Please check the terms and conditions.",
  이전: "Previous",
  이전_로그보기: "History logs",
  이전로그검색: "Previous log search",
  이탈값: "Outlier",
  이하: "below",
  이해_및_동의_안내: "I understand and agree.",
  인스톨에이전트_설치_안내: "Please install the agent first.",
  인증: "Certification",
  인증메일_재발송: "Resend verification email",
  인증메일을_재발송하시겠습니까: "Resend the verification email?",
  인증메일이_발송되었습니다: "An email has been sent.",
  인증상태: "Authentication status",
  인증실패_로그인_제한: "Restrict authentication failure login",
  인증실패_횟수: "Number of authentication failures",
  인코딩: "Encoding",
  일: "Sun",
  일_며칠: "days",
  일개월: "1month",
  일반: "Common",
  일별: "Daily",
  일별_로그발생: "Log_Generation_By_Daliy",
  일별발생_현황: "Daily occurrence status",
  일요일: "Sunday",
  일일_매트릭스: "Daily Matrices",
  일정: "Calendar",
  일주: "1week",
  일치: "Consistency",
  임계치관리: "Threshold Management",
  임계치등록: "Threshold registration",
  임계치명: "Threshold Name",
  임계치수정: "Modify Threshold",
  임계치코드: "Threshold code",
  임시_비밀번호_이메일로_보내기: "Email temporary password",
  임시비밀번호: "Temporary password",
  임시비밀번호_메일발송: "Send temporary password by e-mail",
  임시비밀번호_발급: "Provision of temporary password",
  임시비밀번호_발급_히스토리: "Temporary password issuance history",
  임시비밀번호가_발송되었습니다: "A password has been sent.",
  임시비밀번호를_발급시_재사용불가:
    "Once a temporary password is issued, the old password cannot be used.",
  입력: "Input",
  입력된_정보를_확인해주세요: "Please check the input.",
  입력정보를_확인해주세요: "Please check the input.",
  있음: "Existence",
  자동: "Automatic",
  자동등록방지: "Prevent auto-registration",
  자동차단: "automatic blocking",
  자세한_정보_도움말_이용: "Please use the help for more information.",
  자세히보기: "Learn more",
  작동: "Work",
  작성_시_공격유형_2depth: "type of attack 2depth",
  작성일: "Date Created",
  작성자: "Writer",
  작성자_이메일: "Writer(email)",
  잠금상태: "Locked state",
  잠김: "locked",
  장비_등록: "Registration",
  장비등록: "Equipment registration",
  재발송: "Resend",
  재설정: "Reset",
  재시도: "Retry",
  재시작: "Restart",
  재입력: "Re-enter",
  재전송공격: "Replay attack",
  재정렬: "Reordering",
  저장: "Save",
  적_용: "Apply",
  적용: "Apply",
  적용된_정규식_노출: "Exposing applied regex",
  적용목록_관리: "Application list",
  적용목록관리: "Apply list",
  적용상태: "Status",
  적용서버: "Application server",
  적용시간: "Application time",
  적용시스템그룹: "Applicable host group",
  적용업체: "Company",
  적용일: "Application date",
  적용하기: "Apply",
  전송: "전송",
  전송_방식: "Transmission method",
  전술: "Tactics",
  전술_등록: "Tactics registration",
  전술_매핑: "Tactics mapping",
  전술_수정: "Tactics modification",
  전술_탐지_TOP5: "TOP 5 Tactics detection",
  전술관리: "Tactics management",
  전술등록: "Tactics registration",
  전술명: "Tactics",
  전술ID: "TacticsID",
  전월: "Last month",
  전월발생: "Last Month",
  전일: "Last day",
  전일_그래프_제공: "Graph provided the day before",
  전일발생: "Yesterday",
  전주: "Last week",
  전주발생: "Last Week",
  전체: "All",
  전체로그: "Full log",
  전체로그_바로가기: "Link to full log",
  전체로그_수집: "All log collection",
  전체로그_저장: "Save full log",
  전체선택: "Select all",
  전체차단동작: "Block All",
  전화번호_휴대폰: "Phone number",
  전환: "Transform",
  점검_중_예외: "Exception during check",
  접근_제한: "Access Restrictions",
  접속_예외: "Exception connection",
  접속_횟수: "Number of connections",
  접속_IP: "Connection IP Addr",
  접속수: "Number of connections",
  접속일: "Date of connection",
  접속자: "Contact person",
  접속파일_TOP5: "Connection file TOP5",
  접속하기: "Connect",
  접속IP: "Connection IP addr",
  접속Path_TOP5: "ConnectionPath TOP 5",
  정규식: "Regular expression",
  정렬: "Sort",
  정렬코드: "Sort",
  정렬해제: "Unsort",
  정보: "Infomation",
  정보_마케팅_메일: "Information (Marketing) email",
  정보_수집: "Information collection",
  정보_입력: "Enter information",
  정보검색: "Information retrieval",
  정보를_선택하세요: "Select information.",
  정보메일_서비스: "Information email service",
  정보메일수신: "Receive mail",
  정보수집: "Information collection",
  정보수집이실패하였습니다: "Information collection failed.",
  정보수집후사용가능: "It can be used after collecting information.",
  정보입력: "Information",
  정보입력_다수복사붙여넣기가능: "Input (multiple copy pastes available)",
  정상: "Normal",
  정지: "Stop",
  제거문자열: "Remove string",
  제거유형: "Removal type",
  제공: "Provided",
  제목: "Title",
  제약조건_인덱스_키: "Constraints (index, key)",
  제어: "Control",
  제외: "Except",
  제외기간_설정: "Exclusion period setting",
  제외설정: "Exclusion setting",
  제한: "Restrictions",
  조건: "Condition",
  종료: "End",
  종료일: "End date",
  종합: "Generalization",
  주별: "Weekly",
  주별_총_탐지발생_현황: "Total detections by week",
  주소: "Address",
  주요개체: "Key objects",
  주요개체_선택: "Select key object",
  주요개체설정: "Main object settings",
  주요정보: "Main information",
  주의_개인정보_도용_노출:
    "If the same ID and password are used on multiple sites, personal information may be leaked.",
  주의사항: "Precautions",
  주체_계정_이름: "Subject account name",
  주키퍼관리: "Zookeeper management",
  주키퍼등록: "Zookeeper registration",
  주키퍼명: "Zookeeper name",
  주키퍼선택: "Select zookeeper",
  주키퍼수정: "Modify zookeeper",
  주키퍼주소: "Zookeeper address",
  준비중: "Preparing",
  중간: "Middle",
  중복된_정보가_존재합니다_확인바랍니다: "Duplicate information exists.\nConfirm",
  중복체크: "Check duplicate",
  중지: "Stop",
  중첩검색: "Nested search",
  즉시방어: "Instant defense",
  즉시차단: "Block",
  지금_변경: "Change now",
  지역: "Region",
  지원단계: "Support step",
  지원상태: "Support status",
  지원시작: "Start support",
  지원시작일: "Support start date",
  지원종료: "End of Support",
  지원종료일: "Support stop date",
  직접: "Manual",
  직접_n: "Manual[%]",
  직접등록: "Manual registration",
  직접입력: "Manual input",
  직책: "Position",
  직책_입력: "Enter position",
  진행: "Progress",
  진행사항: "Progress",
  진행상태: "Progress status",
  진행상황: "Progress",
  진행자: "Operator",
  진행중: "Proceeding",
  집계: "Total",
  차단: "Blocking",
  차단_안내: "Blocking guidelines",
  차단_안내_문구: "Blocking notice",
  차단_해지: "Unblock",
  차단기간: "Blocking period",
  차단되었습니다: "Blocked",
  차단모드: "Blocking mode",
  차단실행중: "Blocking",
  차단IP주소: "Blocked IP address",
  차단IP주소_등록: "Block IP address registration",
  채널: "Channel",
  채널_관리: "Channel",
  채널_정렬_관리: "Channel alignment",
  채널_ID: "Channel(ID)",
  채널관리: "Channel",
  채널등록: "Channel registration",
  채널명: "Channel",
  채널명_EN: "Channel(EN)",
  채널별_로그수: "Number of logs per channel",
  채널수정: "Eidt channel",
  채널정렬: "Channel sort",
  채널ID: "Channel ID",
  채널ID_중복체크: "Channel ID(Duplicate check)",
  처리_결과: "Processing result",
  처리상태: "Processing status",
  처리원인: "Treatment cause",
  처리일: "End Date",
  처음: "First",
  초: "Second",
  초_내: "in seconds",
  초기화: "Reset",
  초단위_입력: "Input in seconds",
  초대메일발송일: "Invitation date",
  초대현황: "Invitation status",
  총: "Total",
  총_로그_수: "Total number of logs",
  총_로그업로드: "Total log upload",
  총_로그업로드_수: "Total number of uploads",
  총_매핑_항목: "Total mapping type",
  총_발생로그: "Toal number of logs",
  총_탐지: "Total sum",
  총_탐지건수: "Total detections",
  총_필터: "Total filter",
  총_n개: "Total %",
  총게시물: "Total posts",
  총접속: "Total connection",
  최근_탐지_노출_리스트: "Recent detection exposure list",
  최근동기화시도일: "Last synchronization attempt date",
  최근업데이트: "Last updates",
  최근접속일: "Last login date",
  최대: "maximum",
  최대_1_2분소요_실패시재시도:
    "This may take up to 1-2 minutes. You can check the result by refreshing, and if it fails, please try again.",
  최대_16자: "* up to 16 characters",
  최대_요청: "Max Request",
  최대_응답: "Max Response",
  최대_n개: "Maximum %",
  최대_n개_검색가능: "Up to % searches are possible.",
  최대값: "Maximum value",
  최대n년: "Max % years",
  최소: "Ieast",
  최소_길이_8자리: "Minimum 8 digits, no character combination rules",
  최소값: "Minimum value",
  최신버전: "Latest version",
  최신버전_다운로드_링크: "Download link",
  최신버전_패치버전: "Latest version (Patch version)",
  최신순: "Latest Order",
  최신업데이트일: "Latest Update",
  최종검사: "Ticket",
  최종적용: "Final applied",
  최종접속일: "Last access",
  추가: "Add",
  추가_정보입력: "Additional info",
  추가저장: "Additional storage",
  추가저장기간: "Additional storage period",
  추천: "Recommend",
  추천필터: "Featured filters",
  추천필터_등록: "Featured filters registration",
  추천필터등록: "Register a feature filter",
  추천필터등록필터메뉴얼보기: "Recommended/Registration Filter Manual View",
  추천필터등록하러가기: "Go to Register Filter",
  추천필터수정: "Edit",
  추출로그: "Extract log",
  추출수: "Extract number",
  추출순: "Order of extraction",
  추출일: "Extraction date",
  추출IP주소: "Extraction IP addr",
  출력: "Output",
  취_소: "Cancel",
  취소: "Cancel",
  취약경로: "Vulnerable path",
  취약항목: "Vulnerable item",
  취합_동일로그수: "Collect equal number of logs",
  취합로그: "Collection log",
  취합로그별_서버수: "Number of servers by collection log",
  취합중: "Collecting",
  치환: "Substitution",
  치환대상: "Substitution target",
  치환할_값: "The value to replace",
  커널: "kernel version",
  커서: "cursor",
  커서마크_수정하시겠습니까:
    "When OFF, the speed of the ' Next' page of the search is slowed down.\n Are you sure?",
  컬럼: "Column",
  컬럼_위치_날짜_앞: "Column Position: Before Date",
  컬럼명: "Column name",
  컬렉션관리: "Collection Management",
  컬렉션등록: "Register Collection",
  컬렉션명: "Collection Name",
  컬렉션수정: "Modify Collection",
  컴플라이언스: "Compliance",
  컴플라이언스_등록: "Register Compliance",
  컴플라이언스_리스트: "Compliance list",
  컴플라이언스_매핑: "Compliance mapping",
  컴플라이언스_설정: "Compliance setting",
  컴플라이언스_정보: "Infomation",
  컴플라이언스명: "Compliance name",
  컴플라이언스명_변경: "Edit Compliance name",
  컴플라이언스상세: "Compliance details",
  컴플라이언스ID: "Compliance ID",
  코드: "Code",
  코드_및_항목_설명: "Description code & item",
  코드_불러오기: "Code loading",
  코드_선택: "Select Code",
  코드_입력: "Enter code",
  코드_코드명: "Code/Code name",
  코드_코드명_코드설명: "Code/Code name/Code description",
  코드그룹: "Code group",
  코드그룹등록: "Code group registration",
  코드그룹명: "Code group name",
  코드그룹선택: "Select code group",
  코드그룹설명: "Code group description",
  코드그룹수정: "Edit Code Group",
  코드명: "Code name",
  코드설명: "Code description",
  코드수정: "Edit code",
  코드순: "Code order",
  코드정보: "Code infomation",
  코드정보입력_개발자입력: "Enter code information (developer input)",
  코드추가: "Add code",
  쿠키: "Cookie",
  쿼리: "Query",
  쿼리_요청본문: "Query || Request body",
  크리덴셜_스터핑: "Credential stuffing",
  키: "key",
  키워드: "Keyword",
  키워드_매핑: "Keyword mapping",
  키워드저장크기: "Keyword storage size",
  타겟_디비_패치대상: "Target DB (Patch Target)",
  타겟디비_값: "Target DB value",
  타겟디비_패치대상: "Target DB (Patch Target)",
  타이틀_시스템: "System",
  타입: "Type",
  타입별: "By type",
  탐지: "Detection",
  탐지_단계: "Detection phase",
  탐지_방어: "Detection/Defense",
  탐지_분포: "Detection distribution",
  탐지_양: "Detection volume",
  탐지_예외: "Detection exception",
  탐지_우선순위_설정: "Set detection priority",
  탐지_우선순위_적용: "Apply detection priority",
  탐지_위험도별_현황: "Status by detection/risk",
  탐지_필터: "Detection filter",
  탐지_TOP5: "TOP 5 detection",
  탐지간_인터벌_시간: "Interval time between detections",
  탐지갯수: "Detection count",
  탐지노출: "Detection exposure",
  탐지로그: "Detection log",
  탐지로그_바로가기: "Link to detection log",
  탐지로그_발생_시스템: "Total detection",
  탐지로그_시스템: "Detection",
  탐지모드: "Detection mode",
  탐지서비스: "Detection service",
  탐지수: "Detection count",
  탐지수_기준: "Detection base",
  탐지시간: "Detection time",
  탐지알림: "Detection notification",
  탐지일: "Detection date",
  탐지조건: "Detection condition",
  탐지키워드_TOP5: "Detection keyword TOP 5",
  탐지타입: "Detection type",
  탐지필터: "Detection filter",
  탐지필터_분포: "Detection filter distribution",
  탐지필터_TOP5: "Detection filter TOP5",
  태그: "Tag",
  태그_관리: "Tag management",
  태그_선택: "Select tag",
  테마설정: "Theme settings",
  테이블: "Table",
  템플릿_변수_입력: "Please replace it with an appropriate value.",
  템플릿_정보: "Template info",
  템플릿view: "Template view",
  토: "Sat",
  토요일: "Saturday",
  통계: "Statistics",
  통계동기화: "Statistics synchronization",
  통계제외: "Statistics exclusion",
  통계제외일_n일: "Statistics exclusion day: %",
  통합코드관리: "Integrated code management",
  통합코드그룹_상세: "Integrated code group details",
  트래픽제한: "Access restrictions",
  트리거: "Trigger",
  특정_key_설정: "Set specific Key",
  티켓: "Ticket",
  티켓_발행: "Ticket",
  티켓_발행_테스트: "Ticket Issuance Test",
  티켓_발행완료: "Ticket Issued",
  티켓_연동: "Ticket linkage",
  티켓관리: "Ticketting",
  티켓버전_등록: "Register version",
  티켓버전_수정: "Edit version",
  티켓사이트: "Ticket site address",
  티켓사이트_고유아이디: "Unique ID",
  티켓사이트_관리: "Ticket site",
  티켓사이트_선택: "Select Ticket site",
  티켓사이트명: "URL",
  파라미터: "Parameter",
  파라미터_고정값: "Parameter fixed value",
  파라미터_노출명: "Parameter name",
  파라미터_설명: "Parameter explanation",
  파라미터_설정: "Parameter setting",
  파라미터_전송_템플릿: "Parameter transfer template",
  파라미터명: "Parameter name",
  파라미터유형: "Parameter type",
  파일: "File",
  파일업로드: "File upload",
  팔로우: "follow",
  패턴: "Partten",
  패턴_관리: "Pattern",
  패턴_추가: "Add pattern",
  패턴명: "Filter name",
  패턴선택: "Select pattern",
  퍼센트_입력: "Enter %",
  퍼센트게이지: "%gauge",
  페이지_상단_메뉴에서_OS별_선택: "Select OS",
  평균: "Average",
  평판DB: "Reputation DB",
  포렌식: "Forensic",
  포렌식_등록: "Forensic Registration",
  포렌식_수정: "Forensic Edit",
  포렌식_하위목록_등록: "Forensic Sub-list Registration",
  포렌식_하위목록_수정: "Forensic Sub-list Edit",
  포렌식관리: "Forensic Management",
  포맷: "Format",
  포트: "Port",
  포트_프로토콜: "Port/Protocol",
  포트번호_유지: "Keep Port number",
  포함: "Include",
  포함제외: "Excluding",
  프로그램_이름: "Program name",
  프로그램명: "Program name",
  프로세스_ID: "Process ID",
  프로토콜: "Process",
  프록시_설정: "Proxy settings",
  플랫폼: "Platform",
  플랫폼_노출명: "Platform display name",
  플랫폼_등록: "Platform registration",
  플랫폼_매핑: "Platform mapping",
  플랫폼_수정: "Platform modification",
  플랫폼_코드: "Platform code",
  플랫폼관리: "Platform management",
  플랫폼등록: "Platform registration",
  필수여부: "Required",
  필요시_경로_입력: "Enter route on demand",
  필터: "Filter",
  필터_관리: "Filter",
  필터_등록: "Filter registration",
  필터_등록_필요: "Filter registration required",
  필터_매핑: "Filter mapping",
  필터_비교: "Compare filters",
  필터_선택: "Selection filter",
  필터_소스: "Filter source",
  필터_이름: "Filter name",
  필터_추가: "Add filter",
  필터관리: "Manage filters",
  필터등록: "Filter registration",
  필터를_검색하세요: "Search for filters.",
  필터를_선택하세요: "Please select a filter.",
  필터리스트_관리: "Filterlist management",
  필터명: "Filter name",
  필터명순: "Filter name order",
  필터별_로그발생: "Log_Generation_By_Filter",
  필터별_로그발생_현황: "Log generation by filter",
  필터별_탐지_수: "Detection counts by filter",
  필터별_탐지_카운트: "Detection count by filter",
  필터분류: "Filter classification",
  필터분류_선택: "Select filter classification",
  필터분류선택: "Select filter classification",
  필터상태: "Filter status",
  필터설명: "Filter Description",
  필터수정: "Edit filter",
  필터위험도별: "by filter risk level",
  필터위험도별_설정: "Filter-specific settings",
  필터유형: "Filter type",
  필터유형별: "Filter type",
  필터키: "Filter key",
  필터키입력: "Filter keystrokes",
  필터키OR매핑코드: "Filter key or mapping code",
  필터탐지: "Filter detection",
  하단다음4라인주석해제: "Uncomment the next four lines at the bottom",
  하단에서_수정: "Edit from bottom",
  하위: "Sub",
  하위_필터: "Sub-filter",
  하위대상: "Sub-targets",
  하위요소: "Child elements",
  한개의_필터_등록: "1 filter registration",
  합계: "Sum",
  항목_설명: "Item & Description",
  항목_추가: "Add explanation",
  항목추가: "Add item",
  해시: "Hash",
  해제: "Release",
  해지: "Termination",
  해킹그룹: "Group",
  해킹그룹_등록: "Group registration",
  해킹그룹_매핑: "Group mapping",
  해킹그룹_수정: "Group modification",
  해킹그룹관리: "Group management",
  해킹그룹등록: "Group registration",
  해킹그룹명: "Group",
  해킹그룹탐지: "Group detection",
  해킹그룹ID: "GroupID",
  행동유형: "Behavior",
  허용: "Permit",
  허용IP주소: "Allowed IP address",
  현재: "Current",
  현재_권한: "Current authority",
  현재_비밀번호: "Current Password",
  현재_PIN번호_입력: "Enter current PIN",
  호스트: "Host",
  호스트_사용: "Use host",
  호스트_이름: "Hostname",
  호스트_종료: "Shut down",
  호스트명: "Hostname",
  호스트명_입력: "Enter hostname",
  호스트보안: "EDR",
  호스트보안_단일필터: "EDR filter",
  호스트보안_단일필터_등록: "EDR Registration filter",
  호스트보안_단일필터_수정: "EDR Edit filter",
  호스트보안_단일필터추가: "EDR Add filter",
  호스트보안_방어필터: "EDR Defense filter",
  호스트보안_수정: "Edit EDR",
  호스트보안_업로드: "EDR Upload",
  호스트보안로그_분석: "EDR log analysis",
  호스트보안로그_수집: "EDR log collection",
  호스트보안로그_업로드: "EDR log upload",
  호스트보안로그_업로드_수: "Number of uploads",
  호스트보안필터_추가: "<strong>EDR filter</strong>Add",
  홈페이지_위변조: "Forgery of homepage",
  홈페이지_위변조_보안탐지: "Homepage Forgery Security Detection",
  홈페이지_위변조_보안필터_수정: "Edit homepage forgery security filter",
  홈페이지_위변조_탐지: "Forgery detection",
  화: "Tue",
  화면_설정: "Setting",
  화요일: "Tuesday",
  확_인: "Confirm",
  확률: "Probability",
  확인: "Confirm",
  확인하기: "Check",
  확인했습니다: "Confirmed.",
  환경_설정: "Configuration settings",
  회: "time",
  회사: "Company",
  회사명: "Company name",
  회사명_입력: "Enter company",
  회사별_관리: "Company",
  회사별관리: "Management by company",
  회사별관리수정: "Edit company",
  회사소개: "About Us",
  회사이름: "Company name",
  회원_관리: "Memeber",
  회원가입: "Sign Up",
  회원고유키: "Member unique key",
  회원관리: "Membership",
  횟수: "Count",
  횟수입력: "Enter count",
  휴대폰: "Phone",
  휴대폰번호: "Phone number",
  휴대폰번호_입력: "Enter phone number",
  히스토리: "History",
  히트맵: "Heat map",
  히트맵_정보: "Heat map",
  힙사용률: "Heap utilization",
  abc순: "ABC order",
  Amazon_Linux_AMI_2017_2018_Linux_2_AMI_지원: "Amazon Linux AMI 2018, Linux 2 AMI support",
  API_버전: "API ver",
  API_인증키: "API authentication key",
  Audit_로그패키지개별설치: "Individual installation of the Audit",
  Audit_rule_관리: "Audit rule",
  Audit_rule_등록: "Register Audit rule",
  Audit_rule_수정: "Edit audit rule",
  AUDIT_RULE명: "AUDIT RULE",
  Auditrule관리: "Auditrule",
  Byte_부터: "From Byte",
  CentOS_7_Stream_8_지원: "CentOS 7, Stream 8 support",
  Command_로그_수집_중지: "Stop Command log collection",
  Config_위치_확인: "Config path check",
  DATA_위치: "DATA path",
  Data_name_불러오기: "Loading dataname",
  dataname: "Data name",
  Dataname_관리: "Dataname",
  Dataname등록: "Register dataname",
  Dataname수정: "Edit dataname",
  DB_매핑코드: "DB mapping code",
  Default적용: "Apply default",
  DoS: "DoS",
  e: "Update",
  Event_Log_IIS포함: "Event Log (include IIS)",
  Event_TYPE: "<strong>Event</strong>TYPE",
  GUID: "GUID",
  HASH_전_로그보기: "View log before HASH",
  ID관리: "ID management",
  ID등록: "ID registration",
  ID수: "ID number",
  ID수정: "ID modification",
  IP검사: "IP Addr inspection",
  IP주소: "IP addr",
  IP주소_등록: "IP address registration",
  IP주소_사용자태그: "IP address user tag",
  IP주소_입력: "Enter IP address",
  IP주소_추출: "Extraction IP addr",
  IP주소_추출로그: "IP address Extraction Log",
  IP주소_태그: "IP address tag",
  IP주소그룹: "IP addr group",
  IP주소추가: "End IP addr",
  ipv6는_지원하지않습니다: "IPv6 is not supported.",
  KEY: "KEY",
  Linux_Syslog탭: "Linux > Syslog",
  Login_분석: "Login analysis",
  Login_분포: "Login distribution",
  Login_성공_실패: "Login success/failure",
  Login_추출로그: "Login Extraction Log",
  MAC주소: "MAC address",
  MAC주소_사용자태그: "MAC address user tag",
  Method별_로그발생: "Log generation by Method",
  MIME_타입: "MIME Type",
  ML_예외설정_추가: "Add exception settings ML",
  ML분석: "ML analysis",
  ML예외_설정: "Exception settings ML",
  ML예외n건: "Exception settings ML %",
  ML탐지: "ML detection",
  msg정렬_등록: "msg sort registration",
  msg정렬_수정: "msg sort modification",
  msg정렬관리: "msg sort management",
  MSS관리: "MSS management",
  mysql_conf_다운로드_rsyslog_사용: "Download mysql.conf  for using rsyslog",
  mysql_error_위치_확인: "mysql error pach check",
  n_공격가능확률: "% Attack probability",
  n_데몬_재시작: "% Restart the daemon",
  n_사용시_설치필요: "Installation required when using %.",
  n_수정: "Edit %",
  n_이상_지원: "% more than support",
  n_이상일때_설치필요: "Installation required for % or higher",
  n_지원: "Support %",
  n가_등록됩니다: "% are listed below.",
  n개: "%",
  n개_등록제한: "Up to % can be registered.",
  n개_모두_선택: "All %selected.",
  n건: "%",
  n건_등록: "% registered",
  n년: "%year",
  n명: "%",
  n분_내: "within % minutes",
  n분_전: "Before %min",
  n시: "%h",
  n시간_내: "within % hours",
  n시간_전: "Before %hour",
  n월: value => {
    const months = [
      "",
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[value];
  },
  n이상: "More then %",
  n일: "%days",
  n일_뒤: "% days later",
  n일_전: "Before %day",
  n일_n회_가능: "Per % day, per % times available",
  n주: "%week",
  NO: "NO",
  Ocean_대상IP주소관리: "Ocean: Target IP address",
  OFF: "OFF",
  ON: "ON",
  ON_설정_시_서비스_노출되며_탐지_시작: "When ON, it is exposed to service and start detection.",
  ON_설정_시_탐지노출: "When set to ON, detection notification",
  ON_설정시_로그인이_제한됩니다: "Login will be restricted when set to ON.",
  OS: "OS",
  OS_채널: "OS/Channel",
  OS별_로그발생_현황: "Log generation status by OS",
  OS선택: "Select OS",
  OTP메일: "Email",
  OTP메일_번호_입력: "Check Email verification number",
  OWASP: "OWASP",
  Path: "Path",
  PATH: "PATH",
  PATH를_입력해주세요: "Please enter PATH.",
  PATTERN: "PATTERN",
  Permission: "Permission",
  PERMISSION: "PERMISSION",
  PERMISSION을_입력해주세요: "Please enter PERMISSION.",
  PIN번호: "PIN",
  PIN번호_생성_규칙_관리: "PIN generation rule management",
  PIN번호_설정: "PIN setting",
  PIN번호_설정_완료: "PIN has been set.",
  PIN번호_입력: "Enter PIN",
  PIN번호_확인: "Confirm PIN",
  PIN번호_OFF_설정: "PIN OFF setting",
  PLURA_해킹_대응_서비스시작: "PLURA has started.",
  PLURA_Agent_UI에서_설정된_것_확인: "Check setting in PLURA Agent UI",
  Port_입력: "Enter Port number",
  PostgreSQL및Syslog재시작: "Restart PostgreSQL and Syslog",
  Powershell_관리: "Powershell",
  Powershell_등록: "Powershell registration",
  Powershell_rule: "Powershell rule",
  POWERSHELL_RULE명을_입력해주세요: "Please enter POWERSHELL RULE.",
  Powershellrule명: "Powershell rule name",
  PRIORITY: "PRIORITY",
  Priority_관리: "Priority",
  Priority_등록: "Register Priority",
  Priority_수정: "Edit Priority",
  PRIORITY명: "PRIORITY명",
  QnA: "Q&A",
  QnA_관리: "Q&A",
  QnA_등록: "Q&A Register",
  QnA_상세: "Q&A details",
  QnA_수정: "Q&A Modify",
  Redhat_7_8_지원: "Redhat 7, 8 support",
  SQUID_결과_값: "Squid result code",
  status별: "by status",
  status별_로그발생: "Log generation by Status",
  Status별_로그발생_현황: "Log generation by Status",
  status별_설정: "Setting by status",
  Syslog_재시작: "Restart Syslog",
  Syslog_전송_설정: "Setting Syslog",
  Syslog_포맷: "Syslog Format",
  SyslogAudit_로그패키지통합설치: "Syslog & Audit installed at once.",
  Sysmon_다운로드: "Sysmon download",
  Sysmon_설정: "Setting Sysmon",
  Ubuntu_16_04_18_04_20_04_지원: "Ubuntu 16.04, 18.04, 20.04 support",
  URL_스캔: "URL scan",
  URL_추출로그: "URL (path) Extraction Log",
  URL경로: "URL(path)",
  WAF_모듈_설치: "WAF module installation",
  Windows_Server_2012_이상: "More then Windows Server 2016",
  XML_값: "XML value",
};
export default languageEn;
