import React from "react";
import { Table } from "reactstrap";
import { Observer, observer } from "mobx-react";

import { languageSet } from "@qubit-utill/src";

import TableLoading from "../Loading/TableLoading";
import ThBuilder from "./ThBuilder";
import { ThFieldsConfig } from "../../../store";
import { useTableSort, useTableCheck } from "../../../hooks";

interface DefaultTablePropsTypes {
  isUseCheck?: boolean;
  emptyMessage?: string;
  className?: string;
  isUseSetting: boolean;
  createTr?: any;
  isLoading: boolean;
  tableBordered: boolean;
  trType: any;
  offset: number;
  totalCount: number;
  tableList: any[];
  useIndex: boolean;
  thFields: ThFieldsConfig;
  rowThFields?: ThFieldsConfig;
  colorTheme: string;
  addiionalInfo?: any;
  minHeight?: number;
  thClassName?: string;
  tbodyStyle?: object;
  sortIconColor?: string;
  createUnderTr?: any;
  paddingBottom?: number;
  isUseBorderBottom?: boolean;
  isUseFilteredTableList?: boolean;
  filteredTableList?: any;
  noClassName?: string;
  noWidthClassName?: string;
  settingThemeColor?: string;
  settingRowSpan?: number;
  useRow?: boolean;
  tableFontSize?: number;
}

const DefaultTable: React.FC<DefaultTablePropsTypes> = ({
  tableList,
  isUseCheck = false,
  emptyMessage,
  isUseSetting,
  createTr: CreateTr,
  isLoading = true,
  trType: TrComponent,
  offset,
  totalCount,
  className,
  tableBordered,
  useIndex,
  thFields,
  rowThFields,
  colorTheme,
  addiionalInfo,
  minHeight = 300,
  thClassName,
  tbodyStyle,
  sortIconColor,
  createUnderTr: CreateUnderTr,
  isUseBorderBottom = true,
  filteredTableList,
  isUseFilteredTableList = false,
  noClassName,
  noWidthClassName,
  settingThemeColor = "",
  settingRowSpan,
  useRow = false,
  tableFontSize = 13,
}) => {
  const _renderTr = () => {
    if (_tableList.length > 0) {
      return _tableList.map((info: any, index: number) => (
        <TrComponent
          key={info.uiId}
          info={info}
          index={index}
          offset={offset}
          totalCount={totalCount}
          thFields={thFields}
          addiionalInfo={addiionalInfo}
          minHeight={minHeight}
        />
      ));
    } else {
      return (
        <tr>
          <td
            valign="top"
            className={`dataTables_empty h-${minHeight} t-center f-13`}
            colSpan={100}
          >
            {emptyMessage || languageSet("에러_데이터가없습니다")}
          </td>
        </tr>
      );
    }
  };

  const { isAllCheck, toggleAllCheck } = useTableCheck(
    isUseFilteredTableList ? filteredTableList : tableList
  );
  const { _tableList } = useTableSort({ tableList, thFields });

  const borderBottom = isUseBorderBottom ? "border-bottom" : "";

  return (
    <Table
      className={`f-${tableFontSize} ${borderBottom} table-hover ${className}`}
      responsive
      bordered={tableBordered}
    >
      <ThBuilder
        toggleAllCheck={toggleAllCheck}
        colorTheme={colorTheme}
        isUseCheck={isUseCheck}
        isAllCheck={isAllCheck}
        isUseSetting={isUseSetting}
        useIndex={useIndex}
        thFields={thFields}
        rowThFields={rowThFields}
        thClassName={thClassName}
        sortIconColor={sortIconColor}
        noClassName={noClassName}
        noWidthClassName={noWidthClassName}
        settingThemeColor={settingThemeColor}
        settingRowSpan={settingRowSpan}
        useRow={useRow}
      />
      <tbody style={tbodyStyle}>
        {CreateTr && <CreateTr />}
        <TableLoading minHeight={minHeight} isLoading={isLoading}>
          <Observer render={_renderTr} />
        </TableLoading>
        {CreateUnderTr && <CreateUnderTr />}
      </tbody>
    </Table>
  );
};

export default observer(DefaultTable);
