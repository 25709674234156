import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button } from "reactstrap";
import { observer } from "mobx-react";

import { languageSet, useInjectStore } from "@qubit-utill/src";

interface PropTypes {
  text: string;
  fadeIn?: any;
  size?: string;
  color?: string;
  className?: string;
  children?: any;
  buttonName?: string;
  disabled?: boolean;
}
const Copy: React.FC<PropTypes> = ({
  text = "",
  fadeIn,
  size = "",
  color = "",
  className = "",
  children,
  buttonName = languageSet("복사"),
  disabled = false,
}) => {
  const { PopoverStore } = useInjectStore();
  return (
    <CopyToClipboard text={text} onCopy={PopoverStore.fadeIn}>
      {children ? (
        children
      ) : (
        <Button size={size} color={color} className={className} disabled={disabled}>
          {buttonName}
        </Button>
      )}
    </CopyToClipboard>
  );
};

export default observer(Copy);
