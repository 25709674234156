const languageEn = {
  가능: "Possible",
  가입인증이_완료되었습니다: "Sign-up is complete.",
  가입인증처리: "Subscription authentication processing",
  가입인증처리_하시겠습니까: "Process registration verification?",
  가입일: "Subscription date",
  개인정보: "Personal Information",
  개인정보_수집_이용: "Privacy Policy",
  개인정보_처리방침: "Privacy statement",
  갱신: "Renew",
  경고: "Warning",
  계정생성: "Account creation",
  관리: "Management",
  권한: "Authority",
  그래프: "Graph",
  그룹고유키: "Group unique key",
  나의_정보: "My information",
  님: "",
  단위: "unit",
  대시보드: "Dashboard",
  더보기: "More",
  도메인: "Domain",
  도메인_관리: "Domain management",
  도메인_등록: "Domain registration",
  도메인_만료: "Domain Expiration",
  도메인_수정: "Domain modification",
  도메인_확인_버튼을_클릭해주세요: "Please click the domain verification button.",
  도움말: "Help",
  동의: "Agree",
  등록일: "Registration date",
  라이선스: "License",
  라이선스_기간: "License term",
  라이선스_코드: "License Code",
  라이선스_코드_관리: "License Code Management",
  라이선스_코드_생성: "License Code Generate",
  라이선스_코드_생성되었습니다: "License Code Generated.",
  라이선스_코드_히스토리: "License Code History",
  로그아웃: "Logout",
  로그인: "Login",
  마지막_점검: "Last Check",
  만료: "Expiration",
  만료일: "Expiry date",
  메세지_다시로그인: "Please log in again.",
  메세지_라이선스_만료안내: "The license has expired. Please enter a new license code.",
  메세지_로그인해주세요: "Please login.",
  메세지_만료_n일전: "% days before expiration",
  메세지_만료_n일전입니다: "% days before expiration.",
  메세지_변경한비밀번호로다시로그인해라: "Please log in with the changed password.",
  메세지_변경한PIN번호로다시로그인해주세요: "Please log in again with the changed PIN.",
  메세지_삭제완료: "Deleted.",
  메세지_삭제하쉴: "Are you sure you want to delete?",
  메세지_수정완료: "Changed.",
  메세지_신규라이선스코드_입력안내: "Entering a new license code will renew it, and the previous license code will be expired.",
  메세지_인증메일_버튼을_클릭하시면_회원가입이_완료됩니다:
    "Once you receive your confirmation email, your registration is complete.",
  메세지_인증메일_재발송: "The verification email has been resent. Please check the newly sent verification email.",
  메세지_입력하신이메일로인증메일발송되며인증되야회원가입완료이다:
    "A verification email will be sent to the email you entered and you will be registered as a member when the verification is completed.",
  메세지_초대하였습니다: "Invited.",
  메세지_초대할사람이메일입력하라: "Please enter an email to invite.",
  메세지_핀번호를_입력하세요: "Enter PIN",
  메세지_항목선택: "Please select an item.",
  메세지_해킹의심경고: "Suspicion of Hacking Warning",
  메세지_회원가입완료되었다: "Sign up is complete.",
  메세지_회원가입한_이메일로_인증메일이_발송되었습니다: "A confirmation email has been sent to the email address you registered.",
  메세지_n개선택했습니다: "% selected.",
  메타: "Meta",
  멤버: "Member",
  멤버수: "Number of members",
  미사용: "Unused",
  바로가기: "link",
  발급일: "Issue Date",
  발급자: "Issuer",
  발생: "Occur",
  번: "Times",
  범위: "Range",
  불가능: "Impossible",
  비밀번호: "Password",
  비밀번호_변경: "Edit password",
  비밀번호_변경을_권고: "Please change your password.",
  비밀번호_입력: "Enter password",
  비밀번호_재입력: "Retype password",
  사용: "Use",
  삭제: "Delete",
  삭제_가능한_항목이_없습니다: "There are no items that can be deleted.",
  상태: "Status",
  상태값: "Status",
  새_비밀번호: "New password",
  새_비밀번호_확인: "Confirm new password",
  새_PIN번호: "New PIN",
  새_PIN번호_확인: "Confirm New PIN",
  새로고침: "Refresh",
  새창보기: "New window",
  생성: "Matching",
  생성일: "Creation date",
  생성자: "Creator",
  선택_취소: "Deselect",
  속도: "Speed",
  수정: "Edit",
  수정할_회사명_입력: "Enter company name to edit",
  시도: "Try",
  아이디_이메일: "ID(Email)",
  아이디_이메일_입력: "Enter ID(Email)",
  알림: "Notification",
  에러_네트워크: "Network error.",
  에러_데이터가없습니다: "No data.",
  에러_도메인검색_n시도횟수초과: "You can search up to % times a day. Please try again tomorrow.",
  에러_라이선스코드를_입력해_주세요: "Please enter the license code.",
  에러_비밀번호를_입력해_주세요: "Please enter a password.",
  에러_비밀번호를입력해주세요: "Please enter a password.",
  에러_비밀번호일치하지않는다: "Passwords do not match.",
  에러_빈값을입력하세요: "Please enter empty inquiry.",
  에러_아이디를입력해주세요: "Please enter an ID.",
  에러_이메일입력해라: "Please enter an email.",
  에러_이용약관에동의해라: "Please agree to the Terms and Conditions.",
  에러_정보를입력해라: "Please enter information.",
  에러_회사명입력해라: "Please enter a company name.",
  에러_PIN번호가_일치하지_않습니다: "PIN do not match.",
  오늘접속: "Access today",
  완료일: "Complete date",
  원인: "Reason",
  웹사이트_위변조: "Website Tampering",
  유형_발급_메일발송: "Type(Issued/Email Sent)",
  유효기간: "Validity Period",
  응답시간: "Response Time",
  응답크기: "Response Size",
  의문문_수정하시겠습니까: "Are you sure you want to edit?",
  이메일: "Email",
  이메일_입력: "Enter email",
  이용약관: "Terms of service",
  인증메일_재발송: "Resend verification email",
  인증메일을_재발송하시겠습니까: "Resend the verification email?",
  인증메일이_발송되었습니다: "An email has been sent.",
  인증상태: "Authentication status",
  인증서: "Certificate",
  인증서_만료: "Certificate Expiration",
  일_전: "days before",
  임시비밀번호: "Temporary password",
  임시비밀번호_메일발송: "Send temporary password by e-mail",
  임시비밀번호_발급: "Provision of temporary password",
  임시비밀번호_발급_히스토리: "Temporary password issuance history",
  임시비밀번호가_발송되었습니다: "A password has been sent.",
  임시비밀번호를_발급시_비밀번호_변경: "Changing your password is mandatory when you receive a temporary password.",
  임시PIN번호: "Temporary PIN",
  임시PIN번호_메일발송: "Temporary PIN Email Sent",
  임시PIN번호_발급: "Temporary PIN Issued",
  임시PIN번호_발급_히스토리: "Temporary PIN Issuance History",
  임시PIN번호가_발송되었습니다: "The temporary PIN has been sent.",
  임시PIN번호를_발급시_PIN번호_변경: "Changing your PIN is mandatory when you receive a temporary PIN.",
  입력되지_않은_정보_다시_시도: "Information is missing. Please check and try again.",
  재발송: "Resend",
  점검: "Check",
  접속일: "Date of connection",
  제목: "Title",
  직접입력: "Manual input",
  찾기: "Find",
  체험: "Experience",
  초기화: "Reset",
  초대: "Invitation",
  총: "Total",
  총게시물: "Total posts",
  총접속: "Total connection",
  최근업데이트: "Last updates",
  최근접속일: "Last login date",
  최대: "maximum",
  최소: "Ieast",
  취소: "Cancel",
  취소하시겠습니까_목록으로_이동됩니다:
    "Are you sure you want to cancel? (Clicking confirm will discard any changes and return to the list.)",
  코드: "Code",
  타이틀: "Title",
  태그: "Tag",
  현재_비밀번호: "Current Password",
  현재_PIN번호_입력: "Enter current PIN",
  확인: "Confirm",
  회사: "Company",
  회사명: "Company name",
  회사별_관리: "Company",
  회사소개: "About Us",
  회원_관리: "Memeber",
  회원가입: "Sign Up",
  회원고유키: "Member unique key",
  DNS_스푸핑: "DNS Spoofing",
  DNS_br_스푸핑: "DNS<br/>Spoofing",
  IP주소: "IP addr",
  n_개_항목_삭제_하시겠습니까: "Do you want to delete % items?",
  n개_모두_선택: "All %selected.",
  n개_항목: "% items",
  n일: "%days",
  n일_전: "Before %day",
  PIN번호: "PIN",
  PIN번호_변경: "Change PIN",
  PIN번호_변경을_권고: "We recommend changing your PIN!",
  PIN번호_입력: "Enter PIN",
  PIN번호_재입력: "Re-enter PIN",
  PIN번호_찾기: "Find PIN",
  SSL_만료: "SSL Expiration",
};
export default languageEn;
