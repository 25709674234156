import React from "react";

import { languageSet } from "@src/utils";
import { COOKIELANG } from "@src/constants";

const Footer = () => {
  const COMPANY_URL = "https://w.plura.io/policy/";
  const PRIVACY_URL = COMPANY_URL + COOKIELANG + "/privacy.html";
  const TERMS_URL = COMPANY_URL + COOKIELANG + "/terms.html";

  return (
    <div className="footer">
      <div className="copyright">
        <p className="pull-left sm-pull-reset">
          <span>© 2024. ChecURL. All rights reserved.</span>
        </p>
        <p className="pull-right sm-pull-reset">
          <span>
            <a href={TERMS_URL} target="_blank" rel="noreferrer" className="m-l-10 m-r-10">
              {languageSet("이용약관")}
            </a>
            |
            <a href={PRIVACY_URL} target="_blank" rel="noreferrer" className="m-l-10">
              {languageSet("개인정보_처리방침")}
            </a>
          </span>
        </p>
      </div>
    </div>
  );
};

export default Footer;
